import { Delete, Save } from "@mui/icons-material";
import { Button, Card, Grid } from "@mui/material";
import { useEffect, useMemo, useReducer, useState } from "react";
import Form, { FormActions } from "../components/Form";
import Typography from "@mui/material/Typography";
import FormFooter from "./../components/FormFooter";
import useFetch from "../hooks/useFetch";
import { CONTACTS_APIS, REPORT_APIS } from "../common/apis";
import ContactPrimary from "../features/reports/critical-logs/contact-permission-overview/ContactPrimary";
import AppDialog from "../components/AppDialog";
import ContactAccessPermission from "../features/reports/critical-logs/contact-permission-overview/ContactAccessPermission";
import { toLocalDayDateTime } from "../utils";
import SearchKomatsuEmployee from "../features/quick-details/SearchKomatsuEmployee";
import { useRoot, useToast, } from "../rootContext";
import { ValidateContact } from "../validation/schemas/ContactSchema";
import SearchPortalUsers from "../features/quick-details/SearchPortalUsers";
import ConfirmModal from "../components/ConfirmModal";
import SecondaryContact from "../features/details-old/details/SecondaryContact";
// import { useWindowPopups } from "../components/useWindowPopups";
import SearchContact from "../features/quick-details/SearchContact";

export default function EditContacts({
  contactId,
  setEditContactsDialog,
  setContactsData,
  refreshGrid,
}) {
  const { CanDoDataMaintenance } = useRoot();
  const { get, post, postFile } = useFetch();
  const { showToast, showErrorToast } = useToast();

  const [data, dispatch] = useReducer(SearchFilterReducer, SearchFilterDefaults);
  const [contactRoles, setContactRoles] = useState([]);
  const [contactCountries, setContactCountries] = useState([]);
  const [contactData, setContactData] = useState({});
  const [contactAccessDialogueOpen, setContactAccessDialogueOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [propId, setPropId] = useState();
  const [dialog, setDialog] = useState({
    open: false,
    width: "",
    title: "",
    message: "",
    actionName: "",
  });
  const [convertContactList, setConvertContactList] = useState([{ value: 0, text: "Convert To ..." }])
  const [convertContactMessage, setConvertContactMessage] = useState("")

  const cCheck = useMemo(
    function () {
      const pUser = data?.ConvertContact == 1 ? true : data?.ConvertContact == 0 ? data?.IsPortalUser : false;
      const lbUser = data?.ConvertContact == 2 ? true : data?.ConvertContact == 0 ? data?.IsLabcheckUser : false;

      if (data?.ConvertContact === undefined) {
        return false;
      }
      else if (((contactData?.IsPortalUser === pUser) && (contactData?.IsLabcheckUser === lbUser)) === true) {
        return false
      }
      else return true
    },
    [contactData, data?.ConvertContact]
  );

  // const HandleNewWindow = (actionName, data) => {
  //   useWindowPopups(actionName, data)
  // }

  const actions = {
    save: async function (e) {
      //Post the data
      setFormErrors({});
      ValidateContact(data).then(async function ({ data: req, errors }) {
        if (req) {

          const pUser = data?.ConvertContact == 1 ? true : data?.ConvertContact == 0 ? data?.IsPortalUser : false;
          const lbUser = data?.ConvertContact == 2 ? true : data?.ConvertContact == 0 ? data?.IsLabcheckUser : false;

          await post(CONTACTS_APIS.SAVE_CONTACTS, {
            ...data,
            IsKomatsu: data.IsKomatsu ? true : false,
            IsPortalUser: pUser,
            IsLabcheckUser: lbUser,
            WebsiteNoAccess: data.WebsiteNoAccess ? true : false,
            Street: data.Street1 ? data.Street1 : null,
            Suburb: data.Street2 ? data.Street2 : null,
            AllowEmailsMarketing: data?.OptInEmailsMarketing === true ? true : false,
            ConvertContactIndex: cCheck,
            WasPortalUser: contactData?.IsPortalUser,
            WasLabcheckUser: contactData?.IsLabcheckUser,
            ArchivedChanged: contactData?.IsArchived === data?.IsArchived ? false : true,
          });

          setEditContactsDialog && setEditContactsDialog((prevState) => ({ ...prevState, open: false }));
          refreshGrid && refreshGrid();

          if (window.location.pathname === "/management/edit-contact") {
            localStorage.removeItem("edit-contact");
            localStorage.setItem("edit-contact-resp", JSON.stringify({ resp: true }));
            window.dispatchEvent(new Event('storage'));
            window.close();
          }
        }
        if (errors) {
          setFormErrors(errors);
        }
      });
    },
    deleteClick: async function () {
      const response = await get(`${CONTACTS_APIS.DELETE_CONTACTS}?ContactId=${data.ContactId ? data.ContactId : 0}&Screen=Edit Contacts`);
      if (window.location.pathname === "/management/edit-contact") {
        localStorage.removeItem("edit-contact");
        localStorage.setItem("edit-contact-resp", JSON.stringify({ resp: true }));
        window.dispatchEvent(new Event('storage'));
        window.close();
      }
      setDialog({ open: false, });
      setEditContactsDialog && setEditContactsDialog((prevState) => ({ ...prevState, open: false }));
      setContactsData && setContactsData();
      refreshGrid && refreshGrid();
    },
    saveClickAlert: async function (e) {
      setDialog({
        open: true,
        title: "Convert Contact",
        message: `Do you wish to convert?`,
        actionName: "save",
      });
    },
    deleteClickAlert: async function (e) {
      setDialog({
        open: true,
        title: "Delete Contact",
        message: "Are you sure you want to delete this record?",
      });
    },
  };

  const formActions = {
    searchKomatsuEmployeeClick: async function (e) {
      setDialog({
        open: true,
        title: "Search for Komatsu Employees",
        width: "100vw",
      });
    },
    searchCustomerPortalClick: async function (e) {
      setDialog({
        open: true,
        title: "Search for Customer Portal",
        width: "100vw",
      });
    },

    clickAccessPermissions: async function (e) {
      setPropId(2);
      setContactAccessDialogueOpen(true);
    },

    clickSampleReports: async function (e) {
      setPropId(3);
      setContactAccessDialogueOpen(true);
    },

    clickPrimaryContact: async function (e) {
      setDialog({
        open: true,
        title: `Contacts Primary Mappings for ${data && data.Username}`,
        width: "100vw",
      });
    },

    clickSecondaryContact: async function (e) {
      setDialog({
        open: true,
        title: `Secondary Contacts`,
        width: "100vw",
      });
    },

    maintenanceClick: async function (e) {
      dispatch({
        payload: {
          ...data,
          Forename: "Maintenance",
          Surname: "Supervisor",
          DisplayName: "Maintenance Supervisor",
          _key: Date.now(),
        },
        type: ContactActions.LOAD_DATA,
      });
    },

    administratorClick: async function (e) {
      dispatch({
        payload: {
          ...data,
          Forename: "Administrator",
          Surname: "",
          DisplayName: "Administrator",
          _key: Date.now(),
        },
        type: ContactActions.LOAD_DATA,
      });
    },


    setDisplayClick: async function (e) {
      dispatch({
        payload: {
          ...data,
          DisplayName: data?.Forename + "  " + data?.Surname,
          _key: Date.now(),
        },
        type: ContactActions.LOAD_DATA,
      });
    },

    komatsuEmployeeClick: async function (rowData) {
      dispatch({
        payload: {
          ...data,
          Username: rowData.Mail,
          Forename: rowData.GivenName,
          Surname: rowData.Surname,
          DisplayName: rowData.DisplayName,
          Mobile: rowData?.MobilePhone,
          _key: Date.now(),
        },
        type: ContactActions.LOAD_DATA,
      });
    },

    handleSelectCustomer: async function (row) {
      dispatch({
        payload: {
          ...row,
          _key: Date.now(),
        },
        type: ContactActions.LOAD_DATA,
      });
      setDialog((prevState) => ({ ...prevState, open: false, }));
    },

    handleRefreshDetails: async function () {
      await post(CONTACTS_APIS.REFRESH_CONTACT_DETAILS, {
        ...data,
      });
      // if (resp?.Success === true) {
      //   showToast("Details were changed");
      // }
      // else {
      //   showErrorToast("No details were changed");
      // }
    },
    handleResetPassword: async function () {
      const resp = await post(CONTACTS_APIS.RESET_CONTACT_PASSWORD, {
        ...data,
      });
      if (resp?.Success === true) {
        showToast("The password has been reset and emailed");
      }
      else {
        showErrorToast("The password was unable to be reset");
      }
    },
    handleUnlockAccount: async function () {
      const resp = await post(CONTACTS_APIS.UNLOCK_ACCOUNT, {
        ...data,
      });
      if (resp?.Success === true) {
        showToast("The account has been unlocked");
      }
      else {
        showErrorToast("The account was unable to be unlocked");
      }
    },
    handleResendActivation: async function () {
      const resp = await post(CONTACTS_APIS.RESEND_ACTIVATION, {
        ...data,
      });
      if (resp?.Success === true) {
        showToast("The Customer Portal activation email has been resent: this requires the user to set their initial password");
      }
      else {
        showErrorToast("The Customer Portal activation email was unable to be resent - the account may already be active");
      }
    },
    handleCopyContact: async function () {
      let includeSubscriptions = data?.CopySubscriptions;

      const copyItems = (includeSubscriptions == true ? "website access, sample reports, and any subscriptions" : "website access and sample reports (but NOT any subscriptions)");
      const title = (includeSubscriptions == true ? "Copy Access and Sample Reports, and Subscriptions" : "Copy Access and Sample Reports");

      const msg = "Copy and replace " + copyItems + " from " + data?.DisplayName + " " + data?.Username + " to the contact you will select.\n"
        + "Yes = Select the contact and copy to them\n"
        + "No = Do not copy";

      setDialog({
        open: true,
        title: title,
        width: "100vw",
        message: msg
      });
      //      DialogResult dr = MessageBox.Show(msg, title, MessageBoxButtons.YesNo, MessageBoxIcon.None, MessageBoxDefaultButton.Button2);
      // if (dr == DialogResult.Yes) {                   
      //          SearchContacts frm = new SearchContacts(contact.ContactId.Value, includeSubscriptions, this.parent);
      //   frm.Show();
      //   frm.BringToFront();
      // }
    },
    searchContactClick: function () {
      // HandleNewWindow("access-permission-overview", { SampleNumber: data?.SampleNumber, selectCheck: true })
      setDialog({
        open: true,
        title: `Search Contacts`,
        width: "100vw",
      });
    },

    handleSelectClick: async function (row) {
      setDialog({ open: false, });

      await post(CONTACTS_APIS.CONTACT_COPY, {
        ContactIdFrom: data?.ContactId,
        ContactIdTo: row?.ContactId,
        IncludeSubscriptions: data?.CopySubscriptions === true ? true : false,
        Screen: "Edit Contact",
      });
    }
  };

  const getReferenceXSD = async () => {
    await postFile(`${REPORT_APIS.REFRENCE_XSD}`, { ContactId: contactId || data?.ContactId, }, "Komatsu CMS Reference");
  };

  //Get Edit Contact data
  useEffect(
    function () {
      let ec = JSON.parse(localStorage.getItem("edit-contact"));
      if ((ec === undefined || ec === null) && contactId > 0) {
        (async function () {
          const contactData = await get(`${CONTACTS_APIS.EDIT_CONTACTS}?contactId=${contactId}`);
          if (contactData.Contact.IsPortalUser === false) { setConvertContactList([...convertContactList, { value: 1, text: "Customer Portal" }]) }
          if (contactData.Contact.IsLabcheckUser === false) { setConvertContactList([...convertContactList, { value: 2, text: "Labcheck" }]) }
          setContactRoles(contactData.ContactRoles);
          setContactCountries(contactData.Countries);
          setContactData(contactData.Contact);

          dispatch({
            type: ContactActions.LOAD_DATA,
            payload: {
              ...data, ...contactData.Contact, ConvertContact: 0, WebsiteNoAccess: (contactData.Contact.IsKomatsu == false && contactData.Contact.IsLabcheckUser == false && contactData.Contact.IsPortalUser == false)
            },
          });
        })();
      }
      if ((contactId === undefined || contactId === null) && ec?.ContactId > 0) {
        localStorage.removeItem("edit-contact");
        (async function () {
          const contactData = await get(`${CONTACTS_APIS.EDIT_CONTACTS}?contactId=${ec?.ContactId}`);
          if (contactData.Contact.IsPortalUser === false) { setConvertContactList([...convertContactList, { value: 1, text: "Customer Portal" }]) }
          if (contactData.Contact.IsLabcheckUser === false) { setConvertContactList([...convertContactList, { value: 2, text: "Labcheck" }]) }
          setContactRoles(contactData.ContactRoles);
          setContactCountries(contactData.Countries);
          setContactData(contactData.Contact);

          dispatch({
            type: ContactActions.LOAD_DATA,
            payload: {
              ...data, ...contactData.Contact, ConvertContact: 0, WebsiteNoAccess: (contactData.Contact.IsKomatsu == false && contactData.Contact.IsLabcheckUser == false && contactData.Contact.IsPortalUser == false)
            },
          });
        })();
      }
    },
    [contactId]);

  useEffect(() => {
    if (data?.ConvertContact > 0) {
      setConvertContactMessage("Check that the correct COUNTRY has been selected.")
    }
    else {
      setConvertContactMessage("")
    }
  }, [data?.ConvertContact])

  const handleCloseButton = () => {
    localStorage.removeItem("edit-contact");
  }

  useEffect(function () {
    window.addEventListener("beforeunload", handleCloseButton);
    return function () {
      window.removeEventListener("beforeunload", handleCloseButton);
    };
  });

  return (
    <Card sx={{ p: 1 }}>
      <Form
        sections={GetFormSections(
          formActions,
          getReferenceXSD,
          contactRoles,
          contactCountries,
          contactId,
          contactData,
          data,
          CanDoDataMaintenance,
          convertContactList,
          convertContactMessage
        )}
        data={data}
        spacing={2}
        key={(data?.ContactId || "") + (data?._key || "")}
        errors={formErrors}
        dispatch={dispatch}
      />
      <Grid container>
        <Grid item xs={12}>
          {CanDoDataMaintenance && <FormFooter buttons={GetFormButtons(actions, data, contactId, cCheck)} />}
        </Grid>
      </Grid >
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
        title={dialog.title}
        maxWidth={dialog.width}
      >
        {dialog.title ===
          `Contacts Primary Mappings for ${data && data.Username}` && (
            <ContactPrimary contactId={contactId || data?.ContactId} />
          )}

        {dialog.title === "Search for Komatsu Employees" && (
          <SearchKomatsuEmployee
            komatsuEmployeeClick={formActions?.komatsuEmployeeClick}
            setDialog={setDialog}
            Username={data?.Username}
          />
        )}
        {dialog.title === "Secondary Contacts" && (
          <SecondaryContact
            contactId={contactId || data?.ContactId}
            cancel={() => {
              setDialog((prevState) => ({ ...prevState, open: false, }));
            }}
          />
        )}
        {dialog.title === "Search for Customer Portal" && <SearchPortalUsers handleSelectCustomer={formActions?.handleSelectCustomer} />}
        {dialog.title === "Delete Contact" && (
          <ConfirmModal
            handleClose={() => {
              setDialog({ open: false, });
            }}
            alertAction={actions.deleteClick}
            message={dialog.message}
          />
        )}
        {dialog.title === "Convert Contact" && (
          <ConfirmModal
            handleClose={() => {
              setDialog((prevState) => ({ ...prevState, open: false, }));
            }}
            alertAction={actions.save}
            message={dialog.message}
          />
        )}
        {
          (dialog.title === "Copy Access and Sample Reports, and Subscriptions" || dialog.title === "Copy Access and Sample Reports") &&
          <ConfirmModal
            handleClose={() => {
              setDialog((prevState) => ({
                ...prevState, open: false,
              }));
            }}
            buttonText={"Yes"}
            buttonText2={"No"}
            alertAction={formActions?.searchContactClick}
            message={dialog.message}
          />
        }
        {dialog.title === "Search Contacts" && (
          <SearchContact
            copyContactCheck={true}
            handleSelectClick={(row) => formActions?.handleSelectClick(row)}
          />
        )}
      </AppDialog>
      <AppDialog
        open={contactAccessDialogueOpen}
        handleClose={() => setContactAccessDialogueOpen(false)}
        title={
          propId === 2
            ? `Contacts Access Permissions for ${data.Username}`
            : propId === 3
              ? `Contacts Sample Reporting for display ${data.Username}`
              : propId === 4
                ? `Contacts Subscriptions for ${data.Username}`
                : `Some other message for propId ${propId}`
        }
        maxWidth="100vw"
      >
        <ContactAccessPermission
          username={data?.Username || ""}
          contactId={contactId || data?.ContactId}
          propId={propId}
        />
      </AppDialog>
    </Card>
  );
}

const GetFormButtons = ({ save, deleteClickAlert, saveClickAlert }, data, contactId, cCheck) => [
  {
    variant: "contained",
    label: "Delete",
    color: "danger",
    startIcon: <Delete />,
    onClick: deleteClickAlert,
    disabled: !(contactId || data?.ContactId),
  },
  {
    variant: "contained",
    label: "Save",
    startIcon: <Save />,
    onClick: cCheck === true ? saveClickAlert : save,
    // onClick: save,
  },
];

function GetFormSections(
  formActions,
  getReferenceXSD,
  contactRoles,
  contactCountries,
  contactId,
  contactData,
  data,
  CanDoDataMaintenance,
  convertContactList,
  convertContactMessage
) {

  return [
    {
      xlCol: 12,
      fields: [
        {
          xsCol: 6,
          group: [
            {
              xsCol: 12,
              component: function () {
                return (contactId || data?.ContactId) ? (
                  <Typography variant="subtitle2">
                    Please update the details for a contact
                  </Typography>
                ) : (
                  <></>
                );
              },
            },
            {
              xsCol: 12,
              group: [
                CanDoDataMaintenance && {
                  name: "IsKomatsu",
                  label: "Komatsu",
                  type: "switch",
                  disabled: true,
                  xsCol: 3,
                },

                {
                  xlCol: 5,
                  alignSelf: "flex-end",
                  component: function () {
                    return (data.IsKomatsu && CanDoDataMaintenance) ? (
                      <Button
                        variant="outlined"
                        onClick={formActions?.searchKomatsuEmployeeClick}
                      >
                        Search Komatsu Employee
                      </Button>
                    ) : (
                      <></>
                    );
                  },
                },
                {
                  xlCol: 2,
                  alignSelf: "flex-end",
                  component: function () {
                    return data.IsKomatsu ? (
                      <Typography>KAL Employees</Typography>
                    ) : (
                      <></>
                    );
                  },
                },
              ],
            },
            {
              xsCol: 12,
              group: [
                {
                  name: "IsPortalUser",
                  label: "Customer Portal",
                  type: "switch",
                  disabled: true,
                  className: "orange",
                  xsCol: 3,
                },
                {
                  xlCol: 8,
                  alignSelf: "flex-end",
                  component: function () {
                    return data.IsPortalUser ? (
                      <Button
                        variant="outlined"
                        onClick={formActions?.searchCustomerPortalClick}
                      >
                        Search Customer Portal
                      </Button>
                    ) : (
                      <></>
                    );
                  },
                },
              ],
            },
            {
              xsCol: 12,
              group: [
                {
                  name: "IsLabcheckUser",
                  label: "Labcheck",
                  className: "green",
                  disabled: true,
                  type: "switch",
                  xsCol: 3,
                },
              ],
            },
            {
              xsCol: 12,
              group: [
                {
                  name: "WebsiteNoAccess",
                  label: "No Website",
                  className: "brown",
                  disabled: true,
                  type: "switch",
                  xsCol: 3,
                },
              ],
            },

            {
              name: "Username",
              label: "Email",
              xsCol: 6,
            },
            {
              name: "ContactRoleId",
              label: "Role",
              type: "dropdown",
              xsCol: 6,
              required: true,
              selectOptions: (contactRoles || []).map((x) => ({
                value: x.ContactRoleId,
                text: x.ContactRole,
              })),
            },
            {
              name: "Forename",
              label: "Forename",
              xsCol: 4,
            },
            {
              name: "Surname",
              label: "Surname",
              xsCol: 4,
            },
            {
              name: "DisplayName",
              label: "Display",
              xsCol: 4,
            },
            {
              xsCol: 12,
              group: [
                {
                  xsCol: 4,
                  alignSelf: "flex-end",
                  component: function () {
                    return (
                      <Button variant="outlined" onClick={formActions?.maintenanceClick}>
                        Maintenance Supervisor
                      </Button>
                    );
                  },
                },
                {
                  xsCol: 4,
                  alignSelf: "flex-end",
                  component: function () {
                    return (
                      <Button variant="outlined" onClick={formActions?.administratorClick}>
                        Administrator
                      </Button>
                    );
                  },
                },
                {
                  xsCol: 4,
                  alignSelf: "flex-end",
                  component: function () {
                    return <Button variant="outlined" onClick={formActions?.setDisplayClick}>Set Display</Button>;
                  },
                },
              ],
            },
            {
              xsCol: 6,
              component: function () {
                return (
                  <Typography variant="caption">
                    AU +61 and 1 area code and 8 digits
                  </Typography>
                );
              },
            },
            {
              xsCol: 6,
              component: function () {
                return (
                  <Typography variant="caption">
                    NC +687 and 6 digits
                  </Typography>
                );
              },
            },
            {
              xsCol: 6,
              component: function () {
                return (
                  <Typography variant="caption">
                    NZ Mobile +642 and 7 to 9 digits
                  </Typography>
                );
              },
            },
            {
              xsCol: 6,
              component: function () {
                return (
                  <Typography variant="caption">
                    NZ Phone +64 and 1 area code and 7 digits
                  </Typography>
                );
              },
            },
            {
              name: "AllowMail",
              label: "Allow Postal Mail",
              type: "switch",
              xsCol: 4,
              className: "green",
            },
            {
              name: "AllowSms",
              label: "Allow SMS",
              type: "switch",
              xsCol: 4,
              className: "green",
            },
            {
              name: "Mobile",
              label: "Mobile",
              xsCol: 4,
            },
            {
              name: "Telephone",
              label: "Telephone",
              xsCol: 4,
            },
            {
              name: "Company",
              label: "Company",
              xsCol: 4,
            },

            {
              name: "BranchSite",
              label: "Branch/Site",
              xsCol: 4,
            },
            {
              name: "Department",
              label: "Department",
              xsCol: 4,
            },
            {
              name: "Position",
              label: "Position",
              xsCol: 4,
            },

            {
              name: "Street1",
              label: "Street",
              xsCol: 3,
            },
            {
              alignSelf: "flex-end",
              component: function () {
                return <Button variant="outlined">View</Button>;
              },
            },
            {
              name: "Street2",
              label: "Suburb",
              xsCol: 4,
            },
            {
              name: "City",
              label: "City",
              xsCol: 4,
            },

            {
              name: "PostCode",
              label: "Postcode",
              xsCol: 4,
            },
            {
              name: "State",
              label: "State",
              xsCol: 4,
            },
            {
              xsCol: 4,
              component: function () {
                return (
                  <Grid item sx={{ mt: 2.5 }}>
                    <Typography variant="caption">
                      ACT, VIC, QLD e.t.c
                    </Typography>
                  </Grid>
                );
              },
            },
            {
              name: "Country",
              label: "Country",
              type: "dropdown",
              xsCol: 4,
              required: true,
              selectOptions: (contactCountries || []).map((x) => ({
                value: x.Country,
                text: x.Country,
              })),
            },
          ],
        },
        {
          xsCol: 6,
          group: [
            {
              xsCol: 10,
              component: function () {
                return (
                  <Typography variant="caption" style={{ marginTop: "25px" }}>
                    1. Click on the buttons to specify Access, and then Reports
                  </Typography>
                );
              },
            },

            {
              xsCol: 10,
              component: function () {
                return (
                  <Typography variant="caption" style={{}}>
                    2. Update user details where available - always select a
                    COUNTRY
                  </Typography>
                );
              },
            },

            {
              xsCol: 10,
              component: function () {
                return (
                  <Typography variant="caption" style={{}}>
                    3. If a Komatsu employee, they will need to access the
                    website using the STAFF login page, and not the main
                    Customer Portal login
                  </Typography>
                );
              },
            },

            {
              xsCol: 10,
              component: function () {
                return (
                  <Typography variant="caption" style={{}}>
                    4. Non-Komatsu contacts can be converted to a different
                    type: select the type to convert the contact to
                  </Typography>
                );
              },
            },

            {
              xsCol: 10,
              component: function () {
                return (
                  <Typography variant="caption" style={{}}>
                    5. Do NOT use # or fill in fields with a full stop
                  </Typography>
                );
              },
            },

            {
              name: "IsArchived",
              label: "Archived- no website or reports",
              labelColor: data?.IsArchived === true ? "red" : "",
              type: "switch",
              xsCol: 6,
              className: "red",
            },
            {
              name: "OptInEmailsMarketing",
              label: "Allow Informational Emails",
              labelColor: data?.OptInEmailsMarketing === false ? "red" : "",
              type: "switch",
              xsCol: 6,
            },
            {
              xsCol: 4,
              component: function () {
                return (
                  <Grid item sx={{ mt: 3 }}>
                    <Typography variant="caption">
                      Created:{" "}
                      {contactData && toLocalDayDateTime(contactData.Created)}
                    </Typography>
                  </Grid>
                );
              },
            },
            {
              xsCol: 4,
              component: function () {
                return (
                  <Grid item sx={{ mt: 3 }}>
                    <Typography variant="caption">
                      Website: {contactData && contactData.WebsiteLogonUrl}
                    </Typography>
                  </Grid>
                );
              },
            },
            {
              xsCol: 4,
              component: function () {
                return (
                  <Grid item sx={{ mt: 3 }}>
                    <Typography variant="caption">
                      Login: {contactData && contactData.WebsiteLogonUrl}
                    </Typography>
                  </Grid>
                );
              },
            },
            {
              xsCol: 12,
              group: [
                {
                  xsCol: 3,
                  component: function () {
                    return (
                      <Button
                        variant="outlined"
                        sx={{ mt: 3 }}
                        onClick={formActions?.clickAccessPermissions}
                      >
                        Access Permissions
                      </Button>
                    );
                  },
                },
                {
                  xsCol: 3,
                  component: function () {
                    return (
                      <Button
                        variant="outlined"
                        sx={{ mt: 3 }}
                        onClick={formActions?.clickSampleReports}
                      >
                        Sample Reports
                      </Button>
                    );
                  },
                },
                {
                  xsCol: 3,
                  component: function () {
                    return (
                      <Button
                        variant="outlined"
                        sx={{ mt: 3 }}
                        onClick={formActions?.clickPrimaryContact}
                      >
                        Primary Contact
                      </Button>
                    );
                  },
                },
                CanDoDataMaintenance && {
                  xsCol: 3,
                  component: function () {
                    return (
                      <Button variant="outlined" sx={{ mt: 3 }}
                        onClick={formActions?.handleCopyContact}
                      >
                        Copy Contact
                      </Button>
                    );
                  },
                },
              ],
            },
            {
              name: "CopySubscriptions",
              label: "Copy Subscriptions",
              type: "switch",
              xsCol: 3,
              hidden: !(contactId || data?.ContactId),
            },
            {
              name: "AttachmentMB",
              label: "Attachments",
              type: "number",
              xsCol: 3,
            },

            {
              name: "ZipAttachments",
              label: "Zip",
              type: "switch",
              xsCol: 2,
            },
            {
              name: "EmailPerAttachment",
              label: "Separate Email per Attachment",
              type: "switch",
              xsCol: 4,
            },
            (data?.IsKomatsu === false) && {
              name: "ConvertContact",
              label: "Convert Contact to a Different Contact Type",
              type: "dropdown",
              xsCol: 6,
              selectOptions: convertContactList
            },
            {
              xsCol: 6,
              component: function () {
                return (
                  <Typography mt={2.5} color={"red"}>
                    {convertContactMessage || ""}
                  </Typography>
                );
              },
            },
            {
              name: "Notes",
              label: "Contact Comments",
              xsCol: 12,
              rows: "8",
              type: "textarea",
            },
            {
              mdCol: 4,
              component: function () {
                return <Button variant="outlined" onClick={formActions?.clickSecondaryContact}
                >Secondary Contacts</Button>;
              },
            },
            (CanDoDataMaintenance && data?.HasScope === true) && {
              mdCol: 4,
              component: function () {
                return <Button variant="outlined" onClick={getReferenceXSD}>Reference XSD</Button>;
              },
            },
            (CanDoDataMaintenance && (data?.IsPortalUser == true || data?.IsKomatsu == true)) && {
              mdCol: 4,
              component: function () {
                return <Button onClick={formActions?.handleRefreshDetails} variant="outlined">Refresh Details</Button>;
              },
            },
            (CanDoDataMaintenance && (data?.IsPortalUser == true || data?.IsLabcheckUser == true)) && {
              mdCol: 4,
              component: function () {
                return <Button onClick={formActions?.handleResetPassword} variant="outlined">Reset Password</Button>;
              },
            },
            (CanDoDataMaintenance && (data?.IsPortalUser == true || data?.IsLabcheckUser == true)) && {
              mdCol: 4,
              component: function () {
                return <Button onClick={formActions?.handleUnlockAccount} variant="outlined">Unlock Account</Button>;
              },
            },
            (CanDoDataMaintenance && (data?.IsPortalUser == true)) && {
              mdCol: 4,
              component: function () {
                return <Button onClick={formActions?.handleResendActivation} variant="outlined">Resend Activation</Button>;
              },
            },
            // (CanDoDataMaintenance && (data?.IsPortalUser == true)) && {
            //   mdCol: 4,
            //   component: function () {
            //     return <Button onClick={formActions?.handleResendActivation} variant="outlined">CMS Role</Button>;
            //   },
            // },
          ],
        },
      ],
    },
  ];
}

const ContactActions = {
  LOAD_DATA: "load-data",
};

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === ContactActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}

const SearchFilterDefaults = {
  ConvertContact: 0,
  ContactRoleId: 1,
  AllowMail: true,
  OptInEmailsMarketing: true,
  AttachmentMB: 50,
  ContactType: 2,
  Country: "Australia",
  CopySubscriptions: true,
};
