import useFetch from "../../hooks/useFetch";
import { useCallback, useEffect, useState } from "react";
import Tree from "../../components/tree/Tree";
import Product from "./treeItems/Products";
import CustomerSl from "./treeItems/CustomerSl";
import Customer from "./treeItems/Customer";
import getNodeId, { parseNodeId } from "./treeItems/nodeId";
import Site from "./treeItems/Site";
import Unit from "./treeItems/Unit";
import Component from "./treeItems/Component";
import Sample from "./treeItems/Sample";
import Button from "@mui/material/Button";
import { DETAILS_APIS, REPORT_APIS, TRAY_APIS } from "../../common/apis";
import { SearchIcon } from "../../icons/ButtonIcons";
import { Box, Card, Checkbox, FormControlLabel, } from "@mui/material";
import { DialogTypes, } from "../../components/useScreenPopups";
import productColors from "../../common/ProductColors";
import GroupDialogs from "../../components/GroupDialogs";
import { copyTextToClipboard } from "../../utils";
import { WindowTypes, useWindowPopups } from "../../components/useWindowPopups";

export default function QuickDetailsTree(props) {
  const { expandTreeData, expandTreeData1, comp2Click, minHeight, maxHeight, searchOpen, setSearchOpen, ScreenName } = props;

  const { get, getFile, postFile } = useFetch();

  const [productsWithCount, setProductsWithCount] = useState([]);
  const [node, setNode] = useState("");
  const [nodes, setNodes] = useState({});
  const [expandedNode, setExpandedNode] = useState([]);
  const [customerLetters, setCustomersLetters] = useState([]);
  const [archivedCheck, setArchivedCheck] = useState(true);
  const [dialogType, setDialogType] = useState({ open: false });
  const [productColor, setProductColor] = useState({})
  const [productsWithCountList, setProductsWithCountList] = useState([]);
  const [siteWithCounts, setSiteWithCount] = useState({})
  const [unitWithCounts, setUnitWithCount] = useState({})
  const [componentWithCounts, setComponentWithCount] = useState({})
  const [selectedNode, setSelectedNode] = useState("")
  const [selectedTreeNode, setSelectedTreeNode] = useState("")
  const [localData, setLocalData] = useState(null);

  const handleSelected = (e, nodeId) => {
    if (node === nodeId) {
      expandNodes(nodeId);
    }
    setNode(nodeId);
  };

  const handleNodeSelected = (e, nodeId, check) => {
    if (nodeId?.includes("undefined")) { return console.log("Can not process") }
    let prod = nodeId.split("/")[0];
    let color1 = Number(prod.split("_")[1]);
    let color2 = productColors(color1);
    setProductColor(color2);

    setSelectedNode(nodeId)
    if (check === true) { return; }
    handleSelected(e, nodeId);
  };

  const getNodeData = async (url) => {
    let data = await get(url);
    if (data.length) return data;
    return null;
  };

  const expandNodes = (prevNodeId) => {
    if (!node) return;
    if (expandedNode.includes(node)) {
      setExpandedNode(expandedNode.filter((e) => e !== node));
      const nodePath = node && parseNodeId(node);
      props.treeNodeSelected && props.treeNodeSelected(nodePath);
      return;
    }
    if (prevNodeId && expandedNode.includes(prevNodeId)) {
      setExpandedNode(expandedNode.filter((e) => e !== prevNodeId));
      return;
    }
    nodes[node] &&
      setExpandedNode(expandedNode.length ? [...expandedNode, node] : [node]);
  };

  const handleLabelClick = (e, nodes) => {
    // if (selectedNode === nodes) { return console.log("Can not process") }
    const nodePath = nodes && parseNodeId(nodes);
    props.nodeSelected && props.nodeSelected(nodePath);
    handleNodeSelected(e, nodes, true)
    e?.stopPropagation();
    e?.preventDefault();
  }

  const handleSearchTree = async (rowData, check, refresh) => {
    if (rowData?.ProductId === undefined) { return; }
    let color2 = productColors(rowData?.ProductId);
    setProductColor(color2);
    const product = { name: "product", val: rowData?.ProductId, };
    const stLetter = { name: "stLetter", val: rowData?.StartLetter, };
    const customer = { name: "customer", val: rowData?.CustomerId, };
    const site = { name: "site", val: rowData?.SiteId || rowData?.SiteID, };
    const unit = { name: "unit", val: rowData?.UnitId, };
    const component = { name: "component", val: rowData?.ComponentId, };
    const sample = { name: "sample", val: rowData?.SampleNumber, };

    const productNode = getNodeId([product])
    const stLetterNode = getNodeId([product, stLetter])
    const customerNode = getNodeId([product, stLetter, customer])
    const siteNode = getNodeId([product, stLetter, customer, site])
    const unitNode = getNodeId([product, stLetter, customer, site, unit])
    const componentNode = getNodeId([product, stLetter, customer, site, unit, component])
    const sampleNode = getNodeId([product, stLetter, customer, site, unit, component, sample]);
    // types: customer,site,unit,component,sample
    if (rowData?.StartLetter === undefined || rowData?.CustomerId === undefined) {
      handleRefreshTree()
      setNodes({ [productNode]: customerLetters[rowData?.ProductId] });
      setExpandedNode([]);
      props.nodeSelected && props.nodeSelected(productNode);
      return;
    }

    if (rowData?.SiteId === undefined && rowData?.SiteID === undefined) {
      const url = `types=customer&pid=${rowData?.ProductId}&lId=${rowData?.StartLetter}&cId=${rowData?.CustomerId}&ShowArchived=${archivedCheck}`
      const data = await get(`${DETAILS_APIS.SEARCH_TREE}?${url}`, { skipBlocker: true });
      if (refresh === true) {
        setNodes({ [productNode]: customerLetters[rowData?.ProductId], [stLetterNode]: data?.customerWithCount, [customerNode]: data?.sitesWithCount });
        setExpandedNode([productNode, stLetterNode, customerNode]);
        props.handleSearchTreeData && props.handleSearchTreeData({ nodeType: "customer" }, data?.sampleDetails)
        setSelectedNode(customerNode)
        setSelectedTreeNode(customerNode)
        return;
      }
      else {
        setNodes({ ...nodes, [productNode]: (customerLetters?.length > 0 ? customerLetters : data.customerStartLetters)[rowData?.ProductId], [stLetterNode]: data?.customerWithCount, [customerNode]: data?.sitesWithCount });
        setExpandedNode([...expandedNode, productNode, stLetterNode, customerNode]);
        props.handleSearchTreeData && props.handleSearchTreeData({ nodeType: "customer" }, data?.sampleDetails)
        setSelectedNode(customerNode)
        setSelectedTreeNode(customerNode)
        return;
      }
    }

    if (rowData?.UnitId === undefined) {
      const url = `types=customer,site&pid=${rowData?.ProductId}&lId=${rowData?.StartLetter}&cId=${rowData?.CustomerId}&sid=${rowData?.SiteId || rowData?.SiteID}&ShowArchived=${archivedCheck}`
      const data = await get(`${DETAILS_APIS.SEARCH_TREE}?${url}`, { skipBlocker: true });
      if (refresh === true) {
        setNodes({ [productNode]: (customerLetters?.length > 0 ? customerLetters : data.customerStartLetters)[rowData?.ProductId], [stLetterNode]: data?.customerWithCount, [customerNode]: data?.sitesWithCount, [siteNode]: data?.unitsWithCount });
        setExpandedNode([productNode, stLetterNode, customerNode, siteNode])
        props.handleSearchTreeData && props.handleSearchTreeData({ nodeType: "site" }, data?.sampleDetails)
        setSelectedNode(siteNode)
        setSelectedTreeNode(siteNode)
        return;
      }
      else {
        setNodes({ ...nodes, [productNode]: (customerLetters?.length > 0 ? customerLetters : data.customerStartLetters)[rowData?.ProductId], [stLetterNode]: data?.customerWithCount, [customerNode]: data?.sitesWithCount, [siteNode]: data?.unitsWithCount });
        setExpandedNode([...expandedNode, productNode, stLetterNode, customerNode, siteNode])
        props.handleSearchTreeData && props.handleSearchTreeData({ nodeType: "site" }, data?.sampleDetails)
        setSelectedNode(siteNode)
        setSelectedTreeNode(siteNode)
        return;
      }
    }
    if (rowData?.ComponentId === undefined) {
      const url = `types=customer,site,unit&pid=${rowData?.ProductId}&lId=${rowData?.StartLetter}&cId=${rowData?.CustomerId}&sid=${rowData?.SiteId}&uid=${rowData?.UnitId}&ShowArchived=${archivedCheck}`
      const data = await get(`${DETAILS_APIS.SEARCH_TREE}?${url}`, { skipBlocker: true });
      if (refresh === true) {
        setNodes({ [productNode]: customerLetters[rowData?.ProductId], [stLetterNode]: data?.customerWithCount, [customerNode]: data?.sitesWithCount, [siteNode]: data?.unitsWithCount, [unitNode]: data?.componentsWithCount });
        setExpandedNode([productNode, stLetterNode, customerNode, siteNode, unitNode])
        props.handleSearchTreeData && props.handleSearchTreeData({ nodeType: "unit" }, data?.sampleDetails)
        setSelectedNode(unitNode)
        return setSelectedTreeNode(unitNode)
      }
      else {
        setNodes({ ...nodes, [productNode]: (customerLetters?.length > 0 ? customerLetters : data.customerStartLetters)[rowData?.ProductId], [stLetterNode]: data?.customerWithCount, [customerNode]: data?.sitesWithCount, [siteNode]: data?.unitsWithCount, [unitNode]: data?.componentsWithCount });
        setExpandedNode([...expandedNode, productNode, stLetterNode, customerNode, siteNode, unitNode])
        props.handleSearchTreeData && props.handleSearchTreeData({ nodeType: "unit" }, data?.sampleDetails)
        setSelectedNode(unitNode)
        return setSelectedTreeNode(unitNode)
      }
    }

    if (rowData?.SampleNumber === undefined) {
      const url = `types=customer,site,unit,component&pid=${rowData?.ProductId}&lId=${rowData?.StartLetter}&cId=${rowData?.CustomerId}&sid=${rowData?.SiteId}&uid=${rowData?.UnitId}&compid=${rowData?.ComponentId}&ShowArchived=${archivedCheck}`
      const data = await get(`${DETAILS_APIS.SEARCH_TREE}?${url}`, { skipBlocker: true });
      if (refresh === true) {
        setNodes({ [productNode]: customerLetters[rowData?.ProductId], [stLetterNode]: data?.customerWithCount, [customerNode]: data?.sitesWithCount, [siteNode]: data?.unitsWithCount, [unitNode]: data?.componentsWithCount, [componentNode]: data?.samplesWithCount });
        setExpandedNode([productNode, stLetterNode, customerNode, siteNode, unitNode, componentNode])
        props.handleSearchTreeData && props.handleSearchTreeData({ nodeType: "component" }, data?.sampleDetails)
        setSelectedNode(componentNode)
        return setSelectedTreeNode(componentNode)
      }
      else {
        setNodes({ ...nodes, [productNode]: (customerLetters?.length > 0 ? customerLetters : data.customerStartLetters)[rowData?.ProductId], [stLetterNode]: data?.customerWithCount, [customerNode]: data?.sitesWithCount, [siteNode]: data?.unitsWithCount, [unitNode]: data?.componentsWithCount, [componentNode]: data?.samplesWithCount });
        setExpandedNode([...expandedNode, productNode, stLetterNode, customerNode, siteNode, unitNode, componentNode])
        props.handleSearchTreeData && props.handleSearchTreeData({ nodeType: "component" }, data?.sampleDetails)
        setSelectedNode(componentNode)
        return setSelectedTreeNode(componentNode)
      }
    }

    if (rowData?.SampleNumber !== undefined) {
      const url = `types=customer,site,unit,component,sample&pid=${rowData?.ProductId}&lId=${rowData?.StartLetter}&cId=${rowData?.CustomerId}&sid=${rowData?.SiteID || rowData?.SiteId}&uid=${rowData?.UnitId}&compid=${rowData?.ComponentId}&sampleNo=${rowData?.SampleNumber}&ShowArchived=${archivedCheck}`
      const url1 = `types=&pid=${rowData?.ProductId}&lId=${rowData?.StartLetter}&cId=${rowData?.CustomerId}&sid=${rowData?.SiteID || rowData?.SiteId}&uid=${rowData?.UnitId}&compid=${rowData?.ComponentId}&sampleNo=${rowData?.SampleNumber}&ShowArchived=${archivedCheck}`
      const data = await get(`${DETAILS_APIS.SEARCH_TREE}?${check === true ? url1 : url}`, { skipBlocker: true });
      if (refresh === true) {
        setNodes({ [productNode]: customerLetters[rowData?.ProductId], [stLetterNode]: data?.customerWithCount, [customerNode]: data?.sitesWithCount, [siteNode]: data?.unitsWithCount, [unitNode]: data?.componentsWithCount, [componentNode]: data?.samplesWithCount });
        setExpandedNode([productNode, stLetterNode, customerNode, siteNode, unitNode, componentNode])
        props.handleSearchTreeData && props.handleSearchTreeData({ nodeType: "sample" }, data?.sampleDetails)
        setSelectedNode(sampleNode)
        return setSelectedTreeNode(sampleNode)
      }
      else {
        setNodes({ ...nodes, [productNode]: (customerLetters?.length > 0 ? customerLetters : data.customerStartLetters)[rowData?.ProductId], [stLetterNode]: data?.customerWithCount, [customerNode]: data?.sitesWithCount, [siteNode]: data?.unitsWithCount, [unitNode]: data?.componentsWithCount, [componentNode]: data?.samplesWithCount });
        setExpandedNode([...expandedNode, productNode, stLetterNode, customerNode, siteNode, unitNode, componentNode])
        props.handleSearchTreeData && props.handleSearchTreeData({ nodeType: "sample" }, data?.sampleDetails)
        setSelectedNode(sampleNode)
        return setSelectedTreeNode(sampleNode)
      }
    }
  }

  const cachedSearchTree = useCallback((rowData, check, refresh) => {
    if (rowData !== null && rowData !== undefined) {
      handleSearchTree(rowData, check, refresh)
    }
  }, [localData]);

  function closeDialog() {
    setDialogType({ open: false });
  }

  async function getSampleReport(sampleNumber, fileName) {
    if (sampleNumber !== undefined) {
      await getFile(`${TRAY_APIS.REPORT_SAMPLE}/?sampleNo=${sampleNumber}`, fileName);
    }
    else return null;
  }

  const getMachineReport = async (data) => {
    if (data !== undefined) {
      let fileName = `${data?.Customer || ""} ${data?.Site || ""} ${data?.Unit || ""} `
      await getFile(`${TRAY_APIS.MACHINE_REPORT}/?Machine_UnitId=${data?.UnitId}`, fileName);
    }
    else return null;
  }

  const getAllSamplesList = async (data) => {
    let allSamplesList = await get(`${DETAILS_APIS.SAMPLES_LIST_ALL}/?customerId=${data.CustomerId}&siteId=${data.SiteId || 0}&unitId=${data.UnitId || 0}&componentId=${data.ComponentId || 0}`, { skipBlocker: true });
    return (allSamplesList);
  };

  const getTopSamplesList = async (data) => {
    let topOneSample = await get(`${DETAILS_APIS.SAMPLES_LIST}/?customerId=${data.CustomerId}&siteId=${data.SiteId || 0}&unitId=${data.UnitId || 0}&componentId=${data.ComponentId || 0}`);
    return topOneSample;
  };
  const getSamplesReportDetails = async (data) => {
    let topOneSample = await get(`${DETAILS_APIS.GET_SAMPLE_TREE_TOOLSTRIP}/?currentTreeCustomerId=${data.CustomerId}&currentTreeSiteId=${data.SiteId || 0}&currentTreeUnitId=${data.UnitId || 0}&currentTreeComponentId=${data.ComponentId || 0}`);
    return topOneSample;
  };

  const handleAddSampleToEmail = async (data, samples) => {
    const getUniqueValues = (array) => (
      [...new Set(array)]
    )
    let parseData = [];
    let emailData = localStorage.getItem("emailSelectedItems")

    if (emailData !== null && emailData !== "null" && emailData !== undefined) {
      parseData = JSON.parse(emailData)
    }
    if (samples === "all") {
      let samplesList = await getAllSamplesList(data);
      let newList = getUniqueValues([...parseData, ...samplesList])
      localStorage.setItem("emailSelectedItems", JSON.stringify(newList))
      return window.dispatchEvent(new Event('storage'))
    }
    if (samples === "sample") {
      let newList = getUniqueValues([...parseData, data?.SampleNumber])
      localStorage.setItem("emailSelectedItems", JSON.stringify(newList))
      return window.dispatchEvent(new Event('storage'))
    }
    else {
      let samplesList = await getTopSamplesList(data);
      let newList = getUniqueValues([...parseData, ...samplesList])
      localStorage.setItem("emailSelectedItems", JSON.stringify(newList))
      return window.dispatchEvent(new Event('storage'))
    }
  }

  const handleSampleReport = async (data) => {
    let a = await getSamplesReportDetails(data);

    let fileName = a.toString().replaceAll(",", " ")
    let sampleNumber = a[a.length - 1]
    getSampleReport(sampleNumber, fileName)
  }

  const copyContent = async (text, status) => {
    if (status === "all") {
      let a = await getAllSamplesList(text);
      return copyTextToClipboard(a)
    }
    if (status === "one") {
      let a = await getTopSamplesList(text);
      let b = null;
      if (a.length > 1) {
        b = a[0]
      }
      else { b = a }
      return copyTextToClipboard(b)
        .then(() => {
          // If successful, update the isCopied state value
          console.log('Copied text to clipboard')
        })
        .catch((err) => {
          console.err(err);
        });
    }

    copyTextToClipboard(text)
      .then(() => {
        // If successful, update the isCopied state value
        console.log('Copied text to clipboard')
      })
      .catch((err) => {
        console.err(err);
      });
  }

  const addNew = (actionName, row) => {
    setSelectedTreeNode("")
    setDialogType({ type: actionName, open: true, data: row });
  }

  const getReferenceXSD = async (data) => {
    await postFile(`${REPORT_APIS.REFRENCE_XSD}`, {
      ProductIds: data?.ProductId?.toString(),
      CustomerIds: data?.CustomerId?.toString(),
      SiteIds: data?.SiteId?.toString() || data?.SiteID?.toString(),
      UnitIds: data?.UnitId?.toString(),
      ComponentIds: data?.ComponentId?.toString(),
    }, "Komatsu CMS Reference");
  };

  const getHistoryXML = async (data) => {
    await postFile(`${REPORT_APIS.HISTORY_XML}`, {
      ProductId: data?.ProductId,
      CustomerId: data?.CustomerId,
      SiteId: data?.SiteId || data?.SiteID,
      UnitId: data?.UnitId,
      ComponentId: data?.ComponentId,
      ContactId: data?.ComponentId,
      SampleNumbers: data?.SampleNumber?.toString(),
    }, "Komatsu CMS Reference");
  };

  const getHistoryXLSX = async (data) => {
    await postFile(`${REPORT_APIS.HISTORY_XLSX}`, {
      ProductId: data?.ProductId,
      CustomerId: data?.CustomerId,
      SiteId: data?.SiteId || data?.SiteID,
      UnitId: data?.UnitId,
      ComponentId: data?.ComponentId,
      ContactId: data?.ComponentId,
      SampleNumbers: data?.SampleNumber?.toString(),
    }, "Komatsu CMS Reference");
  };
  const handleRouteChange = (rows) => {
    if (window.location.pathname !== "/details/quick") {
      const treeData = {
        ProductId: rows?.ProductId,
        StartLetter: rows?.StartLetter,
        CustomerId: rows?.CustomerId,
        SiteId: rows?.SiteId,
        UnitId: rows?.UnitId,
        ComponentId: rows?.ComponentId,
        SampleNumber: rows?.SampleNumber,
      };
      localStorage.setItem("treeData", JSON.stringify(treeData));
      window.open(window.location.origin + "/details/quick", "_blank")
    }
  };

  const handleRefreshTreeClick = (data) => {
    handleSearchTree(data, null, true)
  }

  const HandleNewWindow = (actionName, data) => {
    setSelectedTreeNode("")
    useWindowPopups(actionName, data)
  }

  function handleRightSelect(actionName, data) {
    if (Object.values(WindowTypes).includes(actionName)) {
      HandleNewWindow(actionName, data);
      return;
    }
    if (Object.values(DialogTypes).includes(actionName)) {
      addNew(actionName, data);
      return;
    }
    if (actionName === "refresh-tree") { return handleRefreshTreeClick(data); }
    if (actionName === 'email-selected-items') { return handleAddSampleToEmail(data); }
    if (actionName === 'email-selected-items-all') { return handleAddSampleToEmail(data, "all"); }
    if (actionName === 'email-selected-items-sample') { return handleAddSampleToEmail(data, "sample"); }
    if (actionName === 'sample-report') { return handleSampleReport(data); }
    if (actionName === 'view-mhr-report') { return getMachineReport(data); }
    if (actionName === 'copy-sample-number') { return copyContent(data, "one"); }
    if (actionName === 'copy-sample-number-all') { return copyContent(data, "all"); }
    if (actionName === 'copy-sample-number-sample') { return copyContent(data?.SampleNumber); }
    if (actionName === "reference-xsd") { return getReferenceXSD(data); }
    if (actionName === "history-as-xml") { return getHistoryXML(data); }
    if (actionName === "history-as-xlsx") { return getHistoryXLSX(data); }
    if (actionName === "view-in-quick-details") { return handleRouteChange(data); }

    else { console.err("err", actionName); }
  }

  const handleRefreshTree = async (arch) => {
    const data = await get(`${DETAILS_APIS.PRODUCTS_WITH_CUSTOMERCOUNT}?ShowArchived=${arch === undefined ? archivedCheck : arch}`, { skipBlocker: true });
    setProductsWithCount(data.productWithCount);
    setProductsWithCountList((data.productWithCount))
    setCustomersLetters(data.customerStartLetters);
  }

  useEffect(() => {
    let color2 = productColors(2);
    setProductColor(color2);

    handleRefreshTree()

    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, []);

  useEffect(() => {
    if (expandTreeData && Object.entries(expandTreeData)?.length > 0) {
      if (expandTreeData?.refreshCheck === true) { handleSearchTree(expandTreeData, true, true) }
      else { handleSearchTree(expandTreeData, true) }
    }
  }, [expandTreeData])

  useEffect(() => {
    if (productsWithCount?.length > 0) {
      if (expandTreeData1 && Object.entries(expandTreeData1)?.length > 0) {
        if (expandTreeData1?.refreshCheck === true) { handleSearchTree(expandTreeData1, true, true) }
        else { handleSearchTree(expandTreeData1) }
      }
    }
  }, [productsWithCount, expandTreeData1])

  useEffect(() => {
    (async () => {
      const nodePath = node && parseNodeId(node);
      if (node) {

        if (!nodes[node]) {
          if (nodePath.nodeType === "product") {
            if (Object.keys(customerLetters)?.includes(nodePath.product) == false) { return; }

            setNodes({ ...nodes, [node]: customerLetters[nodePath.product] });
            setExpandedNode([...expandedNode, node]);
          } else if (nodePath.nodeType === "stLetter") {
            const data = await get("/Details/CustomersWithCount?ShowArchived=" + archivedCheck + "&productId=" + nodePath.product + "&stLetter=" + nodePath.stLetter);
            setNodes({ ...nodes, [node]: data });
            setExpandedNode([...expandedNode, node]);
          }
          else if (nodePath.nodeType === "customer") {
            const data = await get("/details/customers-with-site?ShowArchived=" + archivedCheck + "&CustomerId=" + nodePath.customer);
            setNodes({ ...nodes, [node]: data?.siteWithCount });
            setSiteWithCount({ ...siteWithCounts, ...data?.siteWithCount })
            setUnitWithCount({ ...unitWithCounts, ...data?.unitWithCount })
            setComponentWithCount({ ...componentWithCounts, ...data?.componentWithCount })
            setExpandedNode([...expandedNode, node]);
          }
          else if (nodePath.nodeType === "site") {
            if (Object.entries(unitWithCounts)?.length > 0 && unitWithCounts[nodePath.site] !== undefined) {
              setNodes({ ...nodes, [node]: unitWithCounts[nodePath.site] });
              setExpandedNode([...expandedNode, node]);
            }
            else {
              const data = await getNodeData("/Details/UnitsWithCount?ShowArchived=" + archivedCheck + "&customerId=" + nodePath.customer + "&siteId=" + nodePath.site);
              if (data?.length > 0) {
                setNodes({ ...nodes, [node]: data });
                setExpandedNode([...expandedNode, node]);
              }
            }
          }
          else if (nodePath.nodeType === "unit") {
            if (Object.entries(unitWithCounts)?.length > 0 && componentWithCounts[nodePath.unit] !== undefined) {
              setNodes({ ...nodes, [node]: componentWithCounts[nodePath.unit] });
              setExpandedNode([...expandedNode, node]);
            }
            else {
              const data = await getNodeData("/Details/ComponentsWithCount?ShowArchived=" + archivedCheck + "&unitId=" + nodePath.unit); setNodes({ ...nodes, [node]: data });
              if (data?.length > 0) {
                setNodes({ ...nodes, [node]: data });
                setExpandedNode([...expandedNode, node]);
              }
            }
          }
          else if (nodePath.nodeType === "component") {
            const data = await get("/Details/SamplesWithCount?ShowArchived=" + archivedCheck + "&componentId=" + nodePath.component, { skipBlocker: true });
            setNodes({ ...nodes, [node]: data });
            setExpandedNode([...expandedNode, node]);
          }
          return;
        }
        expandNodes(node)
      }

    })();
  }, [node]);

  useEffect(() => {
    setTimeout(
      function handleScroll() {
        if (selectedTreeNode === "") return;
        let id1 = document.querySelector("[id$=" + "\'" + selectedTreeNode + "\'" + "]");
        if (id1 !== null) return id1.scrollIntoView();
      }, 1200)
  }, [selectedTreeNode])

  useEffect(() => {
    if (searchOpen === true) {
      addNew("search-general", null)
      setSearchOpen(false)
    }
  }, [searchOpen])

  const handleArchive = () => {
    if (archivedCheck === true) {
      handleRefreshTree(false)
    }
    if (archivedCheck === false) {
      handleRefreshTree(true)
    }
    setNode("");
    setNodes({});
    setExpandedNode([]);
    setSelectedNode("")
    setSelectedTreeNode("")
    setArchivedCheck(!archivedCheck)
  }

  useEffect(() => {
    if (localData !== null && localData !== undefined) {
      cachedSearchTree(localData, true, true)
    }
  }, [localData])

  function handleStorage(event) {
    let sg = JSON.parse(localStorage.getItem("search-general-" + ScreenName + "-resp"))
    if (sg !== undefined && sg !== null) {
      setLocalData(sg)
      localStorage.removeItem("search-general-" + ScreenName + "-resp")
    }

    let tr = JSON.parse(localStorage.getItem("tree-resp"))
    debugger
    console.log("tree resp", tr)
    if (tr !== undefined && tr !== null) {
      setLocalData(tr)
      // handleSearchTree(tr, true, true)
      localStorage.removeItem("tree-resp");
    }
  }

  useEffect(() => {
    window.addEventListener('storage', handleStorage, false);
    return () => {
      document.removeEventListener('storage', handleStorage);
    };
  });

  return (
    <Card
      variant="outlined"
    >
      <Box id="treee" >

        <Box sx={{ py: 0.5, ...productColor || {}, }} >
          <FormControlLabel
            control={
              <Checkbox
                name="isArchived"
                value="remember"
                checked={archivedCheck}
                onChange={handleArchive}
                sx={{ [`.MuiSvgIcon-root`]: { fill: "white", fontSize: "18px" } }}
              />
            }
            label="Show Archived"
            sx={{ margin: 0 }} />

          <Button
            variant="outlined"
            sx={{ float: "right", mr: 1, mt: 0.5, backgroundColor: "white", }}
            startIcon={<SearchIcon />}
            // onClick={() => addNew("search-general", null)}
            onClick={() => HandleNewWindow("search-general", { ScreenName: ScreenName })}
          >
            Search
          </Button>
        </Box>

        <Box sx={{
          mt: 0.5,
          padding: 0,
          minHeight: minHeight || "86dvh",//"78vh"
          maxHeight: maxHeight || "86dvh",//"78vh"
          width: "auto",
          overflowX: "auto",
          overflowY: "auto",
        }} >
          <Tree
            onNodeSelect={handleNodeSelected}
            expanded={expandedNode}
          >
            {productsWithCountList.map((p, i) => {
              const productId = { name: "product", val: p.ProductId };
              return (
                <Product
                  {...p}
                  key={i + ""}
                  pathItems={[productId]}
                  expandedNode={expandedNode}
                  handleLabelClick={handleLabelClick}
                  handleRightSelect={handleRightSelect}
                  selectedNode={selectedNode}
                >
                  {(nodes[getNodeId([productId])] || [{}]).map((s, j) => {
                    const stlId = { name: "stLetter", val: s.StartLetter };
                    return (
                      <CustomerSl
                        {...s}
                        key={j + ""}
                        pathItems={[productId, stlId]}
                        expandedNode={expandedNode}
                        handleLabelClick={handleLabelClick}
                        handleRightSelect={handleRightSelect}
                        selectedNode={selectedNode}
                      >
                        {(nodes[getNodeId([productId, stlId])] || [{}]).map(
                          (c, k) => {
                            const custId = { name: "customer", val: c.CustomerId };
                            return (
                              <Customer
                                {...c}
                                key={k + ""}
                                pathItems={[productId, stlId, custId]}
                                expandedNode={expandedNode}
                                handleLabelClick={handleLabelClick}
                                handleRightSelect={handleRightSelect}
                                selectedNode={selectedNode}
                              >
                                {(
                                  nodes[getNodeId([productId, stlId, custId])] || [{},]).map((s, l) => {
                                    const siteId = { name: "site", val: s.SiteId };
                                    return (
                                      <Site
                                        {...s}
                                        key={l + ""}
                                        pathItems={[productId, stlId, custId, siteId]}
                                        expandedNode={expandedNode}
                                        handleLabelClick={handleLabelClick}
                                        handleRightSelect={handleRightSelect}
                                        selectedNode={selectedNode}
                                      >
                                        {(
                                          nodes[
                                          getNodeId([productId, stlId, custId, siteId,])] || [{}]).map((u, m) => {
                                            const unitId = { name: "unit", val: u.UnitId, };
                                            return (
                                              <Unit
                                                {...u}
                                                key={m + ""}
                                                pathItems={[productId, stlId, custId, siteId, unitId,]}
                                                expandedNode={expandedNode}
                                                handleLabelClick={handleLabelClick}
                                                handleRightSelect={handleRightSelect}
                                                selectedNode={selectedNode}
                                              >
                                                {(
                                                  nodes[
                                                  getNodeId([productId, stlId, custId, siteId, unitId,])] || [{}]).map((comp, n) => {
                                                    const componentId = { name: "component", val: comp.ComponentId, };
                                                    return (
                                                      <Component
                                                        {...comp}
                                                        key={n + ""}
                                                        pathItems={[productId, stlId, custId, siteId, unitId, componentId,]}
                                                        expandedNode={expandedNode}
                                                        handleLabelClick={handleLabelClick}
                                                        handleRightSelect={handleRightSelect}
                                                        selectedNode={selectedNode}
                                                        comp2Click={comp2Click}
                                                      >
                                                        {(
                                                          nodes[
                                                          getNodeId([productId, stlId, custId, siteId, unitId, componentId])] || [{}]).map((samp, o) => {
                                                            const sample = { name: "sample", val: samp.SampleNumber, };
                                                            return (
                                                              <Sample
                                                                {...samp}
                                                                key={o + ""}
                                                                pathItems={[productId, stlId, custId, siteId, unitId, componentId, sample,]}
                                                                handleLabelClick={handleLabelClick}
                                                                handleRightSelect={handleRightSelect}
                                                                selectedNode={selectedNode}
                                                              />
                                                            );
                                                          })}
                                                      </Component>
                                                    );
                                                  })}
                                              </Unit>
                                            );
                                          })}
                                      </Site>
                                    );
                                  })}
                              </Customer>
                            );
                          }
                        )}
                      </CustomerSl>
                    );
                  })}
                </Product>
              );
            })}
          </Tree>
        </Box >

        {(dialogType?.type !== undefined) && <GroupDialogs
          // {...dialogOptions}
          dialogType={dialogType}
          open={dialogType.open || false}
          close={closeDialog}
          screenDialogsProps={{
            cancel: closeDialog,
            handleSearchTree: handleSearchTree
          }}
        />}
      </Box>
    </Card>

  );
}