import { Backdrop, Box, Button, Card, CircularProgress, Grid, List, ListItem, Typography, } from "@mui/material";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { TRAY_APIS } from "../../../common/apis";
import PaperlessTable from "./PaperlessTable";
import Form, { FormActions } from "../../../components/Form";
import TextBox from "../../../components/TextBox";
import { useRoot, useUser } from "../../../rootContext";
import AppDialog from "../../../components/AppDialog";
import ConfirmModal from "../../../components/ConfirmModal";
import { useReactToPrint } from "react-to-print";
import ComponentToPrintSVG from "./PrintAllStickers";
import FormFooter from "../../../components/FormFooter";
import moment from "moment/moment";
import useMasterData from "../../../hooks/useMasterData";
import qrScanner from "../../../Image/qrScanner.gif";
import { toBasicLocalDate, toDateTimeFormat, toLocalDayDate, } from "../../../utils";
import { PrintSticker2 } from "../paperless/PrintSticker";
import ViewSticker from "../paperless/ViewSticker";

const todayDate = new Date();

const maxDateAllowed = new Date(
    todayDate.getFullYear(),
    todayDate.getMonth(),
    todayDate.getDate() + 6
);

const minDateAllowed = new Date(
    todayDate.getFullYear(),
    todayDate.getMonth(),
    todayDate.getDate() - 6
);

export default function Paperless2() {
    let audio = new Audio("/buzzer.mp3")

    const { get, post } = useFetch();
    const user = useUser();
    const { CanDoDataMaintenance, CanCreateTrays } = useRoot();

    const regIdInput = useRef();
    const componentRef = useRef();
    const componentRef1 = useRef();

    const [data, dispatch] = useReducer(PaperlessReducer, paperlessDefaults);
    const { getMasterData } = useMasterData();

    const [masterData, setMasterData] = useState({});
    const [infoMessage, setInfoMessage] = useState("");

    const [originalTableData, setOriginalTableData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [registrationVal, setRegistrationVal] = useState("");
    const [focusCheck, setFocusCheck] = useState(false);
    const [startCheck, setStartCheck] = useState(false);
    const [finishCheck, setFinishCheck] = useState(false);
    const [errMessage, setErrMessage] = useState("")
    const [keyIndex, setKeyIndex] = useState(0)
    const [bdCheck, setBDCheck] = useState(false);
    const [missedList, setMissedList] = useState([]);

    const [regData, setRegData] = useState({});
    const [dialog, setDialog] = useState({
        title: "",
        message: "",
        actionName: "",
        open: false,
        width: "",
    });

    const sendToRegistration = async (a) => {
        const resp = await post(TRAY_APIS.Paperless2, tableData);
        if (resp > 0) {
            handleClearAll();
        }
    }

    const handleRowSelect = (row) => {
        if (row?.BottleNumber > 0) {
            return;
        }
        else setKeyIndex(row?.keyProp)
    }

    const handleDeleteEntry = async (del) => {
        const sampleDeleted = await get(`${TRAY_APIS.DELETE_SELECTED_SAMPLE}?SampleNumber=${del}&Screen=Registration`);
        if (sampleDeleted > 0) {
            const arr = tableData.filter((item) => {
                return item.SampleNumber !== del
            })
            const arr2 = arr.map((item, i) => { return { ...item, keyProp: i } })

            setTableData(arr2);
            setRegData({});
            setRegistrationVal("");
            setDialog({ open: false, });
            dispatch({
                type: FormActions.LOAD_DATA,
                payload: { ...data, NumberOfSamples: arr2?.length }
            })
        }
    }
    const handleClearEntry = async (row) => {
        if (row?.BottleNumber > 0 || row?.BottleNumber?.length > 0) {

            const otd = [...originalTableData];
            const td = [...tableData];
            const toAddItem = otd.filter((item) => {
                return item?.SampleNumber === row?.SampleNumber
            }).pop();
            td[row?.keyProp] = toAddItem;

            const arr2 = td.map((item, i) => { return { ...item, keyProp: i } });
            setTableData(arr2);
            setKeyIndex(row?.keyProp)
            setRegData({});
            setRegistrationVal("");
            setDialog({ open: false, });
        }
    }

    const handleClearAll = () => {
        setInfoMessage("");
        setMissedList([]);
        setTableData([]);
        setRegistrationVal("");
        setFocusCheck(false);
        setStartCheck(false);
        setFinishCheck(false);
        setDialog({
            open: false,
        })
        setRegData({});
        setKeyIndex(0);
        dispatch({
            payload: { OwnerLaboratoryId: user?.DefaultLaboratoryId || 1 },
            type: FormActions.RESET_FORM
        });
    }

    async function playSound() {
        setRegistrationVal("");
        regIdInput.current.focus();
        setBDCheck(true);
        audio.play()
        setTimeout(
            function handleScroll() {
                setBDCheck(false);
            }, 500);
    }

    async function onLoadGrid() {
        const responseData = await post(TRAY_APIS.GET, {
            Date: data.TrayDate,
            LabId: data?.OwnerLaboratoryId || user?.DefaultLaboratoryId,
            TrayNumber: data.Tray,
            MinimumStep: 1,
            MaximumStep: data.NumberOfSamples ? data.NumberOfSamples : 1,
            Hold: true,
        });
        const resp = (responseData || []).map((x, i) => ({
            ...x,
            keyProp: i,
        }));
        setTableData(resp);
        setOriginalTableData(resp);
    }

    const handlePaste = event => {
        let val = (event?.clipboardData?.getData('text'));
        actions?.retrieveRegistration(val)
    };

    const handleDeleteTray = async () => {
        handleClearAll();

        const deleteData = await post(TRAY_APIS.DELETE_TRAY, {
            TrayDate: data?.TrayDate,
            Laboratory: data?.OwnerLaboratoryId,
            Tray: data?.Tray,
            UserId: user?.UserId,
            Screen: "Create Trays",
        });
        setDialog({ open: false });

        if (deleteData?.Deleted) {
            setTableData([]);
            setOriginalTableData([]);

            setInfoMessage(
                `Tray ${data.Tray} for ${masterData.Laboratories?.find(
                    (x) => x.LaboratoryId === data?.OwnerLaboratoryId
                )?.Laboratory
                } has been DELETED`
            );
            dispatch({
                payload: { OwnerLaboratoryId: user?.DefaultLaboratoryId || 1 },
                type: FormActions.RESET_FORM,
            });
        }
    };

    const actions = {
        handleTraySummary: async function () {
            const summary = await post(TRAY_APIS.SUMMARY, {
                ...data,
                TrayDate: data?.TrayDate || new moment(),
                Laboratory: data?.OwnerLaboratoryId || user?.DefaultLaboratoryId,
                Tray: data?.Tray,
            });
            if (summary?.SampleCount > 0) {
                setInfoMessage(
                    `Tray ${data.Tray} for ${summary.OwnerLaboratory} has ${summary.SampleCount
                    } sample(s) ${summary.Delivered !== null ? ", delivered " + summary.Delivered : ""
                    }`
                );
            } else {
                setInfoMessage(
                    `Tray ${data.Tray} for ${masterData.Laboratories?.find(
                        (x) => x.LaboratoryId === data?.OwnerLaboratoryId
                    )?.Laboratory
                    } does NOT exist`
                );
            }
        },
        handlePrintSticker: useReactToPrint({
            documentTitle: regData?.RegistrationId,
            content: () => componentRef.current,
            // onBeforePrint: () => console.log("before printing..."),
            // onAfterPrint: () => console.log("after printing..."),
        }),
        handlePrintAll: useReactToPrint({
            content: () => componentRef1.current
        }),
        handleCreateTray: async function () {
            const resp = await post(TRAY_APIS.CREATE, {
                Tray: data.Tray > 999 ? 999 : data.Tray,
                NumberOfSamples: data.NumberOfSamples > 99 ? 99 : data.NumberOfSamples,
                Date: new moment(),
                OwnerLaboratory: user.RealDefaultLaboratoryId,
                Laboratory: data?.OwnerLaboratoryId,
                Label: data.Label,
                IsTraining: data.IsTrainingSample ? data.IsTrainingSample : false,
                Screen: "Create Trays",
                Delivered: data.useDeliveredCheck ? data.useDeliveredDate : null,
            });
            if (resp.ExistingSamples !== 0) {
                setInfoMessage(`Tray ${data.Tray} for ${masterData.Laboratories?.find((x) => x.LaboratoryId === data?.OwnerLaboratoryId)?.Laboratory}
                         already exists and has ${resp.ExistingSamples && resp.ExistingSamples} samples`
                );
                return;
            }
            if (resp.ExistingSamples === 0) {
                setInfoMessage(`Samples 1 to ${data.NumberOfSamples} created in tray ${data.Tray} for ${masterData.Laboratories?.find((x) => x.LaboratoryId === data?.OwnerLaboratoryId)?.Laboratory}`);
                setStartCheck(true);
                onLoadGrid();
            }
        },
        handleViewTray: async function () {
            setFocusCheck(false);
            setFinishCheck(false);
            localStorage.setItem("local00", JSON.stringify({ ...data, CreationDate: moment.utc().format("YYYYMMDD") }))
            const responseData = await post(TRAY_APIS.GET, {
                //  Date: moment(data.TrayDate).format("MM/DD/YYYY h:mm:ss A"),
                Date: data.TrayDate,
                LabId: data?.OwnerLaboratoryId,
                Laboratory: data?.OwnerLaboratoryId || user.DefaultLaboratoryId,
                TrayNumber: data.Tray,
                MinimumStep: 1,
                MaximumStep: data.NumberOfSamples ? data.NumberOfSamples : 8,
                Hold: true,
            });
            if (responseData.length === 0) {
                setInfoMessage(
                    `Tray ${data.Tray} for ${masterData.Laboratories?.find(
                        (x) => x.LaboratoryId === data?.OwnerLaboratoryId
                    )?.Laboratory
                    } does NOT exist`
                );
                setTableData([]);
                setOriginalTableData([]);
                setFocusCheck(false);
                setStartCheck(false);
                setFinishCheck(false);
            } else {
                setInfoMessage(
                    `Tray  ${data.Tray} for ${masterData.Laboratories?.find(
                        (x) => x.LaboratoryId === data?.OwnerLaboratoryId
                    )?.Laboratory
                    } has ${responseData.length} samples`
                );
                if (responseData?.every(item => item.SiteId === null)) {
                    setStartCheck(true);
                }
                const resp = (responseData || []).map((x, i) => ({
                    ...x,
                    keyProp: i,
                    Model: x?.UnitModelFormatted,
                    UnitMeter: x?.UnitHours,
                    ScannedDate: toDateTimeFormat(x?.Received) || "",
                    OilType: x?.FluidType,

                }));
                setKeyIndex(0);
                setTableData(resp);
                setOriginalTableData(resp);
            }
        },
        retrieveRegistration: async function () {
            if (registrationVal) {
                const temp = (await get(`${TRAY_APIS.REGISTRATIONS_BOTTLENUMBER}?bottlenumber=` + registrationVal)) || {};
                const reg = temp?.Registration;
                const comp = temp?.Component;
                if (reg?.SIF > 0) {
                    if (reg?.SampleNumber > 0) {
                        setRegData({});
                        setRegistrationVal("");
                        setErrMessage(`RegistrationId ${reg?.SIF} has already been registered for Sample Number ${reg?.SampleNumber}`);
                        return playSound();
                    }
                    if (reg?.BottleSampleNumber > 0) {
                        setRegData({});
                        setRegistrationVal("");
                        setErrMessage(`RegistrationId ${reg?.SIF} has already been entered against Sample Number ${reg?.BottleSampleNumber}`);
                        return playSound();
                    }
                    const bExists = tableData.filter(i => i?.BottleNumber == reg?.BottleNumber)?.length;
                    const aExist = tableData[keyIndex]?.BottleNumber;
                    if ((aExist > 0 || aExist?.length > 0) || (bExists > 0 || bExists?.length > 0)) {
                        setRegData({});
                        setRegistrationVal("");
                        setErrMessage("")

                        if (keyIndex === (tableData?.length - 1)) {
                            setErrMessage(`You have reached to the end. Please recheck.`);
                        }
                        return playSound();//setErrMessage(`RegistrationId ${reg?.SIF} has already been entered`);
                    }
                    const oil = masterData?.FluidTypes?.find(item => item.FluidTypeId === reg?.FluidTypeId)?.FluidType || "Oil";
                    const payload = {
                        BottleNumber: reg?.BottleNumber,
                        RegistrationId: reg?.SIF,
                        Customer: reg?.Customer,
                        Site: reg?.Site,
                        Model: reg?.UnitMakeNew + " " + reg?.UnitModelNew,// comp?.UnitModelFormatted,
                        Serial: reg?.SerialNew,//comp?.Serial,
                        Component: reg?.ComponentNameNew,//comp?.Component,
                        MeterReading: reg?.UnitMeter,//comp?.LastMeterReading,
                        OilType: oil,
                        ScannedDate: new Date().toLocaleString(),
                        Sampled: new Date(),
                        SampledAssumed: false,
                        SampleOptionId: 0,
                        Laboratory: data?.OwnerLaboratory || user?.DefaultLaboratory,
                        AddedBy: user.Username,
                        UnitId: reg?.UnitId,
                        Unit: reg?.UnitNameNew,
                        UserId: user.UserId,
                        SampleNumber: tableData[keyIndex]?.SampleNumber,
                        Screen: "Create Tray-QR Code",
                    };
                    const newTable = tableData.map((item) => (item.keyProp === keyIndex
                        ? { ...item, ...comp, ...reg, ...payload, SampleNumber: item?.SampleNumber }
                        : { ...item }));

                    setRegistrationVal("");
                    setTableData(newTable);
                    setRegData(payload);
                    setErrMessage("");
                    // setKeyIndex(keyIndex + 1);

                    //following code is implemeted to check if the next sample has bottle number or not
                    for (let i = keyIndex; i <= newTable?.length; i++) {
                        let counter = newTable[i].BottleNumber;
                        if (!(counter > 0 || counter?.length > 0)) {
                            setKeyIndex(i);
                            break;
                        }
                    }

                    // if (keyIndex === (tableData?.length - 1)) {
                    //     setFinishCheck(true)
                    // }
                    //upper code is commented to prevent auto finsih when tray is fully scanned
                    const selectedQuery = document.querySelector('[data-index="' + keyIndex + '"]')
                    if (selectedQuery !== null) {
                        selectedQuery.scrollIntoViewIfNeeded()
                    };
                }
                else {
                    setErrMessage("")
                    setRegData({});
                    setMissedList([...missedList, registrationVal])
                    playSound();
                }
            }
            else return;
        },
        handleSendTray: sendToRegistration,

        alertAddExtraSample: async function () {
            const addedSample = await post(TRAY_APIS.ADD_EXTRA_SAMPLE, {
                TrayDate: data?.TrayDate,//new moment(),
                Laboratory: data?.OwnerLaboratoryId,//user.DefaultLaboratoryId,
                Tray: data.Tray,
                NoofSamples: 1,
                IsTraining: data.IsTrainingSample ? data.IsTrainingSample : false,
                Delivered: data.useDeliveredCheck ? data.useDeliveredDate : null,
                Screen: "Create Trays",
            });

            if (addedSample[0].Added) {
                setInfoMessage(`Sample ${addedSample[0].NumberOfSamples
                    } was added to tray ${data.Tray} for
           ${masterData.Laboratories?.find(
                        (x) => x.LaboratoryId === data?.OwnerLaboratoryId
                    )?.Laboratory
                    }`);

                const resp = await post(TRAY_APIS.GET, {
                    Date: new moment(),
                    LabId: data?.OwnerLaboratoryId,
                    TrayNumber: data.Tray,
                    MinimumStep: 1,
                    MaximumStep: data.NumberOfSamples ? data.NumberOfSamples : 1,
                    Hold: true,
                });

                const toAddItem = resp?.pop();
                const arr = [...tableData, toAddItem];
                const arr2 = arr.map((item, i) => { return { ...item, keyProp: i } });
                setTableData(arr2);
                setKeyIndex(arr2?.length - 1)

                dispatch({
                    type: FormActions.LOAD_DATA,
                    payload: { ...data, NumberOfSamples: arr2?.length }
                })
            }
        },
        alertInsertSampleTray: async function () {
            const addedSample = await post(TRAY_APIS.INSERT_SAMPLE_TRAY, {
                Date: new moment(),
                Laboratory: data?.OwnerLaboratoryId,//user.DefaultLaboratoryId,
                OwnerLaboratory: user?.RealDefaultLaboratoryId || 1,
                Tray: data.Tray,
                Sample: data?.InsertSample,
                UserId: user?.UserId,
                Screen: "Registration",
            });
            if (addedSample > 0) {
                const resp = await post(TRAY_APIS.GET, {
                    Date: new moment(),
                    LabId: data?.OwnerLaboratoryId,// user.DefaultLaboratoryId,
                    TrayNumber: data.Tray,
                    MinimumStep: 1,
                    MaximumStep: data.NumberOfSamples ? data.NumberOfSamples : 1,
                    Hold: true,
                });
                setKeyIndex(data?.InsertSample - 1)
                const td = [...tableData];
                const tN = (data?.Tray < 9) ? ("00" + data?.Tray) : data?.Tray < 99 ? ("0" + data?.Tray) : data?.Tray;
                const sN = data?.InsertSample < 9 ? ("0" + data?.InsertSample) : data?.InsertSample;
                const sampleNo = toBasicLocalDate(new moment()) + (user?.DefaultLaboratoryId || 1) + tN + sN;

                const toAddItem = resp.filter(i => i?.SampleNumber == sampleNo);
                const ixItem = td.filter(i => i?.SampleNumber < sampleNo)?.pop();
                const ix = td.findIndex(i => i == ixItem);
                const arr = [...td.slice(0, (ix + 1)), ...toAddItem, ...td.slice(ix + 1)];

                const arr2 = arr.map((item, i) => { return { ...item, keyProp: i } });
                setTableData(arr2);
                dispatch({
                    type: FormActions.LOAD_DATA,
                    payload: { ...data, NumberOfSamples: arr2?.length }
                })
            }
        },
        handleDeleteAlert: function (row) {
            setDialog({
                title: "Delete",
                message: `Are you sure you want delete SampleNumber ${row?.SampleNumber} in Tray ${data?.Tray} for ${user?.DefaultLaboratory}?`,
                actionName: () => handleDeleteEntry(row?.SampleNumber),
                buttonText: "Delete",
                open: true,
                width: "md",
            })
        },
        handleClearAlert: function (row) {
            setDialog({
                title: "Clear Sample Details",
                message: `Are you sure you want to remove data from SampleNumber ${row?.SampleNumber}?`,
                actionName: () => handleClearEntry(row),
                buttonText: "Clear",
                open: true,
                width: "md",
            })
        },
        handleClearAllAlert: function () {
            setDialog({
                title: "Clear All",
                message: "This will clear all data in the form and table.",
                actionName: handleClearAll,
                buttonText: "Clear",
                open: true,
                width: "md",
            })
        },
        handleDeleteTrayAlert: function () {
            setDialog({
                title: `Delete Tray ${data?.Tray || ""}`,
                message: `Are you sure you wish to DELETE tray ${data.Tray
                    } on ${toLocalDayDate(data?.TrayDate)} for ${masterData?.Laboratories?.find(
                        (x) => x.LaboratoryId === data?.OwnerLaboratoryId
                    )?.Laboratory
                    }?`,
                actionName: handleDeleteTray,
                buttonText: "Delete Tray",
                open: true,
                width: "md",
            })
        },
    };

    const handleEnterDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            actions?.retrieveRegistration(registrationVal)
        }
    }

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === "F1") {
                event.preventDefault();
                actions.handleCreateTray();
            }
            if (event.key === "F2") {
                event.preventDefault();
                if (startCheck || !focusCheck) {
                    setFocusCheck(true);
                }
            }
            if (event.key === "F3") {
                event.preventDefault();
                if (focusCheck || !finishCheck) {
                    setFinishCheck(true);
                }
            }
            if (event.key === "F4") {
                event.preventDefault();
                if (registrationVal > 0) {
                    actions.handlePrintSticker();
                }
            }
            if (event.key === "F5") {
                event.preventDefault();
                if (finishCheck) {
                    actions.handlePrintAll();
                }
            }
            if (event.key === "F10") {
                event.preventDefault();
                if (finishCheck) {
                    actions.handleSendTray();
                }
            }
            if (event.key === "F12") {
                event.preventDefault();
                if (registrationVal > 0) {
                    actions?.retrieveRegistration(registrationVal)
                }
            }
        }, [data, registrationVal, finishCheck, focusCheck, startCheck]);

    useEffect(function () {
        document.title = "Create Trays-QR Code";

        (async function () {
            const temp = await getMasterData("Laboratories,FluidTypes");
            if (temp.Laboratories) {
                temp.Laboratories = temp.Laboratories.filter((x) => !x.IsForImporting);
            }
            setMasterData(temp);
        })();

    }, []);

    useEffect(() => {
        dispatch({
            type: FormActions.LOAD_DATA,
            payload: { ...data, OwnerLaboratoryId: user.DefaultLaboratoryId, }
        })
    }, [user]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            regIdInput.current.focus();
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    }, [focusCheck]);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    const trayDateSorted = new Date(
        todayDate?.getFullYear(),
        todayDate?.getMonth(),
        todayDate?.getDate()
    );

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={2} >
                    <Card sx={{
                        p: 1,
                        minHeight: "89dvh",
                        backgroundColor: "#e2f2ff"
                    }}>
                        <Grid container spacing={1} >
                            <Grid item flex={1} hidden={!focusCheck || finishCheck}>
                                <TextBox
                                    ref={regIdInput}
                                    id={"RegistrationId"}
                                    name="RegistrationId"
                                    value={registrationVal}
                                    onChange={(e) => {
                                        let a = e.target.value.replaceAll("*", "");
                                        setRegistrationVal(a);
                                    }}
                                    // placeHolder={"Registration Id"}
                                    placeHolder={"Bottle Number"}
                                    onPaste={(e) => handlePaste(e)}
                                    onKeyUp={(e) => handleEnterDown(e)}
                                    autoComplete="off"
                                    autoFocus
                                    sx={{
                                        "& .MuiInputBase-input": { backgroundColor: "white" },
                                        '& input[type=number]': {
                                            '-moz-appearance': 'textfield'
                                        },
                                        '& input[type=number]::-webkit-outer-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        },
                                        '& input[type=number]::-webkit-inner-spin-button': {
                                            '-webkit-appearance': 'none',
                                            margin: 0
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item hidden={!focusCheck || finishCheck}>
                                <Button onClick={actions?.retrieveRegistration}>Scan Bottle (F12)</Button>
                            </Grid>

                            <Grid item xs={12}  >
                                {regData?.RegistrationId > 0 &&
                                    <Grid container sx={{ border: "1px solid black", p: 1, backgroundColor: "#E9F4FF", mt: 1 }} justifyContent={"center"}>
                                        <Grid item xs={12}  >
                                            <Typography  >Bottle Sticker</Typography>
                                            <ViewSticker regData={regData} />
                                        </Grid>
                                        <Grid hidden={true}>
                                            <div ref={componentRef}>
                                                <PrintSticker2 regData={regData} />
                                            </div>
                                        </Grid>

                                        <Grid item  >
                                            <Button onClick={() => { actions?.handlePrintSticker(null, () => componentRef.current); }}
                                                sx={{ ml: 2, backgroundColor: "white", mt: 2 }} variant="outlined">Print Sticker (F4)</Button>
                                        </Grid>
                                        {<Grid item hidden={true}   >
                                            <ComponentToPrintSVG
                                                details={tableData.filter(i => i?.RegistrationId > 0)}
                                                ref={componentRef1}
                                            />
                                        </Grid>}
                                    </Grid>
                                }
                                {errMessage?.length > 0
                                    ? <Typography sx={{ color: "red", position: "relative", border: "1px solid red", p: 1 }}>{errMessage}</Typography>
                                    : <></>
                                }
                                {missedList?.length > 0 &&
                                    <Grid container sx={{
                                        p: 1, backgroundColor: "#E9F4FF",
                                        mt: 1,
                                    }}
                                        justifyContent={"center"}
                                    >

                                        <Grid item
                                            xs={12}
                                            sx={{
                                                position: "absolute",
                                                bottom: "5%"
                                            }}
                                        >
                                            <Typography>Bottle Numbers not present</Typography>

                                            <Box sx={{
                                                border: "1px solid black",
                                                textAlign: "center",
                                                backgroundColor: "white",
                                                width: "230px!important",
                                                height: "230px!important",
                                                overflow: "auto",
                                                p: 1,

                                            }}>
                                                <List>
                                                    {missedList?.map((item, i) => {
                                                        return <ListItem sx={{ padding: 0 }}>
                                                            <Typography>{(i + 1)} : {item}</Typography>
                                                        </ListItem>
                                                    })}
                                                </List>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                }
                            </Grid>

                        </Grid>
                    </Card>
                </Grid >

                <Grid item xs={10} >
                    <>
                        <Card sx={{ p: 1, mb: 1 }}>
                            <Grid container spacing={1}>

                                <Grid item xs={7}>
                                    <Form
                                        sections={GetFormSections(data, actions, focusCheck, finishCheck, CanDoDataMaintenance, trayDateSorted, minDateAllowed, maxDateAllowed)}
                                        data={data}
                                        spacing={1}
                                        dispatch={dispatch}
                                        key={data?._key}
                                        masterData={masterData}

                                    />

                                    <Grid container spacing={1} alignItems={"flex-end"} mt={1}>
                                        <Grid item>
                                            <Button onClick={actions?.handleCreateTray} disabled={!(data?.Label?.length > 0 && data?.Tray > 0 && data?.NumberOfSamples > 0 && !startCheck)} >Create Tray (F1)</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={() => setFocusCheck(true)} disabled={!startCheck || focusCheck} variant="outlined">Start (F2)</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={() => { setFinishCheck(true); }} disabled={!focusCheck || finishCheck} >Finish (F3)</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={actions?.handleDeleteTrayAlert} disabled={!(tableData?.length > 0)} variant="outlined" >Delete Tray</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={handleClearAll} variant="outlined" >Clear All</Button>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item xs={2}   >
                                    {(!focusCheck || finishCheck)
                                        ? <></>
                                        : <Box
                                            component="img"
                                            sx={{
                                                height: 80,
                                                float: "right",
                                            }}
                                            alt="KOWA"
                                            src={qrScanner}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={3} alignSelf="flex-end" justifyContent="flex-end"  >
                                    <Typography sx={{ float: "right", color: "blue", }}> {infoMessage || ""}</Typography>
                                    <FormFooter
                                        buttons={GetFormButtons(actions, finishCheck, tableData)}
                                    />
                                </Grid>

                            </Grid>
                        </Card>
                        <Typography>Total Records: <b>{tableData?.length || "0"}</b></Typography >

                        <PaperlessTable
                            data={tableData}
                            actions={actions}
                            finishCheck={finishCheck}
                            rowsSelected={handleRowSelect}
                        />
                    </>
                </Grid>
            </Grid >

            <AppDialog
                open={dialog.open}
                handleClose={() =>
                    setDialog((prevState) => ({
                        ...prevState,
                        open: false,
                    }))
                }
                title={dialog.title}
                maxWidth={dialog.width}
            >
                <ConfirmModal
                    handleClose={() => {
                        setDialog((prevState) => ({
                            ...prevState,
                            open: false,
                        }));
                    }}
                    alertAction={dialog?.actionName}
                    message={dialog.message}
                    buttonText={dialog?.buttonText}
                    buttonText2={"Cancel"}
                />
            </AppDialog>
            <Backdrop
                sx={{ backgroundColor: "rgba(245, 176, 176,0.5)" }}
                open={bdCheck}
                style={{ zIndex: 2000 }}
            >

                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

function GetFormSections(data, actions, focusCheck, finishCheck, CanDoDataMaintenance, trayDateSorted, minDateAllowed, maxDateAllowed) {
    return [
        {
            fields: [
                {
                    group: [
                        {
                            name: "useDeliveredCheck",
                            label: "Delivered",
                            type: "switch",
                            xsCol: 1.5,
                        },
                        {
                            name: "useDeliveredDate",
                            label: "Use Delivered",
                            type: "datetime",
                            xsCol: 2,
                            disabled: (data) => !data.useDeliveredCheck,
                            disableFuture: true,
                        },
                        {
                            name: "TrayDate",
                            label: "Create Tray For",
                            type: "date",
                            sx: {
                                [`.MuiInputBase-root`]: {
                                    backgroundColor: ((CanDoDataMaintenance !== true && trayDateSorted < minDateAllowed) || (trayDateSorted > maxDateAllowed)) && "red",
                                    color: ((CanDoDataMaintenance !== true && trayDateSorted < minDateAllowed) || (trayDateSorted > maxDateAllowed)) && "white",
                                },
                            },
                            maxDate: maxDateAllowed,
                            minDate: CanDoDataMaintenance !== true ? minDateAllowed : null,
                            xsCol: 2,
                        },
                        {
                            name: "OwnerLaboratoryId",
                            label: "Owner",
                            type: "dropdown",
                            xsCol: 3,
                            required: true,
                            optionConfigs: {
                                name: "Laboratories",
                                textProp: "Laboratory",
                                valProp: "LaboratoryId",
                            },
                        }]
                },
                {
                    group: [

                        {
                            name: "IsTrainingSample",
                            label: "Training",
                            type: "switch",
                            xsCol: 1.5,
                        },
                        {
                            name: "Label",
                            label: "Tray Name",
                            xsCol: 2,
                            disabled: focusCheck,
                            sx: {
                                [`.MuiInputBase-root`]: {
                                    backgroundColor: !data?.Label && "red",
                                },
                            },
                        },
                        {
                            name: "Tray",
                            label: "Tray",
                            xsCol: 1.5,
                            type: "number",
                            disabled: focusCheck
                        },
                        {
                            name: "NumberOfSamples",
                            label: "No. of Samples",
                            xsCol: 1.5,
                            type: "number",
                            disabled: focusCheck
                        },
                        {
                            name: "InsertSample",
                            label: "Insert Sample",
                            xsCol: 1.5,
                            type: "number",
                            hidden: (!focusCheck || finishCheck),
                        },
                        {
                            alignSelf: "end",
                            component: function () {
                                return <Button onClick={actions?.alertInsertSampleTray} variant="outlined" disabled={(!focusCheck || finishCheck)}>Insert Sample</Button >;
                            },
                        },
                        {
                            alignSelf: "end",
                            component: function () {
                                return <Button onClick={actions?.alertAddExtraSample} variant="outlined" disabled={(!focusCheck || finishCheck)}>Add Extra Sample</Button >;
                            },
                        },]
                }
            ],
        },
    ];
}

const GetFormButtons = ({ handleSendTray, handleTraySummary, handleViewTray }, finishCheck, tableData) => [
    // {
    //     variant: "outlined",
    //     label: "Print All (F5)",
    //     onClick: handlePrintAll,
    //     // disabled: !finishCheck
    //     disabled: !(tableData.some(i => i.BottleNumber > 0) && finishCheck)

    // },
    {
        label: "Send to Registration (F10)",
        onClick: handleSendTray,
        disabled: !finishCheck
    },
    {
        variant: "outlined",
        label: "Tray Summary",
        onClick: handleTraySummary,
    },
    {
        variant: "outlined",
        label: "View Tray",
        onClick: handleViewTray,
    },
];

const paperlessDefaults = {
    Tray: 1, NumberOfSamples: 50, InsertSample: 1, OwnerLaboratoryId: 1,
    IsTrainingSample: false,
    TrayDate: moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
    useDeliveredCheck: false,
    useDeliveredDate: moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
}

function PaperlessReducer(state, action) {
    if (action.type === FormActions.INPUT_CHANGED) {
        return { ...state, [action.payload.name]: action.payload.value };
    }
    if (action.type === FormActions.LOAD_DATA) {
        return { ...action?.payload, _key: Date.now() };
    }
    if (action.type === FormActions.RESET_FORM) {
        return { ...paperlessDefaults, ...action?.payload, _key: Date.now() };
    }
    return state;
}
