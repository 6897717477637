import { useRef, } from "react";
import DataTable from "../../../components/DataTable";
import { Box, Button, Divider, Menu, MenuItem, Typography } from "@mui/material";
import { GridToolbarQuickFilter, } from '@mui/x-data-grid';

export const PopUpMenu = ({ anchorEl, openPop, setOpenPop, handleClick, nameVal, rowData, searchDataCheckboxIds, setSearchDataCheckboxIds, searchDataCheckbox, setSearchDataCheckbox }) => {

    const dtref = useRef();

    const SearchTableColumns = [
        {
            headerName: nameVal,
            field: nameVal,
            minWidth: 130,
            valueFormatter: ({ value }) => ((value === true) ? "Yes" : (value === false) ? "No" : value),
        },
    ];

    const unique = [...new Set(rowData.map(item => item[nameVal]))];
    const uniqueList = [...new Set(unique.map(item => { return ({ [nameVal]: item }) }))]

    function rowsSampleSelectedHandler(row) {
        if (row?.length > 0) {
            setSearchDataCheckbox(row);
            setSearchDataCheckboxIds(row?.map((s) => s.keyProp));
        }
    }

    function QuickSearchToolbar() {
        return (
            <Box sx={{ p: 0.5, pb: 0, }}  >
                <GridToolbarQuickFilter
                    quickFilterParser={(searchInput) =>
                        searchInput
                            .split(',')
                            .map((value) => value.trim())
                            .filter((value) => value !== '')
                    }
                    autoFocus
                    ref={dtref}
                />
            </Box>
        );
    }

    const searchOptions = {
        columns: SearchTableColumns,
        dataSource: (uniqueList || []).map((x, i) => ({
            ...x,
            keyProp: i,
        })),
        rowIdProperty: "keyProp",
        checkBoxSelect: true,
        selectedRowIds: searchDataCheckboxIds,
        isDisableColumnFilter: true,
        rowsSelected: rowsSampleSelectedHandler,
        sx: { height: 180, minWidth: 190, },
        checkboxColor: "#000000",
        selectedRowColor: "transparent",
        selectedTextColor: "#000000",
        components: { Toolbar: QuickSearchToolbar },
    };

    return (
        <Menu anchorEl={anchorEl} open={openPop} onClose={() => setOpenPop(false)}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}>
            <MenuItem
                sx={{ minWidth: 180, }}
                onClick={() => handleClick("ascending", nameVal)}
            >
                <Typography >"Sort by Ascending"</Typography>
            </MenuItem>

            <MenuItem
                sx={{ minWidth: 180, }}
                onClick={() => handleClick("descending", nameVal)}
            >
                <Typography >"Sort by Descending"</Typography>
            </MenuItem>
            <Divider />
            <MenuItem
                sx={{ minWidth: 180, }}
            >
                <DataTable {...searchOptions} />
            </MenuItem>
            <MenuItem sx={{ float: "right" }}>
                <Button variant="outlined" sx={{ mr: 1 }} onClick={() => { handleClick("filtering", nameVal, searchDataCheckbox[nameVal]) }}>OK</Button>
                <Button variant="outlined" onClick={() => setOpenPop(false)}>Cancel</Button>
            </MenuItem>
        </Menu>
    );
}
