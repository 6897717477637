import { alpha, styled } from "@mui/material/styles";
import TreeItemMui, { treeItemClasses } from "@mui/lab/TreeItem";
import { MinusSquare, PlusSquare } from "../../icons/ButtonIcons";

const StyledTreeItem = styled((props) => <TreeItemMui
  sx={{
    [`.MuiTreeItem-content.Mui-selected `]: { backgroundColor: "transparent" },
  }}
  {...props} />)
  (
    ({ theme }) => ({
      [`& .${treeItemClasses.iconContainer}`]: {
        "& .close": {
          opacity: 0.3,
        },
      },
      [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 8,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
      },
    })
  );

export default function TreeItem(props) {
  const { customProps, ...treeProps } = props;
  const options = {};
  if (customProps && customProps.hasChildren) {
    options.icon = customProps.expanded ? <MinusSquare /> : <PlusSquare />;
  }

  return <>
    <StyledTreeItem id={props?.selectedNode || ""}
      //  productColor={productColor}
      {...treeProps} {...options}></StyledTreeItem>
  </>
}
