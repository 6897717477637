import { useCallback, useState } from "react";
import renderCellExpand from "../../../components/DatatableCellExpand";
import NewDataTable from "../../../components/NewDataTable";

export default function TestItemsTable({
  data,
  rowSelected,
  setDirtyTestItems,
  height,
}) {
  const [rowSelection, setRowSelection] = useState({});

  const rowEditHandler = useCallback(function (newRow, oldRow) {
    return new Promise(async function (resolve, reject) {
      setDirtyTestItems((testItems) => {
        return { ...testItems, [newRow.TestItemId]: newRow };
      });
      resolve({ ...newRow });
    });
  }, []);

  const options = {
    columns: Columns,
    dataSource: data || [],
    getRowId: (originalRow) => originalRow.TestItemId,

    sx: { height: height || "84dvh", },
    enableColumnActions: false,

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },

    muiTableBodyRowProps: ({ row }) => ({
      //implement row selection click events manually
      onClick: () => {
        rowSelected(row.original);
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }))
      },
      selected: rowSelection[row.id],
      sx: {
        cursor: 'pointer',
      },
    }),
    state: { rowSelection },
  };
  return <NewDataTable {...options} />;
}

const Columns = [
  {
    header: "Test Item",
    accessorKey: "TestItem",
    size: 150,
    enableEditing: false,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Code",
    accessorKey: "TestItemCode",
    size: 55,
    enableEditing: false,
  },
  {
    header: "Type",
    accessorKey: "TestItemType",
    size: 190,
    sortable: false,
    enableEditing: false,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
]; 