import NewDataTable from '../../../components/NewDataTable'
import renderCellExpand from "../../../components/DatatableCellExpand";
import { Checkbox } from "@mui/material";
import { toLocalDate2 } from '../../../utils';

export default function ImportHistoryTable({ originalData, setOriginalData }) {

  const handleChange = (i, val, name) => {
    const newState = originalData.map(obj => {
      if (obj.labno === i?.labno) {
        return { ...obj, [name]: val === true ? false : true };
      }
      return obj;
    });
    setOriginalData(newState)
  };

  const options = {
    columns: generateColumns(originalData, handleChange) || [],
    dataSource: originalData || [],
    getRowId: (originalRow) => (originalRow?.labno),
    enableColumnActions: false,
    enableSorting: false,
    sx: { height: "82dvh", },


    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },

    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: {
          height: "8px",
          border: "0.5px solid #d0d0d0",
        }
      }
    },
  };
  return <NewDataTable {...options} />;
};

const GetColumns = (handleChange) => [
  {
    header: "Ignore",
    accessorKey: "ignoreDetail",
    size: 40,
    Cell: ({ row }) => (
      <Checkbox checked={row?.original?.ignoreDetail}
        onChange={(e) => handleChange(row?.original, row?.original?.ignoreDetail, "ignoreDetail")}
        sx={{ [`.MuiSvgIcon-root`]: { fontSize: "14px", padding: 0 } }} />
    ),
  },
  {
    header: "SampleId",
    accessorKey: "SampleId",
    size: 60,
  },
  {
    header: "Customer",
    accessorKey: "Customer",
    size: 150,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Site",
    accessorKey: "Site",
    size: 120,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Unit",
    accessorKey: "Unit",
    size: 60,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Make",
    accessorKey: "Make",
    size: 100,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Model",
    accessorKey: "Model",
    size: 100,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Serial",
    accessorKey: "Serial",
    size: 100,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Component",
    accessorKey: "Component",
    size: 130,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "FluidType",
    accessorKey: "FluidType",
    size: 60,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "FluidMake",
    accessorKey: "FluidMake",
    size: 150,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Fluid",
    accessorKey: "Fluid",
    size: 90,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "FluidGrade",
    accessorKey: "FluidGrade",
    size: 100,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Sampled",
    accessorKey: "Sampled",
    size: 80,
    Cell: ({ renderedCellValue, row }) => (toLocalDate2(renderedCellValue)),
  },
  {
    header: "Released",
    accessorKey: "Released",
    size: 80,
    Cell: ({ renderedCellValue, row }) => (toLocalDate2(renderedCellValue)),
  },

  {
    header: "UnitHours",
    accessorKey: "UnitHours",
    size: 70,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "ComponentHours",
    accessorKey: "ComponentHours",
    size: 100,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "FluidHours",
    accessorKey: "FluidHours",
    size: 70,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "FluidChanged",
    accessorKey: "FluidChanged",
    size: 80,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Evaluation",
    accessorKey: "Evaluation",
    size: 500,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
];

const columnsToSkip1 = ["custid", "compartid", "compartsn", "modeldesc", "notesos"];

const generateColumns = (originalData, handleChange) => {
  let rowData = {};
  if (originalData?.length > 0) {
    rowData = originalData[0]
  }
  const constantColumns = GetColumns(handleChange);

  const columnsToSkip = [...columnsToSkip1, ...constantColumns.map(i => i?.accessorKey)]
  const columns1 = Object.keys(rowData)
    .filter((key) => !columnsToSkip.includes(key))
    .map((key) => {
      let header = key; // Default header is the key

      return {
        header,
        accessorKey: key,
        size: 30,
        Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
      };
    });

  const columns = [...constantColumns, ...columns1,
    //   {
    //   header: "Evaluation",
    //   accessorKey: "notesos",
    //   size: 1000,
    //   Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    // }
  ];

  return columns;
};

