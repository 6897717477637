const APIS = { CREATE_TRAY: "/Trays/Step0CreateTray", };

const DASHBOARDController = "dashboard";
const DETAILSController = "Details";
const TraysController = "Trays";
const CRITICAL_LOGSController = "CriticalLog";
const CONTACTSController = "Contacts";
const MASTER_DATAController = "MasterData";
const MANAGEMENTController = "Management";
const TEST_SETSController = "testSets";
const FLEETController = "fleet";
const REPORTController = "report";
const GraphController = "graph";


export const REPORT_APIS = Object.freeze({
  ALL_SAMPLE_CHAIN_SIMPLE: `/${REPORTController}/all-sample-chain-simple`,
  EMAIL_SELECTED_SAMPLES: `/${REPORTController}/email-selected-samples`,
  MANAGEMENT_REPORT_XLSX: `/${REPORTController}/management-report-xlsx`,
  REFRENCE_XSD: `/${REPORTController}/reference-xsd`,
  HISTORY_XML: `/${REPORTController}/history-xml`,
  HISTORY_XLSX: `/${REPORTController}/history-xlsx`,
  REPORT_MAIL: `/${REPORTController}/report-mail`,
  PRINT_LABELS: `/${REPORTController}/print-labels`,
  DELETE_ALL_REPORTS: `/${REPORTController}/delete-all-reports`,
  VIEW_REPORT_MAIL: `/${REPORTController}/view-report-mail`,
  VIEW_SPECIAL_MAIL_REPORT: `/${REPORTController}/view-special-mail-report`,
});

export const TRAY_APIS = Object.freeze({
  AUTO_EVAL: `/${TraysController}/auto-eval`,
  CREATE: `/${TraysController}/Step0CreateTray`,
  GET: `/${TraysController}/GetTray`,
  SUMMARY: `/${TraysController}/GetTraySummary`,
  ADD_EXTRA_SAMPLE: `/${TraysController}/AddExtraSample`,
  DELETE_END_SAMPLE: `/${TraysController}/DeleteEndSample`,
  DELETE_TRAY: `/${TraysController}/DeleteUnregisteredTray`,
  VIEW_TRAY: `/${TraysController}/view-tray`,

  DELETE_REG: `/${TraysController}/delete-reg`,
  COMPONENT: `/${TraysController}/component`,
  REGISTRATION: `/${TraysController}/registration`,
  REGISTRATIONS: `/${TraysController}/registrations`,
  REGISTER: `/${TraysController}/register`,
  REGISTRATIONS_BOTTLENUMBER: `/${TraysController}/registrations-bottlenumber`,
  EDIT_REGISTRATION: `/${TraysController}/edit-registration`,

  HISTORY: `/${TraysController}/history`,
  EDIT_RESULT_TEXT: `/${TraysController}/EditSampleResultText`,
  SAMPLE_NOT_IMAGE_COUNT: `/${TraysController}/GetSampleNoteAndImageCount`,
  GET_LABORATORY: `/${TraysController}/laboratory`,
  RELEASE_SAMPLE: `/${TraysController}/release-sample`,
  RELEASE_SAMPLES: `/${TraysController}/release-samples`,
  RELEASE_TRAY: `/${TraysController}/release-tray`,
  UPDATE_SAMPLE_NOTE: `/${TraysController}/UpdateSampleNote`,

  GET_TRAY_SAMPLES_HOLD: `/${TraysController}/GetTraySamplesOnHold`,
  SAMPLES_HOLD: `/${TraysController}/SampleHold`,
  SAMPLES_TRAY_HOLD: `/${TraysController}/SampleTrayHold`,

  TRAY_SAMPLE_COUNT: `/${TraysController}/tray-sample-count`,
  INSERT_SAMPLE_TRAY: `/${TraysController}/insert-sample-tray`,

  QUALITY_CHECK_RERUN_SAMPLE: `/${TraysController}/qualitycheck-rerun-sample`,
  QUALITY_CHECK_RELEASE_SAMPLE: `/${TraysController}/quality-check-releasesample`,
  QUALITY_CHECK_RERUN_TRAY: `/${TraysController}/qualitycheck-rerun-tray`,
  QUALITY_CHECK_RELEASE_TRAY: `/${TraysController}/quality-check-releasetray`,
  QUALITY_CHECK: `/${TraysController}/qualitycheck`,

  EVALUATION_RERUN_SAMPLE: `/${TraysController}/evaluation-rerun-sample`,
  EVALUATION_RERUN_TRAY: `/${TraysController}/evaluation-rerun-tray`,

  NOTES_AND_IMG_COUNT: `/${TraysController}/notes-and-img-count`,
  SEARCH_EVALUATIONS: `/${TraysController}/search-evaluations`,
  EVALUATE_SAMPLE: `/${TraysController}/evaluate-sample`,
  UPDATE_COMMENTS: `/${TraysController}/update-comments`,
  SEARCH_APPROVALS: `/${TraysController}/search-approvals`,

  REPORT_SAMPLE: `/${REPORTController}/sample-report`,
  MACHINE_REPORT: `/${REPORTController}/machine-report`,
  MACHINE_HEALTH_REPORT: `/${REPORTController}/machine-health-report`,
  REGISTRATION_CARD_REPORT: `/${REPORTController}/registration-card`,
  COMPONENT_SAMPLE_REPORT: `/${REPORTController}/components-no-recent-samples`,
  UNIT_SAMPLE_REPORT: `/${REPORTController}/units-no-recent-samples`,
  EXPORT_COMPONENT_SAMPLE_REPORT: `/${REPORTController}/export-components-no-recent-samples`,
  EXPORT_UNIT_SAMPLE_REPORT: `/${REPORTController}/export-units-no-recent-samples`,

  APPROVE_SAMPLES: `/${TraysController}/approve-samples`,
  FILTER_REGISTRATIONS: `/${TraysController}/filter-registrations`,
  SAMPLE_ON_HOLD: `/${TraysController}/hold-samples`,
  MACHINE_INSITE: `/${TraysController}/machine-insite`,
  COMPONENT_NAMES: `/${TraysController}/component-names`,
  ADD_COMPONENT_NAME: `/${TraysController}/add-component-name`,
  DELETE_COMPONENT_NAMES: `/${TraysController}/delete-component-name`,
  MODEL_INSITE: `/${TraysController}/model-insite`,
  NEW_VERSION: `/${TraysController}/new-version`,
  UPDATE_TRAY_LABEL: `/${TraysController}/update-tray-label`,
  DELETE_SELECTED_SAMPLE: `/${TraysController}/delete-selected-sample`,
  EXPORTS_SAMPLES_ON_HOLD: `/${TraysController}/export-samples-on-hold`,
  NOTES_BY_UNIT: `/${TraysController}/notes-by-unit`,
  SAVE_NOTES_BY_UNIT: `/${TraysController}/save-notes-by-unit`,
  DELETE_NOTES: `/${TraysController}/delete-notes`,
  RETURN_SAMPLE_ON_HOLD: `/${TraysController}/return-sample-on-hold`,
  RETURN_TRAY_ON_HOLD: `/${TraysController}/return-tray-on-hold`,

  GET_BATCH: `/${TraysController}/get-batch`,
  GET_BATCH_SAMPLES: `/${TraysController}/get-batch-samples`,
  EXPORT_BATCH_SAMPLES: `/${TraysController}/export-batch-samples`,
  EXPORT_BATCH_SAMPLES_REQJSON: `/${TraysController}/export-batch-samples-reqjson`,
  EXPORT_BATCH_SAMPLES_RESPJSON: `/${TraysController}/export-batch-samples-respjson`,
  SAMPLE_CHART: `/${TraysController}/SampleChart`,

  REAPPROVE_SAMPLE: `/${TraysController}/reapprove-sample`,
  REAPPROVE_TRAY: `/${TraysController}/reapprove-tray`,
  SWAP_TRAYS: `/${TraysController}/swap-trays`,
  MOVE_TRAYS: `/${TraysController}/move-trays`,

  RESEND_BATCH: `/${TraysController}/resend-batch`,
  PUSH_BATCH: `/${TraysController}/push-batch`,
  PUSH_BATCH_SAMPLE: `/${TraysController}/push-batch-sample`,

  UR_UNIT_MAKE: `/${TraysController}/update-reg-unit-make`,
  UR_UNIT_SERIAL: `/${TraysController}/update-reg-unit-serial`,
  UR_COMPONENT_LOCATION: `/${TraysController}/update-reg-component-location`,
  UR_COMPONENT_CAPACITY: `/${TraysController}/update-reg-component-capacity`,
  UR_COMPONENT_MAKE: `/${TraysController}/update-reg-component-make`,
  UR_COMPONENT_SERIAL: `/${TraysController}/update-reg-component-serial`,

  Paperless1: `/${TraysController}/paperless-1`,
  Paperless2: `/${TraysController}/paperless-2`,

});

export const DETAILS_APIS = Object.freeze({
  DETAILS: `/${DETAILSController}/Details`,

  RERUN: `/${DETAILSController}/GetRerunSamples`,
  MISSED: `/${DETAILSController}/missed`,
  EXPORT_MISSED: `/${DETAILSController}/export-missed-hold-samples`,
  UNIT_HOUR_HISTORY: `/${DETAILSController}/unit-hour-history`,
  EXPORT_UNIT_HOUR_HISTORY: `/${DETAILSController}/export-unit-hour-history`,

  ADD_SAMPLE_IMAGE: `/${DETAILSController}/AddSampleImage`,
  DELETE_SAMPLE_IMAGE: `/${DETAILSController}/DeleteSampleImage`,
  GET_SAMPLE_IMAGES: `/${DETAILSController}/GetSampleImages`,

  GET_FLUID_WATCH_LINKS: `/${DETAILSController}/get-fluid-watch-links`,
  GET_FLUID: `/${DETAILSController}/get-fluid`,
  DELETE_FLUID_WATCH: `/${DETAILSController}/delete-fluid-watch`,
  WATCH_BY_TYPE: `/${DETAILSController}/watch-by-type`,
  GET_WATCH_DATA: `/${DETAILSController}/get-watch-data`,
  SAVE_FLUID_WATCH: `/${DETAILSController}/save-fluid-watch`,
  SAVE_FLUID_WATCH_LINKS: `/${DETAILSController}/save-fluid-watch-links`,
  ADD_FLUID: `/${DETAILSController}/add-fluid`,
  GET_EVENTS_LOGS: `/${DETAILSController}/GetEventLogs`,
  ADD_EVENT_LOG: `/${DETAILSController}/add-event-log`,
  SAVE_EVENT_LOG: `/${DETAILSController}/save-event-log`,
  DELETE_EVENT_LOG: `/${DETAILSController}/delete-event-log`,
  SEARCH: `/${DETAILSController}/search`,
  UPDATE_FLUID: `/${DETAILSController}/update-fluid`,
  UPDATE_COMPONENT: `/${DETAILSController}/update-component`,
  ADD_COMPONENT: `/${DETAILSController}/add-component`,
  ADD_CUSTOMER: `/${DETAILSController}/add-customer`,
  UPDATE_CUSTOMER: `/${DETAILSController}/update-customer`,
  ADD_SITE: `/${DETAILSController}/add-site`,
  UPDATE_SITE: `/${DETAILSController}/update-site`,
  UPDATE_UNIT: `/${DETAILSController}/update-unit`,
  ADD_UNIT: `/${DETAILSController}/add-unit`,
  SAVE_REGISTER_SAMPLE: `/${DETAILSController}/SaveRegisterSample`,
  EDIT_SAMPLE: `/${DETAILSController}/edit-sample`,
  EDIT_SAMPLE_HOUR: `/${DETAILSController}/edit-sample-hour`,
  UPDATE_MACHINE_NOTIFICATIONS: `/${DETAILSController}/update-machine-notifications`,
  TOP_NOTIFICATIONS: `/${DETAILSController}/top-notifications`,

  SEARCH_SITES: `/${DETAILSController}/SearchSites`,
  SEARCH_UNITS: `/${DETAILSController}/SearchUnits`,
  SEARCH_COMPONENTS: `/${DETAILSController}/search-components`,

  DELETE_COMPONENT: `/${DETAILSController}/delete-component-children`,
  MOVE_COMPONENT: `/${DETAILSController}/move-components`,
  SEARCH_CUSTOMERS: `/${DETAILSController}/SearchCustomers`,

  APPLY_TEMPLATE_COMPONENTS_UNITS: `/${DETAILSController}/apply-template-components-unit`,

  PRODUCTS_WITH_CUSTOMERCOUNT: `/${DETAILSController}/products-with-customercount`,
  SEARCH_TREE: `/${DETAILSController}/search-tree`,
  SEARCH_GENERAL: `/${DETAILSController}/search-general`,
  SAMPLES_LIST: `/${DETAILSController}/samples-list`,
  SAMPLES_LIST_ALL: `/${DETAILSController}/samples-list-all`,
  TRAY_OVERVIEW: `/${DETAILSController}/tray-overview`,
  EDIT_TRAY: `/${DETAILSController}/edit-tray`,
  EDIT_TRAY_LABEL: `/${DETAILSController}/edit-tray-label`,

  GET_SAMPLE_TREE_TOOLSTRIP: `/${DETAILSController}/get-sample-tree-toolstrip`,
  GET_UNIT_TREE_TOOLSTRIP: `/${DETAILSController}/get-unit-tree-toolstrip`,
  UNIT_HISTORY: `/${DETAILSController}/unit-history`,
  COMPONENT_HISTORY: `/${DETAILSController}/componet-history`,
  EXPORT_UNIT_HISTORY: `/${DETAILSController}/export-recent-unit-history`,
  EXPORT_COMPONENT_HISTORY: `/${DETAILSController}/export-recent-componet-history`,
  EVENT_LOGS_ALL: `/${DETAILSController}/event-logs-all`,
  EVENT_LOGS_FILTERED: `/${DETAILSController}/event-logs-filtered`,

  MANAGE_CUSTOMER_CHAIN: `/${DETAILSController}/manage-customer-chain`,
  MANAGE_SITE_CHAIN: `/${DETAILSController}/manage-site-chain`,
  MANAGE_UNIT_CHAIN: `/${DETAILSController}/manage-unit-chain`,
  MANAGE_COMPONENT_CHAIN: `/${DETAILSController}/manage-component-chain`,
  MANAGE_SAMPLE_CHAIN: `/${DETAILSController}/manage-sample-chain`,

  COMPONENT: `/${DETAILSController}/component`,
  UNIT: `/${DETAILSController}/unit`,
});

export const DASHBOARD_APIS = Object.freeze({
  TRAY: `/${DASHBOARDController}/tray-dashboard`,
  AUTO_EVALUATION: `/${DASHBOARDController}/autoEvaluation`,
  CLEANUP: `/${DASHBOARDController}/cleanup-overview-list`,
  CLEANUP_EXPORT: `/${DASHBOARDController}/export-cleanup-list`,
  FEEDBACK: `/${DASHBOARDController}/ikowa-feedback`,
  EXPORT_MISMATCH: `/${DASHBOARDController}/export-mismatch`,
  EXPORT_HISTORY: `/${DASHBOARDController}/export-history`,
});
export const CRITICAL_LOGS_APIS = Object.freeze({
  CRITICAL_LOG_OUTSTANDING: `/${CRITICAL_LOGSController}/critical-log-outstanding`,
  CRITICAL_LOGS: `/${CRITICAL_LOGSController}/critical-logs`,
  CRITICAL_LOGS_SAMPLE: `/${CRITICAL_LOGSController}/critical-logs-sample`,
  DELETE_CRITICAL_LOG: `/${CRITICAL_LOGSController}/delete-critical-log`,
  SAVE_CRITICAL_LOG: `/${CRITICAL_LOGSController}/save-critical-log`,
  CONTACTS_FOR_SCOPE: `/${CRITICAL_LOGSController}/contacts-for-scope`,
  EXPORT_CONTACTS_FOR_SCOPE: `/${CRITICAL_LOGSController}/export-contacts-for-scope`,
  EXPORT_CRITICAL_LOG_OUTSTANDING: `/${CRITICAL_LOGSController}/export-critical-log-outstanding`,
  EXPORT_CRITICAL_LOGS: `/${CRITICAL_LOGSController}/export-critical-logs`,
  // : `/${CRITICAL_LOGSController}/`,
});

export const CONTACTS_APIS = Object.freeze({

  SEARCH_CONTACTS: `/${CONTACTSController}/search-contacts`,
  SEARCH_CUSTOMERS_CONTACTS: `/${CONTACTSController}/search-customers-contact`,
  EDIT_CONTACTS: `/${CONTACTSController}/edit-contacts`,
  SAVE_CONTACTS: `/${CONTACTSController}/save-contacts`,
  CONTACT_SCOPES_ASSIGNED: `/${CONTACTSController}/contact-scopes-assigned`,
  CONTACT_LINKAGES_ASSIGNED: `/${CONTACTSController}/contact-linkages-assigned`,
  CONTACT_SUBSCRIPTIONS: `/${CONTACTSController}/contact-subscriptions`,
  CONTACT_EXPORTS: `/${CONTACTSController}/contact-exports`,
  CONTACT_PRIMARIES_ASSIGNED: `/${CONTACTSController}/contact-primaries-assigned`,
  CUSTOMERS_FOR_CONTACT: `/${CONTACTSController}/customers-for-contact`,
  SITES_FOR_CONTACT: `/${CONTACTSController}/sites-for-contact`,
  UNITS_FOR_CONTACT: `/${CONTACTSController}/units-for-contact`,
  COMPONENTS_FOR_CONTACT: `/${CONTACTSController}/components-for-contact`,
  PRODUCTS_FOR_CONTACT: `/${CONTACTSController}/products-for-contact`,
  SITE_SETS_FOR_CONTACT: `/${CONTACTSController}/sitesets-for-contact`,
  ADD_SCOPE_FOR_CONTACT: `/${CONTACTSController}/add-scope-for-contact`,
  DELETE_SCOPE_FOR_CONTACT: `/${CONTACTSController}/delete-scope-for-contact`,
  DELETE_CONTACTS: `/${CONTACTSController}/delete-contacts`,
  DELETE_CONTACT_PRIMARY: `/${CONTACTSController}/delete-contact-primary`,
  ADD_CONTACT_PRIMARY: `/${CONTACTSController}/add-contact-primary`,
  SAVE_CONTACT_LINKAGE: `/${CONTACTSController}/save-contact-linkage`,
  CONTACT_LINKAGE: `/${CONTACTSController}/contact-linkage`,
  CONTACT_FOR_EXPORT: `/${CONTACTSController}/contact-for-export`,
  SEARCH_KOMATSU_EMPLOYEE: `/${CONTACTSController}/search-komatsu-employee`,
  ADD_CONTACT_SUBSCRIPTION: `/${CONTACTSController}/add-contact-subscription`,
  SUBSCRIPTION_REPORTS: `/${CONTACTSController}/subscription-reports`,
  DELETE_CONTACT_SUBSCRIPTION: `/${CONTACTSController}/delete-contact-subscriptions`,
  DELETE_CONTACT_LINKAGE: `/${CONTACTSController}/delete-contact-linkage`,
  CONTACT_ALTERNATIVE: `/${CONTACTSController}/contact-alternative`,
  ADD_CONTACT_ALTERNATIVE: `/${CONTACTSController}/add-contact-alternative`,
  PARTIAL_CONTACT: `/${CONTACTSController}/partial-contact`,

  REFRESH_CONTACT_DETAILS: `/${CONTACTSController}/refresh-contact-details`,
  RESET_CONTACT_PASSWORD: `/${CONTACTSController}/reset-contact-password`,
  UNLOCK_ACCOUNT: `/${CONTACTSController}/unlock-acccount`,
  RESEND_ACTIVATION: `/${CONTACTSController}/resend-activation`,
  CONTACT_COPY: `/${CONTACTSController}/contact-copy`,

  // : `/${CRITICAL_LOGSController}/`,
});
export const MASTER_DATA_APIS = Object.freeze({
  AUDIT_LOG_USERS: `/${MASTER_DATAController}/audit-log-users`,
  AUDIT_LOGS: `/${MASTER_DATAController}/audit-logs`,
  EXPORT_AUDIT_LOGS: `/${MASTER_DATAController}/export-audit-logs`,
  GET_BY_TYPES: `/${MASTER_DATAController}/GetByTypes`,
  AUTO_EVAL_COMMENTS: `/${MASTER_DATAController}/auto-eval-comments`,
  DELETE_AUTO_EVAL_COMMENTS: `/${MASTER_DATAController}/delete-auto-eval-comments`,
  EDIT_AUTO_EVAL_COMMENTS: `/${MASTER_DATAController}/edit-auto-eval-comments`,
  EDIT_LABORATORY: `/${MASTER_DATAController}/edit-laboratory`,
  EDIT_PRODUCT: `/${MASTER_DATAController}/edit-product`,
});

export const GRAPH_APIS = Object.freeze({
  USERS: `/${GraphController}/users`,
});

export const MANAGEMENT_APIS = Object.freeze({
  ADD_USER: `/${MANAGEMENTController}/add-user`,
  UPDATE_USER: `/${MANAGEMENTController}/update-profile`,
  DELETE_USER_PROFILE: `/${MANAGEMENTController}/delete-user-profile`,
  MESSAGES_ALL: `/${MANAGEMENTController}/messages-all`,
  DELETE_MESSAGE: `/${MANAGEMENTController}/delete-message`,
  EDIT_MESSAGE: `/${MANAGEMENTController}/edit-message`,
  PRESET_COMMENTS: `/${MANAGEMENTController}/preset-comments`,
  EDIT_PRESET_COMMENTS: `/${MANAGEMENTController}/edit-preset-comments`,
  DELETE_PRESET_COMMENTS: `/${MANAGEMENTController}/delete-preset-comments`,
  OWNED_CUSTOMER: `/${MANAGEMENTController}/owned-customer`,
  DELETE_OWNED_CUSTOMER: `/${MANAGEMENTController}/delete-owned-customer`,
  KOMATSU_DOMAINS: `/${MANAGEMENTController}/komatsu-domains`,
  ADD_KOMATSU_DOMAINS: `/${MANAGEMENTController}/add-komatsu-domain`,
  DELETE_KOMATSU_DOMAINS: `/${MANAGEMENTController}/delete-komatsu-domain`,
  MACHINE_NOTIFICATIONS: `/${MANAGEMENTController}/machine-notifications`,
  DELETE_MACHINE_NOTIFICATIONS: `/${MANAGEMENTController}/delete-machine-notifications`,
  DELETE_ALL_MACHINE_NOTIFICATIONS: `/${MANAGEMENTController}/delete-all-machine-notifications`,
  CHANGE_LABORATORY: `/${MANAGEMENTController}/change-laboratory`,

  BILLING_RATES: `/${MANAGEMENTController}/billing-rates`,
  EDIT_BILLING_RATES: `/${MANAGEMENTController}/edit-billing-rates`,
  GLOBAL_SETTINGS: `/${MANAGEMENTController}/global-settings`,
  EDIT_GLOBAL_SETTINGS: `/${MANAGEMENTController}/edit-global-settings`,
  WEBSITE_NEWS: `/${MANAGEMENTController}/website-news`,
  EDIT_WEBSITE_NEWS: `/${MANAGEMENTController}/edit-website-news`,
  DELETE_WEBSITE_NEWS: `/${MANAGEMENTController}/delete-website-news`,
  USER_FLUID_TYPE_NOTIFICATIONS: `/${MANAGEMENTController}/user-fluid-type-notifications`,
  MANAGE_FLUID_TYPE_NOTIFICATIONS: `/${MANAGEMENTController}/manage-fluid-type-notifications`,
  USER_PRODUCT_TYPE_NOTIFICATIONS: `/${MANAGEMENTController}/user-product-notifications`,
  MANAGE_PRODUCT_NOTIFICATIONS: `/${MANAGEMENTController}/manage-product-notifications`,

  MATCH_MACHINE_INSITE: `/${MANAGEMENTController}/match-machines-insite`,
  CONTACTS_PORTAL: `/${MANAGEMENTController}/contacts-portal`,
  APPLY_CMS_ROLE: `/${MANAGEMENTController}/apply-cms-role`,

  TERMINATED_STAFF: `/${MANAGEMENTController}/terminated-staff`,
  UPDATE_KOMATSU_CONTACTS: `/${MANAGEMENTController}/update-komatsu-contacts`,

  ADHOC_REPORT_TYPES: `/${MANAGEMENTController}/adhoc-report-types`,
  SPECIAL_ADHOC_REPORT: `/${MANAGEMENTController}/special-adhoc-report`,
  UNIT_ADHOC_REPORT: `/${MANAGEMENTController}/unit-adhoc-report`,
  COMPONENT_ADHOC_REPORT: `/${MANAGEMENTController}/component-adhoc-report`,

});

export const TEST_SETS_APIS = Object.freeze({
  TEST_SETS: `/${TEST_SETSController}/testSets`,
  DETAILS: `/${TEST_SETSController}/details`,
  SAVE: `/${TEST_SETSController}/save`,
  REPLACE: `/${TEST_SETSController}/replace`,
  TEST_ITEMS: `/${TEST_SETSController}/test-items`,
  TEST_ITEMS_DETAIL: `/${TEST_SETSController}/test-item-detail`,
  SAVE_TEST_ITEMS: `/${TEST_SETSController}/save-test-item`,
  REPLACE_TEST_ITEMS: `/${TEST_SETSController}/replace-test-item`,
  TEST_ITEMS_TYPES: `/${TEST_SETSController}/test-item-types`,
});

export const FLEET_APIS = Object.freeze({
  SAVE_UNIT_MAKE: `/${FLEETController}/save-unit-make`,
  SITE_SETS: `/${FLEETController}/site-sets`,
  SITE_SETS_SITES: `/${FLEETController}/site-set-sites`,
  ADD_SITE_SET_SITE: `/${FLEETController}/add-site-setsite`,
  DELETE_SITE_SET_SITE: `/${FLEETController}/delete-site-setsite`,
  DELETE_SITE_SET: `/${FLEETController}/delete-site-set`,
  SAVE_SITE_SET: `/${FLEETController}/save-site-set`,
  COMPONENT_MODELS: `/${FLEETController}/component-models`,

  FLUIDS_ALL: `/${FLEETController}/fluids-all`,
  UNIT_MODELS_ALL: `/${FLEETController}/unit-models-all`,
  SITES_ALL_NEW: `/${FLEETController}/sites-all-new`,
  SITES_ALL: `/${FLEETController}/sites-all`,

  MANAGE_SAMPLE: `/${FLEETController}/manage-sample`,
  MANAGE_COMPONENT: `/${FLEETController}/manage-component`,
  MANAGE_UNIT: `/${FLEETController}/manage-unit`,
  MANAGE_SITE: `/${FLEETController}/manage-site`,
  MANAGE_CUSTOMER: `/${FLEETController}/manage-customer`,
  SEARCH_COMPONENT_MODELS: `/${FLEETController}/search-component-models`,
  SEARCH_UNIT_MODELS: `/${FLEETController}/search-unit-models`,
  MANAGE_FLUID: `/${FLEETController}/manage-fluid`,
  MERGE_FLUID: `/${FLEETController}/merge-fluids`,
  MERGE_UNIT_MAKES: `/${FLEETController}/merge-unit-makes`,
  MERGE_SITES: `/${FLEETController}/merge-sites`,
  MOVE_SITE_SPECIAL: `/${FLEETController}/move-site-special`,

  MACHINE_TEMPLATES: `/${FLEETController}/machine-templates`,
  VIEW_MACHINE_TEMPLATES: `/${FLEETController}/view-machine-templates`,
  SAVE_MACHINE_TEMPLATE: `/${FLEETController}/save-machine-template`,
  SAVE_TEMPLATE_COMPONENT: `/${FLEETController}/save-template-component`,
  DELETE_MACHINE_TEMPLATE: `/${FLEETController}/delete-machine-template`,
  COPY_MACHINE_TEMPLATE: `/${FLEETController}/copy-machine-template`,
  ADD_DELETE_TEMPLATE_MODEL: `/${FLEETController}/add-delete-template-model`,
  DELETE_TEMPLATE_COMPONENT: `/${FLEETController}/delete-template-component`,
  UNIT_MODELS: `/${FLEETController}/unit-models`,
  UNIT_MODELS_SERIAL: `/${FLEETController}/unit-models-serial`,

  PARENT_CUSTOMERS: `/${FLEETController}/parent-customers`,
  ADD_PARENT: `/${FLEETController}/add-parent`,
  UPDATE_PARENT: `/${FLEETController}/update-parent`,
  DELETE_PARENT: `/${FLEETController}/delete-parent`,
  SAVE_COMPONENT_MODELS: `/${FLEETController}/save-component-models`,
  CONTACT_FOR_LINKAGE: `/${FLEETController}/contacts-for-linkage`,
  CONTACTS_FOR_PRIMARY: `/${FLEETController}/contacts-for-primary`,

  MANAGE_UNIT_MODEL: `/${FLEETController}/manage-unit-model`,

  IMPORT_HISTORY_XML: `/${FLEETController}/import-history-xml`,
});
export const WEBSITE_APIS = Object.freeze({
  GET_WEBSITE_LINKS: `/${MANAGEMENTController}/websites-link`,
});

export const PRINTER_APIS = Object.freeze({
  GET_DEFAULT_PRINTER: `http://localhost:4567/getDefaultPrinter`,
  GET_ALL_PRINTERS: `http://localhost:4567/getPrinters`,
  PRINT_STICKER: `http://localhost:4567/printSticker`,
});

export default APIS;
