import { Button, Card } from "@mui/material";
import Form, { SectionTitle } from "../../../components/Form";
import { Edit, NotificationsNone } from "@mui/icons-material";
import FormFooter from "../../../components/FormFooter";
import { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { DETAILS_APIS } from "../../../common/apis";
import productColors from "../../../common/ProductColors";
import { useRoot } from "../../../rootContext";
import { useWindowPopups } from "../../../components/useWindowPopups";

function CustomerDetail({ customerDetails, setSearchOpen }) {

  const { post } = useFetch();
  const { CanDoDataMaintenance } = useRoot();

  const [isNotify, setIsNotify] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    setData({ ...customerDetails, key: Date?.now() })
  }, [customerDetails])

  useEffect(() => {
    if (data?.NotificationUserId !== null) {
      return setIsNotify(true)
    }
    else {
      return setIsNotify(false)
    }
  }, [data?.NotificationUserId])

  const getMachineInfo = async () => {
    let dataList = await post(DETAILS_APIS.UPDATE_MACHINE_NOTIFICATIONS,
      { CustomerId: data?.CustomerId, HasNotification: isNotify })
    if (dataList?.Success === true) {
      return setIsNotify(!isNotify)
    }
  }

  const HandleNewWindow = (actionName) => {
    useWindowPopups(actionName, data)
  }

  const actions = {
    edit: function (e) {
      HandleNewWindow("edit-customer")
    },
    notifyMe: function () {
      getMachineInfo()
    },
  };

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Form
          readOnly
          sections={FormSections(data, setSearchOpen)}
          data={data}
          spacing={2}
          key={data?.key || data?.CustomerId}
        />
        <FormFooter buttons={GetFormButtons(isNotify, actions, CanDoDataMaintenance)} />
      </Card>
    </>
  );
}

export default CustomerDetail;

const GetFormButtons = (isNotify, { notifyMe, edit }, CanDoDataMaintenance) => [
  {
    variant: "outlined",
    label: isNotify === true ? "Unnotify" : "Notify Me",
    onClick: notifyMe,
    startIcon: <NotificationsNone />,
  },
  CanDoDataMaintenance && { variant: "contained", label: "Edit", onClick: edit, startIcon: <Edit /> },
];

const FormSections = (data, setSearchOpen) => {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 12,
          component: function () {
            return <SectionTitle title="Customer Details" />;
          },
        },
        { name: "Customer", label: "Customer", xsCol: 8, },
        { name: "Product", label: "Product", xsCol: 4, sx: { [`.MuiFilledInput-root`]: productColors(data?.Product) } },
        { name: "CmsOwnerName", label: "CMS Owner", xsCol: 4 },
        { name: "AccountNumber", label: "Account", xsCol: 4 },
        { name: "SapCustomer", label: "Acc Name", xsCol: 4 },
        { name: "ExportOption", label: "Exports", xsCol: 4 },
        { name: "Parent", label: "Parent", xsCol: 4 },
        {
          xsCol: 3,
          component: function () {
            return <Button disabled={!data?.Parent} sx={{ mt: 1.5 }} onClick={() => setSearchOpen(true)}>Search Parent</Button>;
          },
        },
        {
          xs: 12, group: [
            { name: "AlternativeNames", label: "Known As", xsCol: 4, type: "textarea", rows: 2 },
            { name: "CustomerNotes", label: "Comment", xsCol: 8, type: "textarea", rows: 2 },
          ]
        }
      ],
    },
  ]
}
