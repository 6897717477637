import { Button, Card, Grid, Typography } from "@mui/material";
import moment from "moment/moment";
import { useCallback, useEffect, useState } from "react";
import { LabSteps, ResultColWidths } from "../../../common/Constants";
import DropDown from "../../../components/DropDown";
import { BadgeButton } from "../../../components/FormFooter";
import SwitchInput from "../../../components/SwitchInput";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import { DateFormat, toLocalDate } from "../../../utils";
import ResultsTable from "./ResultsTable";
import SamplesTable from "./SamplesTable";
import SearchFilter from "./SearchFilter";
import AppDialog from "../../../components/AppDialog";
import FluidWatch from "../../master-data/fluids/FluidWatch";
import Hold from "../laboratory/Hold";
import { DETAILS_APIS, TRAY_APIS } from "../../../common/apis";
import GroupDialog from "../../../components/GroupDialog";
import RerunSamples from "../../reports/rerun-samples/RerunSamples";
import MissedSamples from "../../reports/missed-samples/MissedSamples";
import appResources from "../../../app-resources/en/Resources.json";
import {
  HoldIcon,
  ImagesIcon,
  NewVersionIcon,
  NotesIcon,
  SaveCommentsIcon,
  ViewIcon,
} from "./../../../icons/ButtonIcons";
import RerunToLaboratory from "../quality-check/RerunToLaboratory";
import SelectImage from "../../../components/SelectImage";
import { useScreenPopups } from "../../../components/useScreenPopups";
import AddNotes from "./AddNotes";
import ResizePanel from "react-resize-panel";
import { DataGridAction } from "../../../components/DataTable";
import DropdownMenu from "../../../components/DropdownMenu";
import QuickDetailsTree from "../../details-old/QuickDetailsTree";
import { useRoot } from "../../../rootContext";
import renderCellExpand from "../../../components/DatatableCellExpand";
import { WindowTypes, useWindowPopups } from "../../../components/useWindowPopups";

let sampleNumberSelect = "";
let SampleRow = "";

export default function Review({ reviewActions }) {
  const { post, get, getFile } = useFetch();
  const { user, CanEvaluate } = useRoot();

  const [dialogType, setDialogType] = useState({ open: false });
  const [viewType, setViewType] = useState(0);
  const [viewTypeResults, setViewTypeResults] = useState(0);
  const [selectedRowType, setSelectedRowType] = useState("");
  const [selectedSample, setSelectedSample] = useState([]);
  const [selectedHistSample, setSelectedHistSample] = useState({});
  const [simplifyHistory, setSimplifyHistory] = useState(true);
  const [samplesData, setSamplesData] = useState([]);
  const [samplesMoreData, setSampleMoreData] = useState({});
  const [history, setHistory] = useState({});
  const [comments, setComments] = useState({});
  const [historyComments, setHistoryComments] = useState({});

  // To Handle all Alerts
  const [dialog, setDialog] = useState({ title: "", open: false });
  const [showTree, setShowTree] = useState(false);
  const [expandTreeData, setExpandTreeData] = useState(null);
  const [infoMessage, setInfoMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [isInfoIcon, setIsInfoIcon] = useState(false);
  const [isSuccessIcon, setIsSuccessIcon] = useState(false);
  const [isWarningTrue, setIsWarningTrue] = useState(false);
  const [regBoxCheck, setRegBoxCheck] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(0);

  async function getSampleReport(row) {
    try {
      let fileName = "";
      if (row?.Customer === undefined) {
        let sampleNameData = await get(`${DETAILS_APIS.GET_SAMPLE_TREE_TOOLSTRIP}/?currentTreeUnitId=${row.UnitId || 0}`, { skipBlocker: true });
        fileName = `${sampleNameData[0] || ""} ${sampleNameData[1] || ""} ${sampleNameData[2] || ""} ${row?.Component || ""} ${row?.SampleNumber || ""} `;
      }
      else { fileName = `${row?.Customer || ""} ${row?.Site || ""} ${row?.Unit || ""} ${row?.Component || ""} ${row?.SampleNumber || ""} `; }

      await getFile(`${TRAY_APIS.REPORT_SAMPLE}/?sampleNo=${row?.SampleNumber}`, fileName);
    } catch (error) {
      console.error("Error fetching or opening PDF:", error);
    }
  }

  const HandleNewWindow = (actionName, data) => {
    useWindowPopups(actionName, data)
  }

  const handleRouteChange = (rows) => {
    let treeData = {
      ProductId: rows?.ProductId,
      StartLetter: rows?.StartLetter,
      CustomerId: rows?.CustomerId,
      SiteId: rows?.SiteId,
      UnitId: rows?.UnitId,
      ComponentId: rows?.ComponentId,
      SampleNumber: rows?.SampleNumber,
    };
    localStorage.setItem("treeData", JSON.stringify(treeData));
    window.open(window.location.origin + "/details/quick", "_blank")
  };

  const handleRouteChangeBT = (rows) => {
    let treeData = {
      ProductId: SampleRow?.ProductId,
      StartLetter: SampleRow?.StartLetter,
      CustomerId: SampleRow?.CustomerId,
      SiteId: SampleRow?.SiteId,
      UnitId: SampleRow?.UnitId,
      ComponentId: SampleRow?.ComponentId,
      SampleNumber: rows?.SampleNumber,
    };
    localStorage.setItem("treeData", JSON.stringify(treeData));
    window.open(window.location.origin + "/details/quick", "_blank")
  };

  //For the Search Functionalities
  function rowActionHandler(row, actionName) {
    if (Object.values(WindowTypes).includes(actionName)) {
      HandleNewWindow(actionName, row)
      return;
    } else if (actionName === "view-in-quick-details-bt") {
      return handleRouteChangeBT(row);
    } else if (actionName === "view-in-quick-details") {
      return handleRouteChange(row);
    } else if (actionName === "view-sample-report") {
      return getSampleReport(row);
    }
    if (
      [
        "edit-sample",
        "unit-hour-history",
        "primary-contacts-overview",
        "recent-unit-results",
        "report-contacts",
        "website-contacts",
        "critical-log",
        "hold-management",
      ].indexOf(actionName) > -1
    ) {
      openDialog(row, actionName);
      return;
    }
  }

  const dialogOptions = useScreenPopups({
    data: dialogType.data || {},
    cancel: closeDialog,
    dialogType: dialogType.type,
  });

  function openDialog(row, actionName) {
    setDialogType({ type: actionName, open: true, data: row });
  }

  function closeDialog() {
    setDialogType({ ...dialogType, open: false });
  }

  function handelViewTypeChange(e) {
    e.target.name === "viewType" && setViewType(e.target.value);
    e.target.name === "viewTypeResults" && setViewTypeResults(e.target.value);
  }

  function onSimplifyHistoryChange(e) {
    setSimplifyHistory(e.target.checked);
  }

  const onViewHandler = useCallback(async function (filter) {
    if (filter.Date === "Invalid date") {
      //  setIsSuccessIcon(false);
      setIsWarningTrue(true);
      setIsInfoIcon(true);

      return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
    }
    setIsInfoIcon(false);
    const query = filter.MyFluids ? "?byUser=true" : "";
    const resp = await post(`${TRAY_APIS.SEARCH_EVALUATIONS + query}`, {
      Date: moment(filter.Date).format(DateFormat),
      LabId:
        filter.LabandDateOption === 0 || filter.LabandDateOption === 1
          ? user.DefaultLaboratoryId
          : null,
      TrayNumber: filter.LabandDateOption === 0 ? filter.Tray : null,
      MinimumStep: 0,
      MaximumStep: LabSteps.Completed,
      Hold: false,
    });
    const sampleData = resp.map((x, i) => ({ ...x, keyProp: i, }));

    if (sampleData?.length < 1) {
      //  setIsSuccessIcon(false);
      setIsWarningTrue(true);
      setIsInfoIcon(true);
      setInfoMessage(appResources.NO_SAMPLES_WERE_FOUND_MSG);
    } else {
      setIsWarningTrue(false);
      setIsInfoIcon(false);
      setInfoMessage();
      setSamplesData(sampleData);
      SampleRow = sampleData[0];
    }

    if (sampleData?.length) {
      sampleRowSelectedHandler(sampleData[0]);
    }

    if (!sampleData?.length) sampleRowSelectedHandler({});
  }, [user.DefaultLaboratoryId]);

  const sampleRowSelectedHandler = useCallback(
    async function (sample) {
      SampleRow = sample;
      if (
        sample.SampleNumber &&
        sample.SampleNumber === selectedSample.SampleNumber
      )
        return;
      if (sample?.keyProp !== undefined) {
        setSelectedRowId(sample?.keyProp || 0)
      }
      if (sample?.Step > 1 && showTree === true) {
        setExpandTreeData({
          ProductId: sample?.ProductId,
          StartLetter: sample?.StartLetter,
          CustomerId: sample?.CustomerId,
          SiteId: sample?.SiteId,
          UnitId: sample?.UnitId,
          ComponentId: sample?.ComponentId,
          SampleNumber: sample?.SampleNumber,
          refreshCheck: true,
        });
      }
      setHistory({})

      // await loadMoreData(sample.SampleNumber);
      await loadHistory(sample.SampleNumber);

      setSelectedSample(sample || {});
      setSelectedHistSample(sample);

      setSelectedRowType("sample");
    },
    [selectedSample]
  );

  const sampleHistoryRowSelectedHandler = useCallback(async function (sample) {
    setSelectedHistSample(sample);
    setSelectedRowType("history");
  }, []);

  async function loadHistory(sampleNo) {
    if (!sampleNo) {
      setHistory({});
      return;
    }
    const sampleData = await get(`${TRAY_APIS.HISTORY}?sampleNo=${sampleNo}&includeLaterSamples=true&resultView=${viewTypeResults}`, {});
    const temp = prepareResultsData(sampleData, reviewActions, rowActionHandler, viewTypeResults);
    setHistory(temp);
    setSampleMoreData({
      ...samplesMoreData,
      [sampleData?.SampleNotesAndImageCount?.SampleNumber]:
        sampleData?.SampleNotesAndImageCount,
    });
  }

  useEffect(
    function () {
      document.title = "Review";

      loadHistory(selectedSample?.SampleNumber);
    },
    [viewTypeResults]
  );

  function commentInputChangeHandler(e) {
    setComments({ ...comments, [e.target.name]: e.target.value });
  }

  const getCurData = useCallback(
    function () {
      if (!selectedSample.SampleNumber) return {};

      if (selectedRowType === "sample") {
        return samplesMoreData[selectedSample.SampleNumber] || {};
      }

      if (selectedRowType === "history") {
        const componentInfo =
          samplesMoreData[selectedSample.SampleNumber] || {};
        const { Recommendation, SampleNotes, Evaluation, SampleNumber } =
          selectedHistSample;
        return {
          ...componentInfo,
          Recommendation,
          SampleNotes,
          Evaluation,
          SampleNumber,
        };
      }
      return {};
    },
    [selectedSample, selectedRowType, selectedHistSample]
  );

  const curData = getCurData();

  useEffect(
    function () {
      let { Evaluation, Recommendation, CustomerComments, SampleNotes } = getCurData();
      if (Evaluation === null) {
        Evaluation = "Readings appear to be satisfactory, keep sampling at the normal rate to monitor further.";
      }
      if (selectedSample?.SampleNumber === selectedHistSample?.SampleNumber) {
        setComments({ ...comments, Evaluation, Recommendation, CustomerComments, SampleNotes });
        setHistoryComments({})
      }
      else {
        setHistoryComments({ Evaluation, Recommendation, CustomerComments, SampleNotes });
      } setRegBoxCheck(CustomerComments?.length > 0 ? true : false)

    },
    [selectedHistSample]
  );

  useEffect(
    function () {
      let { Evaluation, Recommendation, CustomerNotes, SiteNotes, UnitNotes, ComponentNotes, CustomerComments, SampleNotes } = getCurData();
      setComments({ Evaluation, Recommendation, CustomerNotes, SiteNotes, UnitNotes, ComponentNotes, CustomerComments, SampleNotes });
      setHistoryComments({});
      setRegBoxCheck(CustomerComments?.length > 0 ? true : false)
    },
    [selectedSample, selectedRowType]
  );
  const saveCommentsHandler = async () => {

    curData.Recommendation = comments.Recommendation;
    curData.Evaluation = comments.Evaluation;

    await post(TRAY_APIS.UPDATE_COMMENTS, {
      SampleNumber: selectedSample.SampleNumber,
      Evaluation: comments?.Evaluation,
      Recommendation: comments?.Recommendation,
      RegistrationNote: comments?.CustomerComments,
      SampleNote: comments?.SampleNotes,
      ComponentNote: comments?.ComponentNotes,
      UnitNote: comments?.UnitNotes,
      SiteNote: comments?.SiteNotes,
      CustomerNote: comments?.CustomerNotes,
      ScreenName: "Review",
    });
  };

  const handleUpArrow = () => {
    if (selectedRowId > 0) {
      const key = samplesData[selectedRowId - 1];
      if (key !== undefined) {
        sampleRowSelectedHandler(key)
      }
    }
  }

  const handleDownArrow = () => {
    if (selectedRowId < samplesData?.length) {
      const key = samplesData[selectedRowId + 1];
      if (key !== undefined) {
        sampleRowSelectedHandler(key)
      }
    }
  }

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F4" && samplesData?.length > 0) {
        event.preventDefault();
        setDialog({ title: "Fluid Watch", open: true });
      } else if (event.key === "F3") {
        event.preventDefault();
        if (
          selectedSample.ComponentNoteCount.length > 0 &&
          samplesData?.length > 0
        ) {
          setDialog({ title: "Notes", open: true });
        }
      } else if (event.key === "F11") {
        event.preventDefault();
        if (samplesData.length > 0) {
          setDialog({
            title: "Put on Hold from Laboratory",
            open: true,
          });
        }
      } else if (event.key === "ArrowDown" && document.activeElement.className.includes("cell") === true) {
        event.preventDefault();
        ;
        const a = document.getElementById("es1").querySelector('[aria-selected=true]').getAttribute("data-id");
        handleDownArrow()
      }
      else if (event.key === "ArrowUp" && document.activeElement.className.includes("cell") === true) {
        ;
        const a = document.getElementById("es1").querySelector('[aria-selected= true]').getAttribute("data-id");
        event.preventDefault();
        handleUpArrow()
      }
    },
    [samplesData, selectedSample]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleIndex1 = () => {
    setSamplesData([])
    setHistory({ ...history, "data": [] })
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card sx={{ p: 0.5 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item xs={8}>
                <SearchFilter
                  onView={onViewHandler}
                  showTree={showTree}
                  setShowTree={setShowTree}
                  infoMessage={infoMessage}
                  successMessage={successMessage}
                  isInfoIcon={isInfoIcon}
                  isSuccessIcon={isSuccessIcon}
                  isWarningTrue={isWarningTrue}
                  handleIndex1={handleIndex1}
                />
              </Grid>
              <Grid item paddingTop={1}>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <SwitchInput
                      label="Simplify History"
                      value={simplifyHistory}
                      onChange={onSimplifyHistoryChange}
                    />
                  </Grid>
                  <Grid item minWidth="170px">
                    <DropDown
                      value={viewType}
                      name="viewType"
                      onChange={handelViewTypeChange}
                      selectOptions={[
                        { text: "Tray and History", value: 0 },
                        { text: "History", value: 1 },
                        { text: "Tray", value: 2 },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={2} mt={1} hidden={!showTree}>
          <QuickDetailsTree
            expandTreeData={expandTreeData}
            minHeight={"80vh"}
            maxHeight={"80vh"}
            ScreenName={"/trays/review"}
          />
        </Grid>

        <Grid item xs={showTree === true ? 10 : 12}>
          <Grid item container spacing={1}>
            <Grid item xs={12} hidden={viewType === 1}>
              <Typography hidden={viewType === 1} variant="subtitle2">
                Total Records: <b> {samplesData && samplesData?.length}</b>
              </Typography>
              <div id="es1">
                <ResizePanel direction="s" style={{ paddingBottom: "10px" }}>
                  <SamplesTable
                    actions={reviewActions}
                    selectedRow={selectedRowId}
                    rowActionHandler={rowActionHandler}
                    data={samplesData}
                    rowSelected={sampleRowSelectedHandler}
                  />
                </ResizePanel>
              </div>
            </Grid>

            <Grid item xs={12} hidden={samplesData?.length <= 0}>
              <Card sx={{ p: 0.5 }}>

                {Object.entries(historyComments)?.length > 0

                  ? <Grid item xs={12} container spacing={1} alignItems='end'>
                    {regBoxCheck &&
                      <Grid item xs={3}>
                        <TextBox
                          sx={{ "& .MuiInputBase-input": { backgroundColor: "#dda0dd" } }}
                          name="CustomerComments"
                          readOnly={true}
                          value={historyComments?.CustomerComments || ""}
                          onChange={commentInputChangeHandler}
                          label="Registration Comments: on report"
                          multiline
                          rows={4}
                        />
                      </Grid>}
                    <Grid item xs={(regBoxCheck === true) ? 3 : 4}>
                      <TextBox
                        sx={{ "& .MuiInputBase-input": { backgroundColor: "#dda0dd", } }}
                        name="SampleNotes"
                        readOnly={true}
                        value={historyComments?.SampleNotes || ""}
                        onChange={commentInputChangeHandler}
                        label="Sample Comments: internal only"
                        multiline
                        rows={4}
                      />
                    </Grid>
                    <Grid item xs={(regBoxCheck === true) ? 3 : 4}>
                      <TextBox
                        sx={{ "& .MuiInputBase-input": { backgroundColor: "#dda0dd" } }}
                        name="Evaluation"
                        readOnly={true}
                        value={historyComments?.Evaluation || ""}
                        multiline
                        rows={4}
                        onChange={commentInputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={(regBoxCheck === true) ? 3 : 4} >
                      <TextBox
                        sx={{ "& .MuiInputBase-input": { backgroundColor: "#dda0dd" } }}
                        name="Recommendation"
                        readOnly={true}
                        value={historyComments?.Recommendation || ""}
                        multiline
                        rows={4}
                        onChange={commentInputChangeHandler}
                      />
                    </Grid>
                  </Grid>
                  :
                  <Grid container spacing={1}>
                    {regBoxCheck && <Grid item xs={3}>
                      <TextBox
                        sx={{ "& .MuiInputBase-input": { backgroundColor: "#e0ffff" } }}
                        name="CustomerComments"
                        // readOnly={!CanEvaluate}
                        value={comments?.CustomerComments || ""}
                        onChange={commentInputChangeHandler}
                        label="Registration Comments: on report"
                        multiline
                        rows={4}
                      />
                    </Grid>}
                    <Grid item xs={(regBoxCheck === true) ? 3 : 4}>
                      <TextBox
                        name="SampleNotes"
                        value={comments.SampleNotes || ""}
                        // readOnly={!CanEvaluate}
                        label="Sample Comments: internal only"
                        multiline
                        rows={4}
                        onChange={commentInputChangeHandler} />
                    </Grid>
                    <Grid item xs={(regBoxCheck === true) ? 3 : 4}>
                      <TextBox
                        name="Evaluation"
                        value={comments.Evaluation || ""}
                        multiline
                        rows={4}
                        label={<br />}
                        onChange={commentInputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={(regBoxCheck === true) ? 3 : 4}>
                      <TextBox
                        name="Recommendation"
                        value={comments.Recommendation || ""}
                        multiline
                        label={<br />}
                        rows={4}
                        onChange={commentInputChangeHandler}
                      />
                    </Grid>
                  </Grid>}

                {(CanEvaluate) && <Grid container marginTop={"6px"}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant={
                          selectedSample?.HasFluidWatch === true
                            ? "contained"
                            : "outlined"
                        }
                        color={
                          selectedSample?.HasFluidWatch === true
                            ? "lightseagreen"
                            : "primary"
                        }
                        onClick={() =>
                          setDialog({ title: "Fluid Watch", open: true })
                        }
                        startIcon={<ViewIcon />}
                      >
                        Fluid Watch (F4)
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          setDialog({
                            title: "Put on Hold from Laboratory",
                            open: true,
                          })
                        }
                        startIcon={<HoldIcon />}
                      >
                        Hold (F11)
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        startIcon={<NewVersionIcon />}
                      >
                        New Version
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="outlined"
                        disabled={selectedSample.ComponentNoteCount === 0}
                        startIcon={<NotesIcon />}
                        onClick={() =>
                          setDialog({ title: "Notes", open: true })
                        }
                      >
                        Notes (F3)
                      </Button>
                    </Grid>

                    <Grid item>
                      <SwitchInput label="Historical" mt={1} />
                    </Grid>
                    {CanEvaluate && (
                      <Grid item xs={4} paddingLeft={1.5}>
                        <Button
                          variant="contained"
                          startIcon={<SaveCommentsIcon />}
                          onClick={saveCommentsHandler}
                          disabled={Object.entries(historyComments)?.length > 0}
                        >
                          Save Comments
                        </Button>
                      </Grid>
                    )}
                    <Grid item >
                      <BadgeButton
                        variant="outlined"
                        startIcon={<ImagesIcon />}
                        badge={{
                          badgeContent: curData.SampleImageCount,
                        }}
                        label="Images"
                        onClick={() =>
                          setDialog({ title: "Select Images", open: true })
                        }
                        disabled={(curData.SampleImageCount || 0) < 1}
                      />
                    </Grid>

                    <Grid item>
                      <DropDown
                        sx={{
                          marginLeft: "15px",
                          maxWidth: "230px",
                        }}
                        renderValue={(val) =>
                          ["Results", "Divided", "Adjusted"][val]
                        }
                        value={viewTypeResults}
                        name="viewTypeResults"
                        onChange={handelViewTypeChange}
                        selectOptions={[
                          { text: "Results", value: 0 },
                          { text: "Divided by Fluid Hours", value: 1 },
                          { text: "Adjusted for Topup", value: 2 },
                        ]}
                        disabled={selectedSample.FluidTypeId !== 1}
                      />
                    </Grid>
                    {/* Commented because return only works from evaluation and quality check screen */}
                    {/* <Grid item>
                   CanEvaluate&& <Button
                      variant="contained"
                      onClick={() => setAlertTitle("Return To Laboratory as Rerun") & setOpenAlert(true)}
                      startIcon={<NewVersionIcon />}>
                      Return
                    </Button>
                  </Grid> */}
                  </Grid>
                </Grid>
                }

              </Card>
            </Grid>

            <Grid item xs={12} hidden={viewType === 2}>
              <Typography hidden={viewType === 2} >
                Total Records:{" "}
                <b> {history.data ? history?.data?.length : 0}</b>
              </Typography>
              <ResizePanel direction="s" style={{ paddingBottom: "10px" }}>
                <ResultsTable
                  data={history?.data}
                  rowSelected={sampleHistoryRowSelectedHandler}
                  columns={simplifyHistory ? history?.columnsSimple : history?.columns}
                />
              </ResizePanel>
            </Grid>

            <Grid item xs={12}>
              <Card sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextBox
                      name={"ComponentNotes"}
                      value={comments.ComponentNotes || ""}
                      label="Component Comments: internal only"
                      multiline
                      rows={3}
                      onChange={commentInputChangeHandler}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextBox
                      name={"UnitNotes"}
                      value={comments.UnitNotes || ""}
                      label="Unit Comments: internal only"
                      multiline
                      rows={3}
                      onChange={commentInputChangeHandler}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextBox
                      name={"SiteNotes"}
                      value={comments.SiteNotes || ""}
                      label="Site Comments: internal only"
                      multiline
                      rows={3}
                      onChange={commentInputChangeHandler}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextBox
                      name={"CustomerNotes"}
                      value={comments.CustomerNotes || ""}
                      label="Customer Comments: internal only"
                      multiline
                      rows={3}
                      onChange={commentInputChangeHandler}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>

          </Grid>
        </Grid>
      </Grid>

      <AppDialog
        open={dialog.open}
        title={dialog.title}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        maxWidth="xl"
      >
        {dialog.title === "Fluid Watch" && (
          <FluidWatch
            fluidId={selectedSample.FluidId}
            fluidTypeId={selectedSample.FluidTypeId}
          />
        )}
        {dialog.title === "Put on Hold from Laboratory" && (
          <Hold sampleId={selectedSample.SampleNumber} />
        )}
        {dialog.title === "Rerun Samples" && <RerunSamples />}
        {dialog.title === "Missed and Hold Samples" && (
          <MissedSamples screenName={"ReviewTray"} />
        )}
        {dialog.title === "Return To Laboratory as Rerun" && (
          <RerunToLaboratory
            screenName={"Return To Laboratory as Rerun"}
            sampleId={selectedSample}
          />
        )}
        {dialog.title === "Select Images" && (
          <SelectImage
            sampleId={selectedSample.SampleNumber}
            cancel={() =>
              setDialog((prevState) => ({
                ...prevState,
                open: false,
              }))
            }
          />
        )}
        {dialog.title === "Notes" && (
          <AddNotes unitId={selectedSample?.UnitId} />
        )}
      </AppDialog>

      <GroupDialog
        {...dialogOptions}
        open={dialogType.open || false}
        close={closeDialog}
      />
    </>
  );
}

function prepareResultsData(
  { PastSamplesElementData: data, PastSamples: sampleData, TestSetSuperset, GetEventLogsBySampleNumber },
  reviewActions,
  rowActionHandler,
  viewTypeResults
) {
  const colMap = {};

  const samplesMap = {};

  let hasSif = false;
  sampleData?.forEach(function (s) {
    if (s.SIF) {
      hasSif = true;
    }
    samplesMap[s.SampleNumber] = s;
  });

  let newSampleNumber = "";
  if (SampleRow.NewVersion !== null) {
    newSampleNumber = SampleRow.NewVersion;
  }
  data.forEach((x) => {
    //for new version
    if (Object.keys(samplesMap).filter(item => item == x?.SampleNumber)?.length > 0) {

      if (newSampleNumber !== "") {
        if (samplesMap[x?.SampleNumber] !== undefined) {
          samplesMap[x?.SampleNumber][x?.TestItemCode] = x?.FormattedText || x?.FormattedNumeric || "";
          if (colMap[x?.TestItemCode]) {
            return;
          }
          colMap[x?.TestItemCode] = x;
          // }
        }
      } else {
        if (samplesMap[x?.SampleNumber] !== undefined) {
          samplesMap[x?.SampleNumber][x?.TestItemCode] = x?.FormattedText || x?.FormattedNumeric || "";
          if (colMap[x?.TestItemCode]) {
            return;
          }
          colMap[x?.TestItemCode] = x;
        }
        // }
      }
    }
  });

  const dataSrc2 = Object.keys(samplesMap).map((x,) => { return { ...samplesMap[x], } });

  let eventColumns = [];

  let events = GetEventLogsBySampleNumber;
  let dtRow = [];

  if (events !== null && events.length > 0) {
    for (const z of events) {

      if (z.EventTypeId === 3 && z.UnitHours !== undefined && z.SampleNumber !== undefined) {
        const zzz = dataSrc2.find(zz => zz.UnitHours === z.UnitHours);

        if (zzz !== undefined) {
          continue;
        }
      }
      const dr = {};
      if (z.SampleNumber !== undefined) {
        dr.SampleNumber = z.SampleNumber;
      }
      dr.Sampled = z.EventDate;
      if (z.UnitHours !== undefined) {
        dr.UnitHours = z.UnitHours;
      }
      dr.Event = z.EventType;
      if (z.EventTypeId === 3) {
        dr.FluidChanged = 'Yes';
      }
      dr.EventCode = z.EventCode;
      dtRow.push(dr);
    }
  }
  let dataSrc3 = [];
  dataSrc3 = [...dataSrc2, ...dtRow];

  eventColumns = (dtRow?.length > 0)
    ? [{
      headerName: "Event",
      field: "EventCode",// "EventType",
      width: 35,
      sortable: false,
      description: "EventType",
      renderCell: renderCellExpand,
    }]
    : []

  dataSrc3.sort(function (a, b) {
    return ((a.Sampled < b.Sampled) ? -1 : ((a.Sampled > b.Sampled) ? 1 : 0));
  }).reverse()

  const dataSrc = dataSrc3.map((item, i) => { return { ...item, keyProp: i, addi: i + Math.random(), } })

  const columns = TestSetSuperset?.sort(sortFunction).map((x) => ({
    headerName: x.TestItemCode,
    field: x.TestItemCode,
    sortable: false,
    description: x.TestItem || "",
    // eslint-disable-next-line eqeqeq
    minWidth: (ResultColWidths[x.TestItemCode] || viewTypeResults == 0) ? (x.TestItemCode.length > 3 ? 60 : 40) : (x.TestItemCode.length > 3 ? 60 : 50),
    flex: 1,
    //renderCell: renderCellExpand,
  }));

  const columnsSimple = Object.keys(colMap)
    .map((x) => colMap[x])
    .sort(sortFunction)
    .map((x) => ({
      headerName: x.TestItemCode,
      field: x.TestItemCode,
      sortable: false,
      description: x.TestItem || "",
      // eslint-disable-next-line eqeqeq
      minWidth: (ResultColWidths[x.TestItemCode] || viewTypeResults == 0) ? (x.TestItemCode.length > 3 ? 55 : 35) : (x.TestItemCode.length > 3 ? 55 : 45),
      flex: 1,
      //renderCell: renderCellExpand,
    }));

  const menuOptions = [
    { label: "View Sample Report", name: "view-sample-report" },
    { label: "Unit Hour History", name: "unit-hour-history" },
    { label: "Recent Unit History", name: "recent-unit-results" },
    { label: "Edit Sample", name: "edit-sample" },
    { label: "View in Quick Details", name: "view-in-quick-details-bt" },
  ];

  const extraColumns1 = [
    {
      headerName: "Sample Number",
      field: "SampleNumber",
      width: 120,
      renderCell: function ({ row }) {
        return (
          <>
            {(reviewActions || []).map(function (ac, i) {
              return (
                <DataGridAction
                  key={i}
                  row={row}
                  onClick={ac.action}
                  label={ac.label}
                  startIcon={ac.startIcon}
                />
              );
            })}
            <DropdownMenu
              options={menuOptions}
              onItemSelect={(option) => rowActionHandler(row, option)}
            />
            <pre style={{ paddingTop: 0.25, fontSize: "12px" }}>{row.SampleNumber}</pre>
          </>
        );
      },
    },
    ...eventColumns,
    {
      headerName: "UH",
      field: "UnitHours",
      description: "Unit Hours",
      width: 50,
    },
    {
      headerName: "CH",
      field: "ComponentHours",
      description: "Component Hours",
      width: 50,
    },
    {
      headerName: "FH",
      field: "FluidHours",
      description: "Fluid Hours",
      width: 50,
    },
    {
      headerName: "OC",// "Changed",
      field: "FluidChanged",
      description: "Fluid Changed",
      minWidth: 30,
      maxWidth: 30,
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
    },
    {
      headerName: "FC",//"Filter",
      field: "FilterChanged",
      description: "Filter Changed",
      minWidth: 30,
      maxWidth: 30,
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
    },
  ];

  const extraColumns2 = [
    {
      headerName: "Fluid",
      field: "FluidFormatted",
      description: "Topup in Litres",
      minWidth: 200,
    },
    {
      headerName: "TU",
      field: "Topup",
      description: "Topup in Litres",
      width: 60,
    },
    {
      headerName: "KL",
      field: "KidneyLoop",
      description: "Kidney Loop",
      width: 60,
    },
    {
      headerName: "Sampled",
      field: "Sampled",
      valueFormatter: ({ value }) => toLocalDate(value),
    },
    {
      headerName: "Technician",
      field: "Evaluator",
      renderCell: renderCellExpand,
    },
    {
      headerName: "Registration",
      field: "SIF",
      hide: !hasSif,
    },
  ];

  return {
    columns: [...extraColumns1, ...columns, ...extraColumns2],
    columnsSimple: [...extraColumns1, ...columnsSimple, ...extraColumns2],
    data: dataSrc,
  };
}

function sortFunction(a, b) {
  return (
    a.TechnicianRank - b.TechnicianRank ||
    a.TestItemCode.localeCompare(b.TestItemCode)
  );
}
