import { Button, Grid, } from "@mui/material";
import { useCallback, useEffect, useReducer, useState, } from "react"; import Form, { FormActions } from "../../../components/Form";
import useMasterData from "../../../hooks/useMasterData";
import { CheckNumber, getStartLetter, toIsoFormat } from "../../../utils";
import Constants from "../../../common/Constants";
import { useRoot, useToast, useUser } from "../../../rootContext";
import useFetch from "../../../hooks/useFetch";
import { ValidateRegistration } from "../../../validation/schemas/RegistrationSchema";
import GroupDialog from "../../../components/GroupDialog";
import { RegistrationFormActions, useGetFormSections, GetOverViewString, } from "./RegistrationFields";
import TextBox from "../../../components/TextBox";
import appResources from "../../../app-resources/en/Resources.json";
import { TRAY_APIS } from "../../../common/apis";
import {
  ClearIcon,
  RegisterIcon,
  RetrieveRegIcon,
  SearchRegistrationIcon,
} from "../../../icons/ButtonIcons";
import SwitchInput from "../../../components/SwitchInput";
import AppDialog from "../../../components/AppDialog";
import NewFluid from "../../master-data/fluids/NewFluid";

import { DialogTypes, useScreenPopups, } from "../../../components/useScreenPopups";
import { useRef } from "react";
import ConfirmModal from "../../../components/ConfirmModal";
import { WindowTypes, useWindowPopups } from "../../../components/useWindowPopups";
import ManagementSection from "./ManagementSection";

const todayDate = new Date();

const minDateAllowed = new Date(
  todayDate.getFullYear(),
  todayDate.getMonth(),
  todayDate.getDate() - 14
).toISOString();

const maxDateAllowed = new Date(
  todayDate.getFullYear(),
  todayDate.getMonth(),
  todayDate.getDate() + 1
).toISOString();;

export default function RegistrationForm({
  testSetsList,
  sampleData,
  regData,
  compData,
  masterData,
  updateComponent,
  registrationDone,
  searchRegClickHandler,
  retrieveRegistration,
  regSampleExists,
  setRegSampleExists,
  getTrayData,
  filter,
  trayData,
  startLetter,
  setExpandTreeData,
  setInfoMessage,
}) {

  const user = useUser();
  const { CanRegister, CanDoDataMaintenance, CanAdministrate } = useRoot();

  const { get, post } = useFetch();

  const { getMasterData } = useMasterData();
  const [data, dispatch] = useReducer(RegistrationFormReducer, mapFields({ sampleData, regData, compData, trayData, user }));
  const { showToast, showWarningToast } = useToast();

  const [formErrors, setFormErrors] = useState({});
  const [dialogType, setDialogType] = useState({ open: false });
  const [isLockRegisterChecked, setIsLockRegisterChecked] = useState(true);
  const [alertActionName, setAlertActionName] = useState(null);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [registrationVal, setRegistrationVal] = useState("");
  const [newFluidOpen, setNewFluidOpen] = useState(false);
  const [selectedSearchFluidRow, setSelectedSearchFluidRow] = useState();
  const [testSets, setTestSets] = useState([]);

  const handleEnterDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      retrieveRegistration(registrationVal)
    }
  }

  const HandleNewWindow = (actionName) => {
    useWindowPopups(actionName, data)
  }

  const actions = {

    openWindow: function (type) {
      HandleNewWindow(type);
    },

    openDialog: function (type) {
      setDialogType({ type, open: true });
    },

    clear: function (type) {
      dispatch({ type: RegistrationFormActions.RESET_FORM, payload: { SampleNumber: data?.SampleNumber } });
    },

    deleteSample: async function () {
      if (data.Step === 1) {
        setAlertTitle("Delete Sample");
        setAlertActionName(() => actionsAlert?.alertDeleteSample);
        setOpenAlert(true);
        setAlertMessage(
          `${appResources.CREATE_TRAY_DELETE_LAST_SAMPLE_MSG} ${data.SampleNumber} in tray ${data.Tray} for ${data.Laboratory}?`
        );
      }
      else if (data.Step > 1 && CanAdministrate === true) {
        setAlertTitle("Delete Sample");
        setAlertActionName(() => actionsAlert?.alertDeleteSample);
        setOpenAlert(true);
        setAlertMessage(`${appResources.CREATE_TRAY_DELETE_LAST_SAMPLE_MSG} ${data.SampleNumber} in tray ${data.Tray} for ${data.Laboratory}?`);
      }
      else {
        setInfoMessage(`Sample ${data.SampleNumber} is at step ${data.Step} and so cannot be deleted(except by an administrator`)
      }
    },

    register: async function () {
      setFormErrors({});
      const {
        CompChangedHours,
        MeterChangedHours,
        CompRepairedHours,
        CompRepairedDate,
        CompChangedDate,
        ...restData
      } = data; // Spreading data and extracting MeterChangedHours
      const req = {
        ...restData,
        RegistrationId: data?.RegistrationId || data?.Sif,
        ComponentChangedAt: CompChangedHours === "" ? null : CompChangedHours,
        ComponentChangedAtDate: CompChangedDate,
        MeterChangedAt: MeterChangedHours === "" ? null : MeterChangedHours,
        ComponentRepairedAt:
          CompRepairedHours === "" ? null : CompRepairedHours,
        // ComponentRepairedAtDate: CompRepairedDate,
      }; // Spreading restData and adding MeterChangedAt

      ValidateRegistration(req).then(async function ({ data: req, errors }) {
        if (req) {
          await post(TRAY_APIS.REGISTER, req);

          registrationDone(data.SampleNumber);

          let slIndex = 0;
          if (startLetter === undefined || startLetter === null) {
            let name = data?.Customer?.trim();
            slIndex = Number(getStartLetter(name[0]));
          }
          else {
            slIndex = startLetter
          }

          setExpandTreeData({
            ProductId: data?.ProductId,
            StartLetter: slIndex,
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
            ComponentId: data?.ComponentId,
            refreshCheck: true,
          })
          return;
        }
        if (errors) {
          setFormErrors(errors);
        }
      });
    },
  };

  const actionsAlert = {
    alertDeleteSample: async function () {
      const sampleDeleted = await get(`${TRAY_APIS.DELETE_SELECTED_SAMPLE}?SampleNumber=${data.SampleNumber}&Screen=Registration`);
      if (sampleDeleted > 0) {
        showToast(`Sample ${data?.SampleNumber} for ${data.Laboratory} has been DELETED`);
        registrationDone(true);
        setOpenAlert(false);
      } else {
        showWarningToast(appResources.SOMETHING_WENT_WRONG_MSG);
      }
    },
  };

  async function selectFluid(fluid) {
    if (data?.FluidTypeId !== fluid.FluidTypeId && data?.FluidType !== fluid.FluidType) {
      const temp = await getMasterData("TestSetsForUnitFluidTypes", `&unitId=${data?.UnitId || 1}&fluidTypeId=${fluid.FluidTypeId || 1}`);
      setTestSets(temp.TestSetsForUnitFluidTypes || []);
      dispatch({ type: RegistrationFormActions.APPLY_NEW_FLUID, payload: { ...fluid, TestSetId: null } });
    }
    else {
      dispatch({ type: RegistrationFormActions.APPLY_FLUID, payload: fluid });
    }

    localStorage.removeItem("search-fluid-resp");
    closeDialog();
  }

  function editFluid(row) {
    setSelectedSearchFluidRow(row);
    setNewFluidOpen(true);
  }

  function selectComponent(comp) {
    updateComponent(comp.ComponentId);
  }

  const dialogOptions = useScreenPopups({
    data: data || {},
    cancel: closeDialog,
    dialogType: dialogType.type,
    selectFluid: selectFluid,
    editFluid: editFluid,
    selectComponent: selectComponent,
    setExpandTreeData: setExpandTreeData,
    startLetter: startLetter,
  });

  function closeDialog() {
    setDialogType({ ...dialogType, open: false });
  }

  function onLockRegisterChange(e) {
    setIsLockRegisterChecked(!isLockRegisterChecked);
  }

  const hanldeLastMonth = () => {
    dispatch({
      type: RegistrationFormActions.DATE_CHANGE,
      payload: { Sampled: minDateAllowed },
    });
    let topUpField = document.getElementsByName("Topup")
    if (topUpField?.length > 0) {
      topUpField[0].focus()
    }
  }

  const GetFormSections = useGetFormSections(
    maxDateAllowed,
    hanldeLastMonth,
    regSampleExists,
    setRegSampleExists,
    testSets,
    data,
    actions,
    { ...masterData, user }
  );

  const handleDateString = () => {
    let a = data?.DateSampledStr;

    if (a?.length === 6) {
      const dd = a.slice(0, 2);
      const mm = a.slice(2, 4);
      const yy = a.slice(4, 6);

      if (yy < ((todayDate?.getFullYear() + '')?.slice(-2))) {
        let date1 = 20 + yy + "-" + mm + "-" + dd;
        let time = toIsoFormat(date1);
        if (time !== null) {
          dispatch({
            type: RegistrationFormActions.DATE_CHANGE,
            payload: { Sampled: time },
          });
          // let topUpField = document.getElementsByName("Topup")
          // if (topUpField?.length > 0) {
          //   topUpField[0].focus()
          // }
        }
      }
      if ((yy == ((todayDate?.getFullYear() + '')?.slice(-2))) && (mm < (todayDate?.getMonth() + 1))) {
        let date1 = 20 + yy + "-" + mm + "-" + dd;
        let time = toIsoFormat(date1);
        if (time !== null) {
          dispatch({
            type: RegistrationFormActions.DATE_CHANGE,
            payload: { Sampled: time },
          });
          // let topUpField = document.getElementsByName("Topup")
          // if (topUpField?.length > 0) {
          //   topUpField[0].focus()
          // }
        }
      }
      if ((yy == ((todayDate?.getFullYear() + '')?.slice(-2))) && (mm == (todayDate?.getMonth() + 1)) && (dd <= (todayDate?.getDate() + 1))) {
        let date1 = 20 + yy + "-" + mm + "-" + dd;
        let time = toIsoFormat(date1);
        if (time !== null) {
          dispatch({
            type: RegistrationFormActions.DATE_CHANGE,
            payload: { Sampled: time },
          });
          // let topUpField = document.getElementsByName("Topup")
          // if (topUpField?.length > 0) {
          //   topUpField[0].focus()
          // }
        }
      }
      else return;
    }
  };

  const handlePaste = event => {
    let val = (event?.clipboardData?.getData('text'));
    retrieveRegistration(val)
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F12") {
        event.preventDefault();
        if ((data?.SampleNumber === undefined || data?.Step > 1)) return;
        (dialogType.open === false && openAlert === false) && actions.register();
      } else if (event.key === "F4") {
        event.preventDefault();
        (data.CustomerId && dialogType.open === false && openAlert === false) && actions.openWindow(DialogTypes.EDIT_CUSTOMER);
      } else if (event.key === "F5") {
        event.preventDefault();
        (data.SiteId && dialogType.open === false && openAlert === false) && actions.openWindow(DialogTypes.EDIT_SITE);
      } else if (event.key === "F6") {
        event.preventDefault();
        (data.UnitId && dialogType.open === false && openAlert === false) && actions.openWindow(DialogTypes.EDIT_UNIT);
      } else if (event.key === "F7") {
        event.preventDefault();
        (data.ComponentId && dialogType.open === false && openAlert === false) && actions.openWindow(DialogTypes.EDIT_COMPONENT);
      } else if (event.key === "F3") {
        event.preventDefault();
        (data.FluidId && dialogType.open === false && openAlert === false) && actions.openWindow(DialogTypes.SEARCH_FLUID);
      } else if (event.key === "Tab") {
        event.preventDefault();
        var inputs = document.getElementsByTagName('input');
        for (var i = 0; i < inputs?.length; i++) {
          if (document.activeElement.id == inputs[i].id && i + 1 < inputs?.length) {
            inputs[i + 1].focus();
            break;
          }
        }
      }
      else if (event.key === "Escape") {
        event.preventDefault();
        (dialogType.open === false && openAlert === false) && hanldeLastMonth()
      }
    },
    [data, dialogType, openAlert]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  //test sets are handled in registration call
  useEffect(
    function () {
      (async function () {
        if (data?.ComponentId) {
          const temp = await getMasterData("TestSets", `&compId=${data.ComponentId}`);
          setTestSets(temp.TestSets || []);
        }
      })();
    }, [data.ComponentId]);

  useEffect(() => {
    if (testSetsList?.length > 0) {
      setTestSets(testSetsList)
    }
    else {
      setTestSets([])
    }
  }, [testSetsList]);

  useEffect(() => {
    if (data?.FluidChanged === true && (data?.FluidChangedAt?.length < 1 || data?.FluidChangedAt === undefined || data?.FluidChangedAt === null || data?.FluidChangedAt === "")) {
      dispatch({
        type: RegistrationFormActions.LOAD_DATA,
        payload: {
          FluidChangedAt: data?.MeterReading,
        },
      });
    }
    if (data?.ComponentChanged === true && (data?.CompChangedHours?.length < 1 || data?.CompChangedHours === undefined || data?.CompChangedHours === null || data?.CompChangedHours === "")) {
      dispatch({
        type: RegistrationFormActions.LOAD_DATA,
        payload: {
          CompChangedHours: data?.MeterReading,
        },
      });
    }
    if (data?.MeterChanged === true && (data?.MeterChangedHours?.length < 1 || data?.MeterChangedHours === undefined || data?.MeterChangedHours === null || data?.MeterChangedHours === "")) {
      dispatch({
        type: RegistrationFormActions.LOAD_DATA,
        payload: {
          MeterChangedHours: data?.MeterReading,
        },
      });
    }

  }, [data?.FluidChanged, data?.ComponentChanged, data?.MeterChanged])

  const regIdInput = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      regIdInput.current.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    handleDateString();
  }, [data?.DateSampledStr]);

  function eventCb(event) {

    if (event.storageArea != localStorage) return;
    const resp = JSON.parse(localStorage.getItem("search-fluid-resp"));
    if (resp?.FluidId > 0) {
      selectFluid(resp)
    }

    const resp1 = JSON.parse(localStorage.getItem("component-resp"));
    if (resp1?.TestSetId > 0) {
      dispatch({
        type: RegistrationFormActions.LOAD_DATA,
        payload: {
          TestSetId: resp1?.TestSetId,
        },
      });
      localStorage.removeItem("component-resp")
    }
  }

  useEffect(function () {
    window.addEventListener("storage", eventCb);
    return function () {
      window.removeEventListener("storage", eventCb);
    };
  });

  return (
    <>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        sx={{ mb: 1 }}
      >
        <ManagementSection
          allData={data}
          trayData={trayData}
          dialogType={dialogType}
          registrationDone={registrationDone}
          setInfoMessage={setInfoMessage}
          getTrayData={getTrayData}
          filter={filter}
        />
      </Grid>

      <Grid container spacing={1}>

        <Grid item xs={12} container justifyContent="space-between">

          <Grid item xs={10}>
            <Grid container spacing={0.5} alignItems="flex-end">
              <Grid item sx={{ w: 10 }}>
                <TextBox
                  ref={regIdInput}
                  id={"RegistrationId"}
                  name="RegistrationId"
                  value={registrationVal}
                  autoFocus
                  onChange={(e) => {
                    let a = e.target.value.replaceAll("*", "");
                    setRegistrationVal(a);
                  }}
                  placeHolder={"Registration Id"}
                  disabled={!data.SampleNumber}
                  // onPaste={(e) => retrieveRegistration(registrationVal)}
                  onPaste={(e) => handlePaste(e)}
                  onKeyUp={(e) => handleEnterDown(e)}
                  autoComplete="off"
                />
              </Grid>
              {CanRegister && data.SampleNumber && (
                <Grid item>
                  <Button
                    startIcon={<RetrieveRegIcon />}
                    onClick={() => retrieveRegistration(registrationVal)}
                  >
                    Retrieve Registration
                  </Button>
                </Grid>
              )}
              <Grid item hidden={!data.SampleNumber}>
                <Button
                  startIcon={<SearchRegistrationIcon />}
                  onClick={() => searchRegClickHandler(true)}
                >
                  Search Registration
                </Button>
              </Grid>
              {(CanRegister || CanDoDataMaintenance) && data?.UnitId && (
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => HandleNewWindow(WindowTypes?.ADD_NEW_COMPONENT)
                    }
                  >
                    New Component
                  </Button>
                </Grid>
              )}

              {(CanRegister || CanDoDataMaintenance) && data?.SiteId && (
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => HandleNewWindow(WindowTypes?.ADD_NEW_UNIT)
                    }
                  >
                    New Unit
                  </Button>
                </Grid>
              )}
              {(CanRegister || CanDoDataMaintenance) && data?.CustomerId && (
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => HandleNewWindow(WindowTypes?.ADD_NEW_SITE)
                    }
                  >
                    New Site
                  </Button>
                </Grid>
              )}
              {(CanRegister || CanDoDataMaintenance) && data?.ProductId && (
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => HandleNewWindow(WindowTypes?.ADD_NEW_CUSTOMER)
                    }
                  >
                    New Customer
                  </Button>
                </Grid>
              )}
              <Grid item>
                <SwitchInput
                  label="Lock Already Registered"
                  value={isLockRegisterChecked}
                  onChange={onLockRegisterChange}
                  sx={{ ".MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": { backgroundColor: "red" }, ml: 2 }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Grid container justifyContent="flex-end">
              {
                (!data.ProductArchived ||
                  !data.CustomerArchived ||
                  !data.SiteArchived ||
                  !data?.ArchivedUpper) &&
                  (CanRegister || CanDoDataMaintenance) &&
                  // data?.Sif &&
                  data?.ComponentId &&
                  data?.TestSetId &&
                  data?.FluidId &&
                  (data.Step === Constants.regStep ||
                    isLockRegisterChecked === false) ?
                  (
                    <Grid item>
                      <Button
                        startIcon={<RegisterIcon />}
                        variant="contained"
                        onClick={actions.register}
                        sx={{ marginRight: "10px" }}
                      >
                        Register (F12)
                      </Button>
                    </Grid>
                  ) : <></>}
              <Grid item>
                <Button
                  startIcon={<ClearIcon />}
                  onClick={() => actions.clear()}
                  variant="outlined"
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Form
            sections={GetFormSections}
            data={data}
            errors={formErrors}
            spacing={1}
            dispatch={dispatch}
            key={
              (data.SampleNumber || "") +
              (data.ComponentId || "") +
              (data._ts || "")
            }
          />
        </Grid>
      </Grid >

      <GroupDialog
        {...dialogOptions}
        open={dialogType.open || false}
        close={closeDialog}
      />
      {/* Dialog Box */}
      <AppDialog
        open={openAlert}
        title={alertTitle}
        maxWidth="md"
        handleClose={() => {
          setOpenAlert(false);
        }}
      >
        <ConfirmModal
          handleClose={() => { setOpenAlert(false); }}
          alertAction={alertActionName}
          message={alertMessage}
        />
      </AppDialog>
      <AppDialog
        open={newFluidOpen}
        title="Edit Fluid"
        maxWidth="md"
        handleClose={() => setNewFluidOpen(false)}
      >
        <NewFluid
          cancel={() => setNewFluidOpen(false)}
          rowManageSelected={selectedSearchFluidRow}
          isEdit={true}
        />
      </AppDialog>
    </>
  );
}

// REsponse data mapping
function mapFields({ sampleData, regData, compData, trayData, user }) {

  const sampleTransformed = {
    ProductArchived: sampleData?.ProductArchived || regData?.ProductArchived,
    CustomerArchived: sampleData?.CustomerArchived || regData?.CustomerArchived,
    SiteArchived: sampleData?.SiteArchived || regData?.SiteArchived,
    UnitArchived: sampleData?.UnitArchived || regData?.UnitArchived,
    ComponentArchived: sampleData?.ComponentArchived || regData?.ComponentArchived,
    SampleArchived: sampleData?.SampleArchived || regData?.SampleArchived,
    IsScrapped: sampleData?.IsScrapped,
    IsParked: sampleData?.IsParked,
    HasContacts: sampleData?.HasContacts,
    HasContactsNonKomatsu: sampleData?.HasContactsNonKomatsu,

    SampleNumber: sampleData.SampleNumber,
    Tray: sampleData.Tray,
    TrayDate: sampleData.TrayDate,
    Sample: sampleData.Sample,
    Laboratory: sampleData.Laboratory,
    Label: sampleData.Label,
    Sif: sampleData.SIF || compData?.SIF,
    ComponentId: sampleData.ComponentId,
    AddressId: sampleData.AddressId,
    Street1: sampleData.Street1,
    City: sampleData.City,
    State: sampleData.State,
    PostCode: sampleData.PostCode,
    Country: sampleData.Country,
    ComponentType: sampleData.ComponentType,
    Serial: sampleData.Serial,
    DefaultFluidId: sampleData.DefaultFluidId,
    DefaultFluidType: sampleData.DefaultFluidType,
    DefaultFluidFormatted: sampleData.DefaultFluidFormatted,
    DefaultFluid: sampleData.DefaultFluid || compData?.DefaultFluid,
    DefaultTestSetId: sampleData.DefaultTestSetId,
    LastFluidId: sampleData.LastFluidId,
    LastFluid: sampleData.LastFluid,
    LastFluidFormatted: sampleData.LastFluidFormatted,
    LastFluidType: sampleData.LastFluidType,
    LastTestSetId: sampleData.LastTestSetId,
    LastTestSet: sampleData.LastTestSet,
    DefaultTestSet: sampleData.DefaultTestSet,
    OwnerLaboratoryId: sampleData.LaboratoryId,
    LaboratoryId: sampleData.LaboratoryId,
    //IsPredelivery: "",
    IsInternalOnly: sampleData.IsInternalOnly,
    IsArchived: sampleData.SampleArchived,
    //IsDynamic: "",
    MeterReading: CheckNumber(sampleData.MeterReading || compData?.MeterReading),
    ComponentReading: CheckNumber(sampleData.ComponentReading || compData?.ComponentReading),
    HasPrimaryContacts: sampleData?.HasPrimaryContacts || regData?.HasPrimaryContacts,
    MeterChangedHours: sampleData.MeterChangedHours,
    MeterChangedAtDate: sampleData.MeterChangedDate || minDateAllowed,
    MeterChanged: (sampleData.MeterChangedEventId > 0 ? true : false) || false,
    MeterChangedEventId: sampleData.MeterChangedEventId,
    CompChangedHours: sampleData.CompChangedHours,
    CompChangedDate: sampleData.CompChangedDate || minDateAllowed,
    ComponentChanged: (sampleData.CompChangedEventId > 0 ? true : false) || false,

    ComponentChangedEventId: sampleData.ComponentChangedEventId,
    CompChangedEventId: sampleData.CompChangedEventId,
    ComponentRepairedAt: sampleData.CompRepairedHours,
    ComponentRepairedAtDate: sampleData.CompRepairedDate || minDateAllowed,
    ComponentRepaired: (sampleData.CompRepairedEventId > 0 ? true : false) || false,
    ComponentRepairedEventId: sampleData.ComponentRepairedEventId,
    FluidChangedAt: sampleData.FluidChangedHours || compData?.FluidChangedAt,
    FluidChangedAtDate: sampleData.FluidChangedDate || compData?.FluidChangedAtDate || minDateAllowed,
    FluidChanged: (sampleData.FluidChanged === true ? true : false) || !!sampleData.FluidChangedEventId || compData?.FluidChanged,
    FluidChangedEventId: sampleData.FluidChangedEventId,
    FluidHours: sampleData.FluidHours || compData?.FluidHours,
    FluidFormatted: sampleData.FluidFormatted || compData?.DefaultFluidFormatted,
    FluidType: sampleData?.FluidType || compData?.DefaultFluidType,
    Topup: sampleData.Topup,
    FilterChanged: sampleData.FilterChanged,
    FluidId: sampleData.FluidId || compData?.DefaultFluidId,
    MakeFluidDefault: false,
    ApplyFluidToAllSamples: false,
    TestSetId: sampleData.TestSetId,
    MakeTestSetDefault: false,
    Sampled: sampleData.Sampled || compData?.Sampled || minDateAllowed,
    Registered: sampleData.Registered,
    SampledAssumed: sampleData.SampledAssumed === true ? true : false,
    JobNumber: sampleData.JobNumber,
    SampleOptionId: sampleData.SampleOptionId || 0,
    //InternalNumber: "",
    ServiceTypeId: sampleData.ServiceTypeId || compData?.ServiceTypeId,
    SampleTypeId: sampleData.SampleTypeId || 1,
    UserId: user?.UserId,
    Screen: "Registration",

    Component: sampleData.Component,
    Unit: sampleData.Unit,
    Site: sampleData.Site,
    Customer: sampleData.Customer,

    UnitId: sampleData.UnitId,
    SiteId: sampleData.SiteId,
    CustomerId: sampleData.CustomerId,

    SampleNote: sampleData.SampleNotes || "",
    ComponentNote: sampleData.ComponentNotes || "",
    UnitNote: sampleData.UnitNotes || "",
    SiteNote: sampleData.SiteNotes || "",
    CustomerNote: sampleData.CustomerNotes || "",
    CustomerComments: sampleData.CustomerComments || "",

    Step: sampleData.Step,

    Product: sampleData.Product,
    ProductId: sampleData.ProductId,
    FluidTypeId: sampleData.FluidTypeId,
    Model: sampleData.UnitModelFormatted,
    Measure: sampleData.MeasureLong,
    Capacity: sampleData.Capacity,
  };

  let tempCompData = {};
  if (compData) {
    tempCompData = {
      Component: compData.Component,
      Unit: compData.Unit,
      Site: compData.Site,
      Customer: compData.Customer,

      ComponentNote: compData.ComponentNotes || "",
      UnitNote: compData.UnitNotes || "",
      SiteNote: compData.SiteNotes || "",
      CustomerNote: compData.CustomerNotes || "",

      ComponentId: compData.ComponentId,
      AddressId: compData.AddressId,
      Street1: compData.Street1,
      City: compData.City,
      State: compData.State,
      PostCode: compData.PostCode,
      Country: compData.Country,
      ComponentType: compData.ComponentType,
      Serial: compData.Serial,
      DefaultFluidId: compData.DefaultFluidId,
      DefaultFluidType: compData.DefaultFluidType,
      DefaultFluidFormatted: compData.DefaultFluidFormatted,
      DefaultFluid: compData.DefaultFluid,
      DefaultTestSetId: compData.DefaultTestSetId,
      LastFluidId: compData.LastFluidId,
      LastFluid: compData.LastFluid,
      LastFluidType: compData.LastFluidType,
      LastTestSetId: compData.LastTestSetId,
      LastTestSet: compData.LastTestSet,
      DefaultTestSet: compData.DefaultTestSet,
      LastFluidFormatted: compData.LastFluidFormatted,
      UnitId: compData.UnitId,
      SiteId: compData.SiteId,
      CustomerId: compData.CustomerId,
      Model: compData.UnitModelFormatted,
      Capacity: compData.Capacity,
      Measure: compData.MeasureLong,
      LastFluidHours: compData.LastFluidHours,
      LastMeterReading: compData.LastMeterReading,
      LastComponentHours: compData.LastComponentHours,
      Product: compData.Product,
      ProductId: compData.ProductId,
      TestSetId: compData.TestSetId,
    };
  }

  let data;
  if (regData) {
    data = {
      ...sampleTransformed,
      Sif: regData.SIF,
      Product: regData.Product,
      RegistrationId: regData.RegistrationId,
      JobNumber: regData.JobNumber,
      Sampled: regData.Sampled || minDateAllowed,
      SampledAssumed: regData.Sampled === true ? true : false,
      ComponentId: regData.ComponentId,
      // AddressId: sampleData.AddressId,
      // Street1: sampleData.Street1,
      // City: sampleData.City,
      // State: sampleData.State,
      // PostCode: sampleData.PostCode,
      // Country: sampleData.Country,
      ComponentType: regData.ComponentType,
      Serial: regData.Serial,
      DefaultFluidId: regData.DefaultFluidId,
      DefaultFluidType: regData.DefaultFluidType,
      DefaultFluidFormatted: regData.DefaultFluidFormatted,
      DefaultFluid: regData.DefaultFluid || compData?.DefaultFluidFormatted,
      DefaultTestSetId: regData.DefaultTestSetId,
      LastFluidId: regData.LastFluidId,
      LastFluid: regData.LastFluid,
      LastFluidType: regData.LastFluidType,
      LastTestSetId: regData.LastTestSetId,
      LastTestSet: regData.LastTestSet,
      DefaultTestSet: regData.DefaultTestSet,
      MeterReading: CheckNumber(regData.UnitMeter),
      ComponentReading: CheckNumber(regData.ComponentMeter),
      FluidHours: CheckNumber(regData.FluidMeter),
      Topup: CheckNumber(regData.Topup),
      MeterChanged: regData.UnitMeterChanged || false,
      MeterChangedHours: regData.UnitMeterChanged
        ? CheckNumber(regData.UnitMeterChangedAt || regData.UnitMeter)
        : "",
      MeterChangedAtDate: regData.UnitMeterChangedAtDate || regData.Sampled || minDateAllowed,

      ComponentChanged: regData.ComponentChanged || false,
      CompChangedHours: regData.ComponentChanged
        ? CheckNumber(regData.ComponentChangedAt || regData.UnitMeter)
        : "",
      CompChangedDate: regData.ComponentChangedAtDate || regData.Sampled || minDateAllowed,

      ComponentRepaired: regData.ComponentRepaired || false,
      ComponentRepairedAt: regData.ComponentRepaired
        ? (regData?.ComponentRepairedAt || regData?.UnitMeter)
        : "",
      ComponentRepairedAtDate:
        regData.ComponentRepairedAtDate || regData.Sampled || minDateAllowed,

      FluidChanged: regData.FluidChanged,
      FluidChangedAt: regData.FluidChanged
        ? CheckNumber(regData.FluidChangedAt || regData.UnitMeter)
        : "",
      FluidChangedAtDate: regData.FluidChangedAtDate || regData.Sampled || minDateAllowed,

      FluidFormatted: regData.FluidName || compData?.DefaultFluidFormatted,
      FluidId: regData.FluidId || compData?.DefaultFluidId,
      MakeFluidDefault: false, //TODO
      StartLetter: compData?.StartLetter,
      ServiceTypeId: regData.ServiceTypeId,

      CustomerComments: regData.Notes
        ? regData.Notes + (regData.AddedBy ? "\r\n" + regData.AddedBy : "")
        : "",
      FilterChanged: regData.FilterChanged,

      regSampleNo: regData.SampleNumber,

      ...tempCompData,
    };
    GetOverViewString(data, trayData);
    return data;
  }
  data = { ...sampleTransformed, ...tempCompData };
  GetOverViewString(data, trayData);
  return data;
}
//Regisatration form data mapping
function RegistrationFormReducer(state, action) {

  if (action.type === RegistrationFormActions.DATE_CHANGE) {
    return { ...state, ...action.payload, };
  }
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === RegistrationFormActions.APPLY_FLUID) {
    return {
      ...state,
      FluidId: action.payload.FluidId,
      FluidFormatted: action.payload.FluidFormatted,
      FluidType: action.payload.FluidType,
      FluidTypeId: action.payload.FluidTypeId,
      _ts: Date.now(),
    };
  }
  if (action.type === RegistrationFormActions.APPLY_NEW_FLUID) {
    return {
      ...state,
      TestSetId: action.payload.TestSetId,
      FluidId: action.payload.FluidId,
      FluidFormatted: action.payload.FluidFormatted,
      FluidType: action.payload.FluidType,
      FluidTypeId: action.payload.FluidTypeId,
      _ts: Date.now(),
    };
  }
  if (action.type === RegistrationFormActions.LOAD_DATA) {
    return { ...state, ...action.payload, _ts: Date.now() };
  }

  if (action.type === RegistrationFormActions.RESET_FORM) {
    return { ...action.payload, _ts: Date.now() };
  }
  return state;
}
