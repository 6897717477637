import { useCallback, useEffect, useReducer, useState } from "react";
import { Card, FormControlLabel, Grid, Radio, Typography } from "@mui/material";
import Form, { FormActions } from "../../components/Form";
import { Search } from "@mui/icons-material";
import FormFooter from "../../components/FormFooter";
import useFetch from "../../hooks/useFetch";
import { ClearIcon } from "../../icons/ButtonIcons";
import { DETAILS_APIS, MASTER_DATA_APIS } from "../../common/apis";
import SearchGeneralTable from "./SearchGeneralTable";
import renderCellExpand from "../../components/DatatableCellExpand";

export default function SearchGeneral({ handleSearchTree }) {
  const { get, post } = useFetch();
  const [filter, dispatch] = useReducer(
    SearchQuickDetailsReducer,
    SearchQuickDetailsDefaults
  );
  const [tableData, setTableData] = useState([]);
  const [headerNameList, setHeaderNameList] = useState([]);
  const [errMessage, setErrMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState(1);
  const [allProducts, setAllProducts] = useState([]);

  const formActions = {
    search: function (e) {
      handleSearch(filter);
    },
    clear: function (e) {
      dispatch({ type: FormActions.RESET_FORM });
      setTableData([]);
      setErrMessage("");
    },
  };

  const handleRadioChange = (e) => {
    let val = Number(e.target.value);
    dispatch({
      type: FormActions.INPUT_CHANGED,
      payload: { name: "archivedOption", value: val },
    });
  };

  const handleSearchRadioChange = (e) => {
    let val = Number(e.target.value);
    setSearchTerm(val)
  };

  const handleSearch = async () => {
    const postObj = {
      ComponentModel: filter?.ComponentModel,

      ProductId: filter?.ProductId,
      SampleNumber: filter?.SampleNumber,
      ReportNumber: filter?.ReportNumber,
      Sif: filter?.Sif,
      JobNumber: filter?.JobNumber,
      Customer: filter?.Customer,
      CustomerId: filter?.CustomerId === "" ? null : filter?.CustomerId,
      Site: filter?.Site,
      Town: filter?.Town,
      Unit: filter?.Unit,
      Serial: filter?.Serial,
      Make: filter?.Make,
      Model: filter?.Model,
      Component: filter?.Component,
      ComponentId: filter?.ComponentId === "" ? null : filter?.ComponentId,
      EqpMachineId: filter?.EqpMachineId === "" ? null : filter?.EqpMachineId,
      SiteId: filter?.SiteId === "" ? null : filter?.SiteId,
      UnitId: filter?.UnitId === "" ? null : filter?.UnitId,
      EventLogId: filter?.EventLogId === "" ? null : filter?.EventLogId,
      ComponentSerial: filter?.ComponentSerial,
      UnarchivedOnly:
        filter?.archivedOption === 1
          ? true
          : filter?.archivedOption === 1
            ? true
            : null,
      UseStartsWith: searchTerm === 0 ? true : false,//filter?.UseStartsWith,
      Parent: filter?.Parent,
    };
    const pathname = window.location.search.replace("?sn=", "")
    localStorage.setItem("search-general-" + pathname || "", JSON.stringify({ ...postObj }));

    const result = await post(DETAILS_APIS.SEARCH_GENERAL, postObj);

    if (typeof result[0] == "string") { return setErrMessage("Please narrow your search scope by providing more information"); }
    if (result?.length < 1) {
      setTableData([]);
      return setErrMessage("No results were found from your search");
    }
    setTableData(result);
    setErrMessage("");
    let FormFields = GetColumns();
    let headList = FormFields.filter((x) =>
      (Object.keys(result[0]) || []).includes(x.accessorKey)
    );
    setHeaderNameList(headList);
  };

  const handleSearchSelect = (row) => {

    // handleSearchTree && handleSearchTree(row);
    const treeData = { ProductId: row?.ProductId, StartLetter: row?.StartLetter, CustomerId: row?.CustomerId, SiteId: row?.SiteId, UnitId: row?.UnitId, ComponentId: row?.ComponentId, SampleNumber: row?.SampleNumber, refreshCheck: true }
    const pathname = window.location.search.replace("?sn=", "")
    //  window.location.pathname.replace("/", "");

    localStorage.setItem("search-general-" + pathname + "-resp", JSON.stringify({ ...treeData }));
    window.dispatchEvent(new Event('storage'));
    window.close();
  };

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSearch();
      }
    },
    [filter]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    const pathname = window.location.search.replace("?sn=", "")
    let ls = JSON.parse(localStorage.getItem("search-general-" + pathname || ""));
    if (ls !== undefined && ls !== null) {
      dispatch({
        payload: {
          ...ls,
        },
        type: FormActions.LOAD_DATA,
      });
    }

    (async function () {
      const response = await get(`${MASTER_DATA_APIS.GET_BY_TYPES}?types=Products`);
      //Products with AllProducts added at 0 index
      const allProd = JSON.parse(JSON.stringify(response.Products));
      if (allProd) {
        allProd.unshift({
          ProductId: 0,
          Product: "All Products",
          Colour: "",
        });
      }
      setAllProducts(allProd || {});
    })();
  }, []);

  return (
    <>
      <Grid minHeight={700}>
        <Card sx={{ p: 1, pb: 1, mb: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Form
                sections={useFormSections(
                  allProducts,
                  filter,
                  handleRadioChange
                )}
                data={filter}
                dispatch={dispatch}
                key={filter._ts_key || ""}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography sx={{ mt: 1 }}>Wildcard Characters: Use % for 0 or more characters and _ for single character</Typography>
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                checked={searchTerm === 0}
                onChange={handleSearchRadioChange}
                value={0}
                control={<Radio />}
                label="Starts-with Search: term%"
              />
              <FormControlLabel
                checked={searchTerm === 1}
                onChange={handleSearchRadioChange}
                value={1}
                control={<Radio />}
                label="Mid-word Search: %term%"
              />
            </Grid>

            <Grid item xs={4}>
              <FormFooter
                footerTextSx={{ color: "red" }}
                footerText={errMessage}
                buttons={GetFormButtons(formActions)}
              />
            </Grid>
          </Grid>
        </Card>

        {tableData?.length > 0 ? (
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Total Records: <b>{tableData?.length}</b>
            </Typography>

            <SearchGeneralTable
              data={tableData}
              headerNameList={headerNameList}
              handleSearchSelect={handleSearchSelect}
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

const GetFormButtons = ({ search, clear }) => [
  {
    variant: "outlined",
    label: "Clear",
    onClick: clear,
    startIcon: <ClearIcon />,
  },
  {
    variant: "contained",
    label: "Search",
    onClick: search,
    startIcon: <Search />,
  },
];

const useFormSections = (allProducts, filter, handleRadioChange) => {
  return [
    {
      fields: [
        {
          xsCol: 12,
          group: [
            {
              name: "ProductId",
              label: "Product",
              xsCol: 2,
              type: "dropdown",
              selectOptions: (allProducts || []).map((x) => ({
                value: x.ProductId,
                text: x.Product,
              })),
              sx: {
                [`.MuiSelect-select`]: {
                  backgroundColor: allProducts?.find((x) => x.ProductId === (filter?.ProductId))?.Colour || "",
                  color: filter.ProductId === 0 ? "" : "white",
                  [`.MuiSvgIcon-root-MuiSelect-icon`]: {
                    color: filter.ProductId === 0 ? "" : "white",
                  },
                },
                [`.MuiSelect-icon`]: {
                  color: filter.ProductId === 0 ? "" : "white",
                },
              },
            },
            { name: "Customer", label: "Customer", xsCol: 2 },
            { name: "Site", label: "Site", xsCol: 2 },
            { name: "Unit", label: "Unit", xsCol: 2 },
            { name: "Serial", label: "Serial", xsCol: 2 },
            {
              name: "archivedOption",
              xsCol: 2,
              component: function () {
                return (
                  <FormControlLabel
                    checked={filter?.archivedOption === 0}
                    onChange={handleRadioChange}
                    value={0}
                    control={<Radio />}
                    label="Archived and Unarchived"
                  />
                );
              },
            },
          ],
        },
        {
          xsCol: 12,
          group: [
            { name: "SampleNumber", label: "Sample Number", xsCol: 2 },
            { name: "Make", label: "Make", xsCol: 2 },
            { name: "Model", label: "Model", xsCol: 2 },
            { name: "Component", label: "Component", xsCol: 2 },
            { name: "ComponentSerial", label: "Comp. Serial", xsCol: 2 },
            {
              name: "archivedOption",
              xsCol: 2,
              component: function () {
                return (
                  <FormControlLabel
                    checked={filter?.archivedOption === 1}
                    onChange={handleRadioChange}
                    value={1}
                    control={<Radio />}
                    label="Unarchived Only"
                  />
                );
              },
            },
          ],
        },
        {
          xsCol: 12,
          group: [
            { name: "Sif", label: "Registration", xsCol: 2 },
            { name: "Town", label: "Town", xsCol: 2 },
            { name: "JobNumber", label: "Job Number", xsCol: 2 },
            { name: "ReportNumber", label: "Report Number", xsCol: 2 },
            { name: "Parent", label: "Parent", xsCol: 2 },
            {
              name: "archivedOption",
              xsCol: 2,
              component: function () {
                return (
                  <FormControlLabel
                    checked={filter?.archivedOption === 2}
                    onChange={handleRadioChange}
                    value={2}
                    control={<Radio />}
                    label="Archived Only"
                  />
                );
              },
            },
          ],
        },
        {
          xsCol: 12,
          group: [
            {
              name: "CustomerId",
              label: "Customer Id",
              xsCol: 2,
              type: "number",
            },
            { name: "SiteId", label: "Site Id", xsCol: 2, type: "number" },
            { name: "UnitId", label: "Unit Id", xsCol: 2, type: "number" },
            {
              name: "ComponentId",
              label: "Component Id",
              xsCol: 2,
              type: "number",
            },
            {
              name: "EventLogId",
              label: "EventLog Id",
              xsCol: 2,
              type: "number",
            },
            {
              name: "EqpMachineId",
              label: "Eqp Machine Id",
              xsCol: 2,
              type: "number",
            },
            {
              name: "ComponentModel",
              label: "Component Model",
              xsCol: 2,
            },
          ],
        },
      ],
    },
  ];
};

function SearchQuickDetailsReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...SearchQuickDetailsDefaults, _ts_key: Date.now() };
  }
  if (action.type === FormActions.LOAD_DATA) {
    return { ...SearchQuickDetailsDefaults, ...action.payload, _ts_key: Date.now() };
  }
  return state;
}

const SearchQuickDetailsDefaults = {
  ProductId: 0,
  archivedOption: 0,
};

const GetColumns = () => [
  {
    header: "SampleNumber",
    accessorKey: "SampleNumber",
    size: 100,
  },
  {
    header: "SIF",
    accessorKey: "SIF",
    size: 60,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "ReportNumber",
    accessorKey: "ReportNumber",
    size: 107,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "AdHocReportType",
    accessorKey: "AdHocReportType",
    size: 136,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Product",
    accessorKey: "Product",
    size: 80,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Parent",
    accessorKey: "Parent",
    size: 140,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Customer",
    accessorKey: "Customer",
    size: 140,
    flex: 1,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Site",
    accessorKey: "Site",
    size: 150,
    flex: 1,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Unit",
    accessorKey: "Unit",
    size: 140,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Make",
    accessorKey: "UnitMake",
    size: 90,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Model",
    accessorKey: "UnitModelFormatted",
    size: 140,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Serial",
    accessorKey: "Serial",
    size: 80,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Component",
    accessorKey: "Component",
    size: 140,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Component Model",
    accessorKey: "ComponentModel",
    size: 140,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "CompSerial",
    accessorKey: "CompSerial",
    size: 140,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Predelivery",
    accessorKey: "Predelivery",
    size: 75,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Internal",
    accessorKey: "Internal",
    size: 70,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Parked",
    accessorKey: "Parked",
    size: 75,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Scrapped",
    accessorKey: "Scrapped",
    size: 75,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Event",
    accessorKey: "Event",
    size: 140,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Physical",
    accessorKey: "PhysicalCity",
    size: 140,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Postal",
    accessorKey: "PostalCity",
    size: 140,
    Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
  },
  {
    header: "Archived",
    accessorKey: "Archived",
    size: 80,
  },
];
