import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useState, } from "react";
import DropDown from "../../../components/DropDown";
import ImportHistoryTable from "./ImportHistoryTable";
import { FLEET_APIS } from "../../../common/apis";
import useFetch from "../../../hooks/useFetch";
import { toIsoFormat, } from "../../../utils";
import AppDialog from "../../../components/AppDialog";
import ConfirmModal from "../../../components/ConfirmModal";

export default function ImportHistory() {

  const { post } = useFetch();

  const [jsonArray, setJsonArray] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [dialog, setDialog] = useState({ open: false, });
  const [info, setInfo] = useState({ color: "black", text: "" });

  const xmlToJson = (xml) => {
    const data = [];
    const tableNodes = xml.getElementsByTagName("Table1");

    for (let i = 0; i < tableNodes.length; i++) {
      const tableNode = tableNodes[i];
      const entry = {};
      for (let j = 0; j < tableNode.childNodes.length; j++) {
        const childNode = tableNode.childNodes[j];
        if (childNode.nodeType === Node.ELEMENT_NODE) {
          entry[childNode.nodeName] = childNode.textContent;
        }
      }
      data.push(entry);
    }
    const payload = data.map(i => { return renameKeys(i, newKeys) });
    return payload;
  };

  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    setInfo({ color: "green", text: "" })

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const xmlText = event.target.result;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlText, "text/xml");
        const jsonData = xmlToJson(xmlDoc);

        setJsonArray([...jsonData]);
        const data = getData([
          ...jsonData,
        ]);
        setOriginalData(data);
      };
      reader.readAsText(file);
    }
  };

  function getData(jsonData) {
    const transformedData = transformData(jsonData);
    return transformedData;
  }

  const handleImportClick = async () => {

    let list = originalData?.filter(i => i.ignoreDetail !== true);

    let resData = await post(FLEET_APIS.IMPORT_HISTORY_XML, list);

    if (resData) {

      setDialog({
        open: false
      });
      setJsonArray([]);
      setOriginalData([]);
      setInfo({ color: "green", text: "Samples were successfully imported to the Database." })
    }
  };

  const handleClearClick = () => {
    setOriginalData([]);
    setJsonArray([]);
    setInfo({ color: "black", text: "" });
  }

  useEffect(() => { document.title = "Import History" }, [])

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Grid container spacing={1} alignItems={"end"}  >
              <Grid item xs={3}>
                <DropDown
                  name="fileId"
                  label="File Type"
                  selectOptions={[
                    { text: "Oil Commander XML", value: 0 },
                    // { text: "XML", value: 1 },
                    // {
                    //   text: "Manage Equipment > Fluid Analysis > Sample Data Extract; choose XML for Format Type",
                    //   value: 2,
                    // },
                  ]}
                  required={true}
                  value={0}
                // onChange={handleDropdown}
                />
              </Grid>

              <Grid item xs={7} mt={2.2}>
                <Grid container spacing={1} alignContent={"end"} alignItems={"end"}  >
                  <input
                    type="file"
                    accept="text/xml"
                    onClick={(e) => { e.target.value = null }}
                    onChange={handleFileChange} />
                  <Grid   >
                    <Typography sx={{ color: info?.color || "" }}>{info?.text || ""}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={2} mt={1.6}>
                <Grid container spacing={1} justifyContent={"right"} alignContent={"end"}>

                  <Grid   >
                    <Button
                      aria-hidden={false}
                      onClick={() => {
                        setDialog({
                          open: true,
                          title: "Import History Samples",
                          message: "Do you wish to IMPORT the third-party history samples into KOWA?",
                          actionName: handleImportClick
                        });
                      }}
                    >
                      Import
                    </Button>
                  </Grid>

                  <Grid ml={1} >
                    <Button
                      variant="outlined"
                      onClick={handleClearClick}
                    >
                      Clear
                    </Button>
                  </Grid>

                </Grid>

              </Grid>

            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }}>

          <Typography variant="subtitle2" sx={{ color: "green" }}>
            {jsonArray?.length > 0 ? (jsonArray?.length + " third-party history samples are displayed") : ""}
          </Typography>

          <ImportHistoryTable
            originalData={originalData}
            setOriginalData={setOriginalData}
          />
        </Grid>
      </Grid>

      <AppDialog
        open={dialog?.open}
        handleClose={() =>
          setDialog({ open: false, })
        }
        title={dialog?.title}
        maxWidth={"xl"}
      >
        <ConfirmModal
          handleClose={() => {
            setDialog({
              open: false,
            });
          }}
          alertAction={dialog?.actionName}
          message={dialog?.message}
        />
      </AppDialog>
    </>
  );
}

const transformData = (jsonData) => {
  const transformedData = jsonData.map(transformEntry);
  return transformedData;
};

const transformEntry = (entry) => {
  const transformedEntry = {};
  for (const key in entry) {
    const value = entry[key];
    let transformedValue = value;

    // Apply your transformation logic based on the key
    if (key === "Make") {
      switch (value) {
        case "KDC":
          transformedValue = "Komatsu";
          break;
        case "KM":
          transformedValue = "Komatsu";
          break;
        case "AA":
          transformedValue = "Caterpillar";
          break;
        default:
          transformedValue = "Unknown";
          break;
      }
    }
    // else if (key === "sampledate" || key === "processdate") {
    //   transformedValue = new Date(value);
    // }
    // Add more transformations as needed for other keys

    transformedEntry[key] = transformedValue;
  }

  return transformedEntry;
};


function renameKeys(obj, newKeys) {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key;
    if (newKey === "Sampled" || newKey === "Released") {
      return { [newKey]: (toIsoFormat(obj[key])) };
    }
    if (newKey === "UnitHours") {
      return { [newKey]: (obj[key])?.length > 0 ? (obj[key]) : null }
    }
    if (newKey === "FluidMake") {
      return {
        [newKey]: ((obj[key])?.split(" ")[0]) || "",
        "Fluid": ((obj[key])?.split(" ").slice(1).join(" ")) || "",
        "FluidType": key === "coolantid" ? "Coolant" : "Oil",
      };
    }
    else return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

const newKeys = {
  // "hello": "Source",

  "labno": "SampleId",
  "cust_name": "Customer",
  "Job_x0020_Site": "Site",
  "unitno": "Unit",
  "makeid": "Make",
  "modelid": "Model",
  "serialno": "Serial",
  "compart": "Component",
  "oilgradeid": "FluidGrade",
  "oiltypeid": "FluidMake",
  "coolantid": "FluidMake",
  "sampledate": "Sampled",
  "processdate": "Released",
  "meterread": "UnitHours",
  "actual_fluid_hours": "ComponentHours",
  "oilhours": "FluidHours",
  "oilchanged": "FluidChanged",
  "notesos": "Evaluation",
  "V40": "Viscosity40",
  "V": "Viscosity100",
  "Fluid": "Fluid"

  // DEP: "OK",
  // ISO: "2191",
  // PC4: "38456",
  // PC6: "2788",
  // PC10: "448",
  // PC14: "116",
  // PC50: "<1",
  // PQ: "4",
  // WAT: "<0.1",

};//keys to be replaced with
