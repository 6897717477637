import { Grid, } from "@mui/material";
import { useMemo, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { DETAILS_APIS } from "../../common/apis";
import QuickDetailsTree from "./QuickDetailsTree";
import { useEffect } from "react";
import ResizePanel from "react-resize-panel";
import ViewDetails from "./ViewDetails";

function QuickDetails() {

  const { get } = useFetch();
  const [nodeLevel, setNodeLevel] = useState(0);
  const [sectionsData, setSectionsData] = useState({});
  const [expandTreeData, setExpandTreeData] = useState({});
  const [searchOpen, setSearchOpen] = useState(false);

  const treeLevels = useMemo(
    () => ({
      product: 0,
      stLetter: 1,
      customer: 2,
      site: 3,
      unit: 4,
      component: 5,
      sample: 6,
    }),
    []
  );

  const nodeSelectedHandler = (node) => {
    setNodeLevel(treeLevels[node.nodeType] || 0);
    getNodeDetailData(node);
  };
  const treeNodeSelectedHandler = (node) => {
    setNodeLevel(treeLevels[node.nodeType] || 0);
  };

  const callNodeDetailApi = async (node) => {
    if (node.nodeType === "customer") {
      return await get(`${DETAILS_APIS.DETAILS}?types=customer&cId=${node.customer}`, { skipBlocker: true });
    }
    if (node.nodeType === "site") {
      return await get(`${DETAILS_APIS.DETAILS}?types=customer,site&cId=${node.customer}&sId=${node.site}`, { skipBlocker: true });
    }
    if (node.nodeType === "unit") {
      return await get(`${DETAILS_APIS.DETAILS}?types=customer,site,unit&cId=${node.customer}&sId=${node.site}&uId=${node.unit}`, { skipBlocker: true });
    }
    if (node.nodeType === "component") {
      return await get(`${DETAILS_APIS.DETAILS}?types=customer,site,unit,component&cId=${node.customer}&sId=${node.site}&uId=${node.unit}&compId=${node.component}`, { skipBlocker: true });
    }
    if (node.nodeType === "sample") {
      return await get(`${DETAILS_APIS.DETAILS}?types=customer,site,unit,component,sample&cId=${node.customer}&sId=${node.site}&uId=${node.unit}&compId=${node.component}&sampleNo=${node.sample}`, { skipBlocker: true });
    }
    return {};
  };

  const getNodeDetailData = async (node) => {
    const data = await callNodeDetailApi(node);
    setSectionsData(data);
  };

  const handleSearchTreeData = (node, data) => {
    setNodeLevel(treeLevels[node.nodeType] || 0);
    setSectionsData(data);
  }

  useEffect(() => {
    document.title = "Quick Details";
    let treeData1 = localStorage.getItem('treeData');

    if (treeData1 !== null) {
      let treeData = JSON.parse(treeData1);
      setExpandTreeData(treeData)
      localStorage.removeItem("treeData");
    }
  }, [])

  return (
    <Grid container  >
      <ResizePanel direction="e">
        <Grid item flex={1} sx={{ mr: 1, minWidth: "300px", maxWidth: "700px" }}>
          <QuickDetailsTree
            expandTreeData1={expandTreeData}
            nodeSelected={nodeSelectedHandler}
            treeNodeSelected={treeNodeSelectedHandler}
            handleSearchTreeData={handleSearchTreeData}
            searchOpen={searchOpen}
            setSearchOpen={setSearchOpen}
            ScreenName={"details/quick"}
          />
        </Grid>
      </ResizePanel>

      <Grid item flex={5} sx={{ overflowY: "auto", minHeight: "92vh", maxHeight: "92vh", pr: "5px" }}>
        <ViewDetails
          sectionsData={sectionsData}
          nodeLevel={nodeLevel}
          setSearchOpen={setSearchOpen}
          setExpandTreeData={setExpandTreeData}
        />
      </Grid>
    </Grid>

  );
}

export default QuickDetails;