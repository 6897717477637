import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useCallback, useMemo, useReducer, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import { REPORT_APIS } from "../../../../common/apis";
import Form, { FormActions } from "../../../../components/Form";
import { AddIcon, ClearIcon, SearchIcon } from "../../../../icons/ButtonIcons";
import { Close, Deselect, DoneOutline, Email } from "@mui/icons-material";
import EmailTable from "./EmailTable";
import SampleTable from "./SampleTable";
import { useRoot, useToast } from "../../../../rootContext";
import { useWindowPopups } from "../../../../components/useWindowPopups";

export default function EmailsAndSamplesReports() {

  const reportListAll = [
    { value: "Sample Report PDF", text: "Sample Report PDF" },
    { value: "Machine Report PDF", text: "Machine Report PDF" },
    { value: "CMS Brochure Only", text: "CMS Brochure Only" },
    { value: "Monthly Report PDF", text: "Monthly Report PDF" },
    { value: "Monthly Report Excel", text: "Monthly Report Excel" },
    { value: "Sample Results Excel", text: "Sample Results Excel" },
    { value: "Sample Results Email", text: "Sample Results Email" },
    { value: "Sample Results XML", text: "Sample Results XML" },
    { value: "Customer History Excel", text: "Customer History Excel" },
    { value: "Site History Excel", text: "Site History Excel" },
    { value: "Unit History Excel", text: "Unit History Excel" },
  ]

  const reportList = [
    { value: "CMS Brochure Only", text: "CMS Brochure Only" },
  ]

  // const successMessage = { color: "green", message: "Email(s) sent successfully!" };
  // const errorMessage = { color: "red", message: "Email(s) not sent" };

  const { post } = useFetch();
  const { CanDoDataMaintenance } = useRoot();
  const { showToast, showErrorToast } = useToast();

  const [data, dispatch] = useReducer(SearchSampleReportReducer, SearchContactDefaults);

  const [sampleReportData, setSampleReportData] = useState([]);
  const [reportTypeOptions, setReportTypeOptions] = useState(reportList);
  const [sampleReportDataCheckbox, setSampleReportDataCheckbox] = useState([]);
  const [emailReportDataCheckbox, setEmailReportDataCheckbox] = useState([]);
  const [emailReportData, setEmailReportData] = useState([]);
  const [emailSelectedRows, setEmailSelectedRows] = useState([]);
  const [emailReportDataSelectedRows, setEmailReportDataSelectedRows] = useState([]);
  const [infoMessage, setInfoMessage] = useState({})

  useEffect(() => {
    // Update the title tag
    document.title = "Emails Selected Samples and Special Reports";
    let parseData;
    const samplesList = localStorage.getItem('emailSelectedItems');

    if (samplesList !== "null") {
      parseData = JSON.parse(samplesList)
      if (parseData !== undefined && parseData !== null) {
        setSampleReportDataCheckbox(parseData)
        setEmailReportDataSelectedRows(parseData)
        getSampleReportData(parseData)
        if (CanDoDataMaintenance === true) {
          dispatch({
            type: FormActions.LOAD_DATA,
            payload: {
              ...SearchContactDefaults,
              ReportNumbers: "Sample Report PDF",
              _ts_key: Date.now(),
            },
          });
          return setReportTypeOptions(reportListAll)
        }
      };
    }
    if (CanDoDataMaintenance !== true) {
      dispatch({
        type: FormActions.LOAD_DATA,
        payload: {
          ...SearchContactDefaults,
          ReportNumbers: "CMS Brochure Only",
          _ts_key: Date.now(),
        },
      });
      return setReportTypeOptions(reportList)
    }
  }, []);

  async function getSampleReportData(parseData) {
    const response = await post(REPORT_APIS.ALL_SAMPLE_CHAIN_SIMPLE, {
      sampleNumbers: parseData,//sampleReportDataCheckbox,
      reportNumbers: parseData,
    });
    if (response) {
      setSampleReportData(response);
      setSampleReportDataCheckbox(response?.map((s) => s.ItemNumber));
      dispatch({
        payload: {
          ReportNumbers: "Sample Report PDF",
          CMSBrochure: false,
          TakeSample: false,
          WebsiteGuide: false,
          IsComplete: true,
          AttachmentSize: 50,
          _ts_key: Date.now(),
        },
        type: FormActions.LOAD_DATA,
      });
    }
  }

  function rowsEmailSelectedHandler(row) {
    if (row?.length > 0) {
      const selectedVals = row.map(item => item.Username)
      setEmailSelectedRows(row);
      setEmailReportDataCheckbox(selectedVals)
    }
    else {
      setEmailSelectedRows(row);
      return setEmailReportDataCheckbox([]);
    }
  }

  function rowsSampleSelectedHandler(row) {
    if (row?.length > 0) {
      const selectedVals = row.map(item => item.ItemNumber)
      setSampleReportDataCheckbox(selectedVals)
      return setEmailReportDataSelectedRows(row);
    }
    else {
      setSampleReportDataCheckbox([]);
      return setEmailReportDataSelectedRows([]);
    }
  }

  function resetForm() {
    dispatch({
      type: FormActions.RESET_FORM,
      payload: { ...SearchContactDefaults, _ts_key: Date.now() },
    });
  }

  function selectAllItemsClick() {
    const temp = sampleReportData?.map((s) => s.ItemNumber);
    setSampleReportDataCheckbox(temp);
  }

  function deSelectAllItemsClick() {
    setSampleReportDataCheckbox([]);
  }

  function removeSelectClick() {
    let filteredList = [];
    if (emailReportDataSelectedRows.length > 0) {
      const filteredArray1 = sampleReportData?.filter(
        (item1) =>
          !emailReportDataSelectedRows.some(
            (item2) => (item2?.ItemNumber || item2) === item1.ItemNumber
          )
      );
      setSampleReportData(filteredArray1);
      filteredList = filteredArray1?.map(item => (item.ItemNumber))

      if (filteredList.length > 0) {
        localStorage.setItem("emailSelectedItems", JSON.stringify([...filteredList || filteredList]));
        return window.dispatchEvent(new Event('storage'))
      }
      else {
        localStorage.setItem("emailSelectedItems", null);
        return window.dispatchEvent(new Event('storage'))
      }
    }
  }

  async function emailSelectedClick() {
    let temp1 = [];

    if (emailSelectedRows.length > 0) {
      temp1 = emailSelectedRows?.map((x) => ({
        ...x,
        ContactId: x.ContactId,
        Username: x.Username || null,
        DisplayName: x.DisplayName || null,
        Forename: x.Forename || null,
        Surname: x.Surname || null,
        DisplayName1: x.DisplayName1 || null,
        Telephone: x.Telephone || null,
        Mobile: x.Mobile || null,
        Company: x.Company || null,
        Department: x.Department || null,
        IsKAL: x.IsKAL || false,
        IsKomatsu: x.IsKomatsu || false,
        IsPortalUser: x.IsPortalUser || false,
        PortalCountry: x.PortalCountry || null,
        IsLabcheckUser: x.IsLabcheckUser || false,
        IsEmailUser: x.IsEmailUser || false,
        IsSpecialUser: x.IsSpecialUser || false,
        IsArchived: x.IsArchived || false,
        KomatsuCompany: x.KomatsuCompany || null,
        Street1: x.Street1 || null,
        City: x.City || null,
        State: x.State || null,
        PostCode: x.PostCode || null,
        Country: x.Country || null,
        IsPrimaryContact: x.IsPrimaryContact || false,
        HasScope: x.HasScope || false,
        HasLinkages: x.HasLinkages || false,
        HasSubscriptions: x.HasSubscriptions || false,
        HasExports: x.HasExports || false,
        UserFound: x.UserFound || false,
        ContactRoleId: x.ContactRoleId || null,
        ContactRole: x.ContactRole || null,
        ContactRoleIsDefault: x.ContactRoleIsDefault || false,
      }));
    } else {
      temp1 = emailReportData?.map((x) => ({
        ...x,
        ContactId: x.ContactId,
        Username: x.Username || null,
        DisplayName: x.DisplayName || null,
        Forename: x.Forename || null,
        Surname: x.Surname || null,
        DisplayName1: x.DisplayName1 || null,
        Telephone: x.Telephone || null,
        Mobile: x.Mobile || null,
        Company: x.Company || null,
        Department: x.Department || null,
        IsKAL: x.IsKAL || false,
        IsKomatsu: x.IsKomatsu || false,
        IsPortalUser: x.IsPortalUser || false,
        PortalCountry: x.PortalCountry || null,
        IsLabcheckUser: x.IsLabcheckUser || false,
        IsEmailUser: x.IsEmailUser || false,
        IsSpecialUser: x.IsSpecialUser || false,
        IsArchived: x.IsArchived || false,
        KomatsuCompany: x.KomatsuCompany || null,
        Street1: x.Street1 || null,
        City: x.City || null,
        State: x.State || null,
        PostCode: x.PostCode || null,
        Country: x.Country || null,
        IsPrimaryContact: x.IsPrimaryContact || false,
        HasScope: x.HasScope || false,
        HasLinkages: x.HasLinkages || false,
        HasSubscriptions: x.HasSubscriptions || false,
        HasExports: x.HasExports || false,
        UserFound: x.UserFound || false,
        ContactRoleId: x.ContactRoleId || null,
        ContactRole: x.ContactRole || null,
        ContactRoleIsDefault: x.ContactRoleIsDefault || false,
      }));
    }
    // const selectedSampleNumbers = sampleReportDataCheckbox?.map((item) => (item));
    const joinedSamples = sampleReportDataCheckbox.join(";")

    const temp2 = sampleReportData?.map((y) => ({
      ContactName: y.ContactName || null,
      EmailAddress: y.EmailAddress || null,
      AttachmentSize: data.AttachmentSize,
      IsZipped: data.IsZipped,
      ShowEvaluation: false,
      SampleNumbers: joinedSamples,
      ReportNumbers: null,
      UnitIds: y.UnitIds,
      SiteIds: y.SiteIds,
      CustomerIds: y.CustomerIds,
      SampleCount: sampleReportDataCheckbox?.length,
      ReportCount: 0,
      UnitCount: 0,
      SiteCount: 0,
      CustomerCount: 0,
      GroupTotal: null,
      GroupCountFrom: null,
      AttachMarketingMaterials: data.WebsiteGuide
        ? true
        : false || data.TakeSample
          ? true
          : false || data.CMSBrochure
            ? true
            : false,
      MarketingMaterialNames:
        (data.WebsiteGuide ? "WebsiteGuide" : "") +
        (data.WebsiteGuide && data.TakeSample ? "," : "") +
        (data.TakeSample ? "TakeSample" : "") +
        (data.TakeSample ? "," : "") +
        (data.CMSBrochure ? "CMSBrochure" : ""),
      MessageText: data.MessageText,
      BatchID: null,
      TemplateName: null,
      sampleReports:
        data.ReportNumbers === "Sample Report PDF" ? data.ReportNumbers : null,
      machineReports:
        data.ReportNumbers === "Machine Report PDF" ? data.ReportNumbers : null,
      excel:
        data.ReportNumbers === "Sample Results Excel"
          ? data.ReportNumbers
          : null,
      sampleEmails:
        data.ReportNumbers === "Sample Results Email"
          ? data.ReportNumbers
          : null,
      xml:
        data.ReportNumbers === "Sample Results XML" ? data.ReportNumbers : null,
      adhocReports:
        data.ReportNumbers === "CMS Brochure Only" ? data.ReportNumbers : null,
      monthlyReportPDF:
        data.ReportNumbers === "Monthly Report PDF" ? data.ReportNumbers : null,
      monthlyReportExcel:
        data.ReportNumbers === "Monthly Report Excel"
          ? data.ReportNumbers
          : null,
      customerhistoryExcel:
        data.ReportNumbers === "Customer History Excel"
          ? data.ReportNumbers
          : null,
      sitehistoryExcel:
        data.ReportNumbers === "Site History Excel" ? data.ReportNumbers : null,
      unithistoryExcel:
        data.ReportNumbers === "Unit History Excel" ? data.ReportNumbers : null,
      brochureEmail: data?.ReportNumbers === "CMS Brochure Only" ? true : null,
    }));

    const marketingString = (data?.CMSBrochure === true ? "CMSBrochure, " : "")
      + (data?.WebsiteGuide === true ? "WebsiteGuide, " : "")
      + (data?.TakeSample === true ? "TakeSample" : "")
    const sampleData = data.ReportNumbers === "CMS Brochure Only"
      ? {
        BatchID: null,
        brochureEmail: data?.ReportNumbers === "CMS Brochure Only" ? true : null,
        MarketingMaterialNames: marketingString,
        GroupCountFrom: null,
        GroupTotal: null,
        IsZipped: data.IsZipped,
        MessageText: data.MessageText,
        AttachmentSize: data.AttachmentSize,
      } :
      temp2[0];

    const mergeTemp = {
      contacts: temp1,
      samplesExport: sampleData,
    };

    const response = await post(REPORT_APIS.EMAIL_SELECTED_SAMPLES, mergeTemp);
    if (response !== null && response >= 0) {
      removeSelectClick();
      showToast("Email(s) sent successfully!");
      // setInfoMessage(successMessage)
    }
    else {
      showErrorToast("Email(s) not sent");
      // setInfoMessage(errorMessage)
    }
  }

  function clearContactClick() {
    setEmailReportData([]);
  }
  //add controls of contact select
  function addContactClick() {
    const updatedContactData = {
      Username: data.UserName,
      DisplayName: data.ForeName + " " + (data.SureName || ""),
      IsKomatsu: false,
    };
    let isUserNameExists = false;

    for (let i = 0; i < emailReportData.length; i++) {
      if (emailReportData[i].Username === updatedContactData.Username) {
        isUserNameExists = true;
        break;
      }
    }
    if (
      updatedContactData.Username &&
      updatedContactData.DisplayName &&
      isUserNameExists === false
    ) {
      setEmailReportData((prevData) => [...prevData, updatedContactData]);
      setEmailReportDataCheckbox((prevState) => [
        ...prevState,
        updatedContactData?.Username,
      ]);
    }
  }

  const HandleNewWindow = (actionName) => {
    useWindowPopups(actionName, { selectCheck: true, ...data });
  }

  function findContactClick() {
    HandleNewWindow("search-contact",)
  }

  //add row of contact select
  function handleContactRowData(row) {
    setEmailReportData((prevData) => {
      // Check if the row already exists in the list
      if (!prevData.some((existingRow) => existingRow.Username == row.Username)) {
        // If the row doesn't exist, add it to the list
        setEmailReportDataCheckbox((prevState) => [
          ...prevState,
          row?.Username,
        ]);
        return [...prevData, row];
      }
      return prevData;
    });
  }

  function clearAllItems() {
    setSampleReportData([]);
    setEmailReportData([]);

    setReportTypeOptions(reportList);
    dispatch({
      type: FormActions.LOAD_DATA,
      payload: {
        ...SearchContactDefaults,
        ReportNumbers: "CMS Brochure Only",
        _ts_key: Date.now(),
      },
    });
    localStorage.setItem('emailSelectedItems', null);
    return window.dispatchEvent(new Event('storage'))
  }

  const emailValidation = useMemo(
    function ValidateEmail() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/.test(data?.UserName)) {
        return (false)
      }
      return (true)
    }, [data?.UserName]
  );

  const nameValidation = useMemo(
    function () {
      if (data?.ForeName?.trim()?.length > 2) {
        return (false)
      }
      return (true)
    }, [data?.ForeName]
  );

  const eventCb = () => {

    const selectContact = JSON.parse(localStorage.getItem('search-contact-resp'));
    if (selectContact) {
      handleContactRowData(selectContact)
      localStorage.removeItem('search-contact-resp')
    }
  }

  useEffect(function () {
    window.addEventListener("storage", eventCb);
    return function () {
      window.removeEventListener("storage", eventCb);
    };
  });

  const handleKeyPress = useCallback(
    (event) => {
      event.stopPropagation();
      if (event.key === "F12") {
        event.preventDefault();
        if (emailReportData?.length > 0) {
          emailSelectedClick();
        }
      }
      else if (event.key === "F11") {
        event.preventDefault();
        findContactClick();
      }
      else if (event.key === "F10") {
        event.preventDefault();
        if (!(emailValidation || nameValidation)) {
          addContactClick();
        }
      }
    }, [data, emailReportData, emailValidation, nameValidation]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (data?.ReportNumbers === "CMS Brochure Only") {
      dispatch({
        type: FormActions.LOAD_DATA,
        payload: {
          ...data,
          CMSBrochure: true,
          TakeSample: true,
          WebsiteGuide: true,
          _ts_key: Date.now(),
        },
      });
    }
  }, [data?.ReportNumbers])

  return (
    <>
      <Grid container sx={{ mb: 1 }}>
        <Card sx={{ p: 1, width: "100%" }}>
          <Grid item xs={12}>
            <Form
              sections={GetFormSections(
                resetForm,
                selectAllItemsClick,
                emailSelectedClick,
                deSelectAllItemsClick,
                removeSelectClick,
                clearContactClick,
                findContactClick,
                addContactClick,
                clearAllItems,
                sampleReportData,
                emailReportData,
                reportTypeOptions,
                emailReportDataSelectedRows,
                infoMessage,
                emailValidation,
                nameValidation
              )}
              data={data}
              dispatch={dispatch}
              key={data._ts_key || ""}
            />
          </Grid>
        </Card>
      </Grid>

      <Grid container spacing={2}>

        <Grid item xs={5}>
          {emailReportData?.length === 0 && (
            <Typography variant="subtitle2">
              No Contacts have been selected
            </Typography>
          )}
          {emailReportData?.length > 0 && (
            <Typography variant="subtitle2">
              {emailReportDataCheckbox?.length || 0} Contact(s) have been selected
            </Typography>
          )}
          <EmailTable
            rowsSelectedEmail={rowsEmailSelectedHandler}
            data={emailReportData}
            emailReportDataCheckbox={emailReportDataCheckbox}
          />
        </Grid>

        <Grid item xs={7}>
          {sampleReportData?.length === 0 && (
            <Typography variant="subtitle2">
              No Sample have been selected
            </Typography>
          )}
          {sampleReportData?.length > 0 && (
            <Typography variant="subtitle2">
              {sampleReportDataCheckbox?.length || 0} Sample(s) have been selected
            </Typography>
          )}
          <SampleTable
            rowsSelected={rowsSampleSelectedHandler}
            data={sampleReportData}
            sampleReportDataCheckbox={sampleReportDataCheckbox}
          />

        </Grid>
      </Grid>
    </>
  );
}

function GetFormSections(
  resetForm,
  selectAllItemsClick,
  emailSelectedClick,
  deSelectAllItemsClick,
  removeSelectClick,
  clearContactClick,
  findContactClick,
  addContactClick,
  clearAllItems,
  sampleReportData,
  emailReportData,
  reportTypeOptions,
  emailReportDataSelectedRows,
  infoMessage,
  emailValidation,
  nameValidation
) {
  return [
    {
      xsCol: 12,
      fields: [
        { name: "ForeName", label: "Name", xsCol: 2, labelColor: nameValidation === true ? "red" : "", },
        { name: "SureName", alignSelf: "flex-end", label: "", xsCol: 2 },
        { name: "UserName", label: "Email", xsCol: 3, labelColor: emailValidation === true ? "red" : "", },
        {
          mdCol: 2,
          alignSelf: "flex-end",
          component: function () {
            return (
              <Button
                startIcon={<ClearIcon />}
                variant="outlined"
                onClick={resetForm}
              >
                Clear
              </Button>
            );
          },
        },
        {
          mdCol: 2,
          alignSelf: "flex-end",
          component: function () {
            return (
              <Button
                startIcon={<ClearIcon />}
                onClick={clearContactClick}
                variant="outlined"
              >
                Clear Recipients
              </Button>
            );
          },
        },
        {
          mdCol: 2,
          alignSelf: "flex-end",
          component: function () {
            return (
              <Button
                startIcon={<SearchIcon />}
                onClick={findContactClick}
              >
                Find Contact (F11)
              </Button>
            );
          },
        },
        {
          mdCol: 6,
          alignSelf: "flex-end",
          component: function () {
            return (
              <Button startIcon={<AddIcon />} onClick={addContactClick} disabled={emailValidation || nameValidation}>
                Add (F10)
              </Button>
            );
          },
        },
        {
          name: "MessageText",
          label: "Message",
          xsCol: 7,
          type: "textarea",
        },
        {
          name: "CMSBrochure",
          label: "CMS Brochure",
          mdCol: 1.5,

          type: "switch",
        },
        {
          name: "TakeSample",
          label: "Taking an Oil Sample",
          mdCol: 1.9,

          type: "switch",
        },
        {
          name: "WebsiteGuide",
          label: "Website Guide",
          mdCol: 2,
          type: "switch",
        },
        {
          group: [
            {
              name: "IsZipped",
              label: "ZIP Files",
              mdCol: 2,
              type: "switch",
            },

            {
              name: "IsComplete",
              label: "Only Email Completed",
              mdCol: 2,
              readOnly: true,
              type: "switch",
            },
            {
              name: "AttachmentSize",
              label: "Maximum Size",
              type: "number",
              min: 1,
              max: 150,
              mdCol: 1,
              alignSelf: "flex-end",
            },
            {
              name: "ReportNumbers",
              label: "Report",
              type: "dropdown",
              required: true,
              selectOptions: reportTypeOptions,
              xsCol: 2,
              alignSelf: "flex-end",
            },
            {
              mdCol: 1,
              alignSelf: "flex-end",
              component: function () {
                return (
                  <Button onClick={emailSelectedClick} startIcon={<Email />} disabled={(emailReportData?.length < 1)}>
                    Email Selected (F12)
                  </Button>
                );
              },
            },
            {
              mdCol: 1.2,
              alignSelf: "flex-end",
              component: function () {
                return (
                  <Button
                    variant="outlined"
                    disabled={sampleReportData && sampleReportData.length === 0}
                    onClick={selectAllItemsClick}
                    startIcon={<DoneOutline />}
                  >
                    Select All Items
                  </Button>
                );
              },
            },
            {
              mdCol: 1.5,
              alignSelf: "flex-end",
              component: function () {
                return (
                  <Button
                    variant="outlined"
                    disabled={sampleReportData && sampleReportData.length === 0}
                    onClick={deSelectAllItemsClick}
                    startIcon={<Deselect />}
                  >
                    Deselect All
                  </Button>
                );
              },
            },
            {
              mdCol: 1.5,
              alignSelf: "flex-end",
              component: function () {
                return (
                  <Button
                    variant="outlined"
                    disabled={sampleReportData && sampleReportData.length === 0}
                    onClick={removeSelectClick}
                    startIcon={<Close />}
                  >
                    Remove Selected
                  </Button>
                );
              },
            },
            {
              mdCol: 1.5,
              alignSelf: "flex-end",
              component: function () {
                return (
                  <Button
                    variant="outlined"
                    disabled={sampleReportData && sampleReportData.length === 0}
                    onClick={clearAllItems}
                    startIcon={<ClearIcon />}
                  >
                    Clear All Items
                  </Button>
                );
              },
            },
            {
              mdCol: 1.5,
              alignSelf: "flex-end",
              component: function () {
                return (
                  <Typography color={infoMessage?.color || ""}>
                    {infoMessage?.message || ""}
                  </Typography>
                );
              },
            },
          ],
        },
      ],
    },
  ];
}

function SearchSampleReportReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.LOAD_DATA) {
    return action.payload;
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...action.payload };
  }
  return state;
}

const SearchContactDefaults = {
  CMSBrochure: true,
  TakeSample: true,
  WebsiteGuide: true,
  IsComplete: true,
  AttachmentSize: 50,
  ReportNumbers: "CMS Brochure Only",
  IsZipped: false,

};
