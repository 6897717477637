import { Button, Card } from "@mui/material";
import Form, { SectionTitle } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import { Edit, EventNote, NotificationsNone } from "@mui/icons-material";
import AppDialog from "../../../components/AppDialog";
import { useState, useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import { DETAILS_APIS } from "../../../common/apis";
import { useRoot } from "../../../rootContext";
import FluidWatch from "../../master-data/fluids/FluidWatch";
import { useWindowPopups } from "../../../components/useWindowPopups";

function ComponentDetail({ componentDetails }) {

  const { post } = useFetch();
  const { CanDoDataMaintenance } = useRoot();

  const [isNotify, setIsNotify] = useState(false);
  const [data, setData] = useState({});
  const [dialog, setDialog] = useState({
    open: false,
    width: "",
    title: "",
  });

  useEffect(() => {
    setData({ ...componentDetails, key: Date?.now() })
  }, [componentDetails])

  useEffect(() => {
    if (data?.NotificationUserId !== null) {
      return setIsNotify(true)
    }
    else {
      return setIsNotify(false)
    }
  }, [data?.NotificationUserId])

  const getMachineInfo = async () => {
    let dataList = await post(DETAILS_APIS.UPDATE_MACHINE_NOTIFICATIONS,
      { ComponentId: data?.ComponentId, HasNotification: isNotify })
    if (dataList?.Success === true) {
      return setIsNotify(!isNotify)
    }
  }

  const HandleNewWindow = (actionName) => {
    useWindowPopups(actionName, data)
  }

  const actions = {
    edit: function (e) {
      HandleNewWindow("edit-component")
    },
    events: function (e) {
      HandleNewWindow("edit-event")
    },
    notifyMe: function () {
      getMachineInfo()
    },
  };

  function FluidWatchOpenClick() {
    setDialog({
      open: true,
      title: "Fluid Watch",
      width: "xl",
    });
  }

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Form
          readOnly
          sections={GetFormSections(data, FluidWatchOpenClick)}
          data={data}
          key={data?.key || data?.ComponentId}
        />
        <FormFooter buttons={GetFormButtons(data, isNotify, actions, CanDoDataMaintenance)} />
      </Card>

      <AppDialog
        open={dialog.open}
        title={dialog.title}
        maxWidth={dialog.width}
        handleClose={() =>
          setDialog((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
      >
        {dialog.title === "Fluid Watch" && (
          <FluidWatch fluidId={data.DefaultFluidId} fluidTypeId={data.ComponentFluidTypeId} />
        )}
      </AppDialog>

    </>
  );
}

export default ComponentDetail;

const GetFormButtons = (data, isNotify, actions, CanDoDataMaintenance) => [
  {
    variant: data?.EventLogCount > 0 ? "contained" : "outlined",
    color: data?.EventLogCount > 0 ? "lightseagreen" : "primary",
    label: "Event Log",
    startIcon: <EventNote />,
    onClick: actions.events,
    badge: { badgeContent: data.EventLogCount, badgeColor: "lightseagreen", sx: { [`& .MuiBadge-badge`]: { border: "1px solid white" } } },
  },
  {
    variant: "outlined",
    label: isNotify === true ? "Unnotify" : "Notify Me",
    startIcon: <NotificationsNone />, onClick: actions.notifyMe,
  },
  CanDoDataMaintenance && {
    variant: "contained",
    label: "Edit",
    onClick: actions.edit,
    startIcon: <Edit />,
  },
];

const GetFormSections = (data, FluidWatchOpenClick) => [
  {
    xsCol: 12,
    fields: [
      {
        xsCol: 12,
        component: function () {
          return <SectionTitle title="Component Details" />;
        },
      },
      { name: "Component", label: "Component", xsCol: 3 },
      { name: "Serial", label: "Serial", xsCol: 3 },
      { name: "ComponentMakeFormatted", label: "Model", xsCol: 3 },
      { name: "Defaults", label: "Defaults", xsCol: 3 },
      {
        xsCol: 2,
        component: function () {
          return (
            <Button
              variant="contained"
              color={data?.HasFluidWatch === true ? "lightseagreen" : "primary"}
              onClick={FluidWatchOpenClick}
              sx={{ mt: "10px", }}
            >
              Fluid Watch
            </Button>
          );
        },
      },
      {
        name: "DefaultFluidFormatted",
        label: (data && data.DefaultFluidType) || "Oil",
        xsCol: 4,
      },
      { name: "DefaultTestSet", label: "Test Set", xsCol: 3 },

      { name: "CIN", label: "CIN", xsCol: 3 },
      { name: "ComponentType", label: "Type", xsCol: 3 },
      { name: "Capacity", label: "Capacity", xsCol: 3 },
      { name: "Location", label: "Location", xsCol: 3 },
      // {name: "EventLogCount", label: "Event Log", xsCol: 3 },
      { name: "ComponentNotes", label: "Comments", type: "textarea", xsCol: 12, rows: 2 },
    ],
  },
];
